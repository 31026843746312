const mouseClickEvents = ['mousedown', 'click', 'mouseup']
export const simulateMouseClick = (element) => {
  mouseClickEvents.forEach((mouseEventType) =>
    element.dispatchEvent(
      new MouseEvent(mouseEventType, {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1
      })
    )
  )
}
