import { BrowserRouter } from 'react-router-dom'
import { Router } from './router/Router'

export const Root = () => {
  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  )
}
