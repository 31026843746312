import { Button, Card, Typography } from 'antd'
import { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { SubscriptionResult } from '../../../../components/subscriptionResult/SubscriptionResult'
import { PathRiverRoutes } from '../../../../router/routes'
import { LocalStorage } from '../../../../service/LocalStorage'
import { User } from '../../../../types/user'
import { DateTime, DateTimeFormat } from '../../../../util/dateTime'

type Props = {
  profile: User
  daysFromSubscriptionExpiry: number
  isSuperAdmin: boolean
}

export const ProfileSubscription = ({ daysFromSubscriptionExpiry, profile, isSuperAdmin }: Props) => {
  const content = useMemo(() => {
    const subscriptionName = profile.subscription_plan || 'subscription plan'
    const subscriptionExpiry = DateTime.format(new Date(profile.subscription_expires_at), DateTimeFormat.ll)
    if (daysFromSubscriptionExpiry > 7 || isSuperAdmin) {
      return {
        title: `Active subscription`,
        note: (
          <Typography.Paragraph className="note">
            Your Pathriver subscription is active
            <br />
            {!isSuperAdmin && (
              <>
                Next payment is due on <Typography.Text strong>{subscriptionExpiry}</Typography.Text>
              </>
            )}
          </Typography.Paragraph>
        )
      }
    } else if (daysFromSubscriptionExpiry < 7 && daysFromSubscriptionExpiry > 0) {
      return {
        title: `Your ${subscriptionName} expires in ${Math.round(daysFromSubscriptionExpiry)} day(s)`,
        note: (
          <Typography.Paragraph className="note">
            On <Typography.Text strong>{subscriptionExpiry}</Typography.Text> your {subscriptionName} will end and you
            will not be able to access Search and Compare and Directory.
            <br />
            <br />
            Upgrade now to keep using Pathriver after the trial ends.
          </Typography.Paragraph>
        )
      }
    }
    return {
      title: `Your ${subscriptionName} expired`,
      note: (
        <Typography.Paragraph className="note">
          On <Typography.Text strong>{subscriptionExpiry}</Typography.Text> your {subscriptionName} expired.
          <br />
          Upgrade now to keep using Pathriver.
        </Typography.Paragraph>
      )
    }
  }, [daysFromSubscriptionExpiry, isSuperAdmin, profile.subscription_expires_at, profile.subscription_plan])

  if (isSuperAdmin || daysFromSubscriptionExpiry > 0) {
    LocalStorage.removeSubscriptionPendingState()
  }

  return (
    <>
      <div className="top-header">
        <Typography.Title level={2}>My Subscription</Typography.Title>
      </div>
      <Card size="default" className="profile-page-card">
        {!isSuperAdmin && daysFromSubscriptionExpiry <= 0 && LocalStorage.isSubscriptionUpdatePending() ? (
          <SubscriptionResult />
        ) : (
          <>
            <Typography.Title level={4} className="title">
              {content.title}
            </Typography.Title>
            {content.note}
            {daysFromSubscriptionExpiry < 7 && !isSuperAdmin && (
              <Button type="primary" className="upgrade-btn">
                <NavLink to={PathRiverRoutes.SUBSCRIPTIONS}>Upgrade now</NavLink>
              </Button>
            )}
          </>
        )}
      </Card>
    </>
  )
}
