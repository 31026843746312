import { useState } from 'react'
import { Button, Form, Input, Select, Typography, Row, Col } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { message } from 'antd'
import { NavLink } from 'react-router-dom'

import { SignupRequest } from '../../../mutations/signup/request'
import { useMutationSignup } from '../../../mutations/signup/useMutationSignup'
import { emailRegex } from '../../../util/regex'
import { Mixpanel } from '../../../util/tracking'
import { httpClient } from '../../../service/http/HttpClient'
import CheckableTag from 'antd/es/tag/CheckableTag'
import { UnauthenticatedFormWrapper } from '../components/UnauthenticatedFormWrapper'
import { AuthRoutes } from '../../../router/routes'
import { SEPPARATOR, SpecialtyOptions } from '../../../util/userUtil'

const { Title } = Typography

export const SignupPage = () => {
  const [form1] = useForm()
  const [form2] = useForm()
  const signupMutation = useMutationSignup()
  const [selectedSpecialty, setSelectedSpecialty] = useState<string[]>([])
  const [selectedReferral, setSelectedReferral] = useState<string>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [activeStep, setStep] = useState<number>(1)
  const [isSpecialistSelected, setIsSpecialist] = useState<boolean>(false)
  const [payload, setPayload] = useState<SignupRequest>(null)
  const [isSuccessfullySubmitted, setIsSuccessSubmitted] = useState<boolean>(false)

  const handleSpecialtyChange = (specialty: string, checked: boolean) => {
    const nextSelectedSpecialty = checked
      ? [...selectedSpecialty, specialty]
      : selectedSpecialty.filter((t) => t !== specialty)

    setSelectedSpecialty(nextSelectedSpecialty)
  }

  const onFinish = () => {
    let specialty = selectedSpecialty.join(SEPPARATOR)
    if (selectedSpecialty.includes('Other')) {
      specialty += SEPPARATOR + form2.getFieldValue('other_specialty')
    }
    let referralSource = selectedReferral
    if (selectedReferral === 'Other') {
      referralSource += SEPPARATOR + form2.getFieldValue('other_referral_source')
    }

    const signupPayload = {
      ...payload,
      specialty,
      referral_source: referralSource,
      years_of_experience: form2.getFieldValue('years_of_experience')
    }

    if (activeStep > 1 && signupPayload) {
      setIsLoading(true)
      httpClient.session
        .signup(signupPayload)
        .then(() => {
          Mixpanel.trackEvent('Sign up successfully completed', {
            email: signupPayload.email,
            referral_source: signupPayload.referral_source,
            specialty: signupPayload.specialty,
            years_of_experience: signupPayload.years_of_experience
          })
          setIsLoading(false)
          setIsSuccessSubmitted(true)
        })
        .catch((err) => {
          Mixpanel.trackEvent('Sign up failed ', {
            email: signupPayload.email,
            error: err
          })
          message.error({
            content: 'Sign up failed. Please try again.',
            duration: 3
          })
          console.error(err)
          setIsLoading(false)
        })
    }
  }

  return (
    <UnauthenticatedFormWrapper pageName="Sign up page" className="signup">
      {isSuccessfullySubmitted && (
        <div
          style={{
            width: '80%',
            margin: '0 auto',
            padding: '20px 0',
            textAlign: 'center'
          }}>
          <Title level={3}>Thank you for signing up!</Title>
          <NavLink
            to={AuthRoutes.LOGIN}
            style={{
              fontSize: 20
            }}>
            Login here
          </NavLink>
        </div>
      )}
      {!isSuccessfullySubmitted && (
        <div>
          <Title level={3}>Sign up for a new account</Title>
          {activeStep === 1 && (
            <>
              <Form
                name="basic"
                initialValues={{ remember: true }}
                autoComplete="off"
                form={form1}
                requiredMark="optional"
                layout="vertical">
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="first_name"
                      label="First name"
                      requiredMark={true}
                      rules={[{ required: true, message: 'Please enter first name' }]}>
                      <Input path-id="signup-page-first-name-input" type="text" placeholder="Enter your first name" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="last_name"
                      label="Last name"
                      requiredMark={true}
                      rules={[{ required: true, message: 'Please enter last name' }]}>
                      <Input path-id="signup-page-last-name-input" type="text" placeholder="Enter your last name" />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  name="email"
                  label="Email Address"
                  requiredMark={true}
                  rules={[
                    { required: true, message: 'Please enter a valid email' },
                    { pattern: emailRegex, message: 'Email is not valid' }
                  ]}>
                  <Input path-id="signup-page-email-input" type="email" placeholder="Enter your email" />
                </Form.Item>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="password"
                      label="Password"
                      requiredMark={true}
                      rules={[{ required: true, message: 'Please enter your password' }]}>
                      <Input
                        path-id="signup-page-password-input"
                        type="password"
                        placeholder="Enter your password"
                        autoComplete="new-password"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="password_confirmation"
                      label="Confirm Password"
                      requiredMark={true}
                      rules={[
                        { required: true, message: 'Please enter confirm password' },
                        {
                          required: true,
                          message: 'Password does not match',
                          validator: (_, value) => {
                            if (!value || form1.getFieldValue('password') === value) {
                              return Promise.resolve()
                            }
                            return Promise.reject(new Error('Password does not match'))
                          }
                        }
                      ]}>
                      <Input
                        path-id="signup-page-confirm-password-input"
                        type="password"
                        placeholder="Enter the password again"
                        autoComplete="new-password"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item shouldUpdate className="submit">
                  {() => (
                    <Button
                      type="primary"
                      color="primary"
                      shape="round"
                      onClick={() => {
                        form1.validateFields().then((values) => {
                          if (values) {
                            setPayload(values)
                            setStep(2)
                            Mixpanel.trackEvent('Sign up page - Step 1 completed')
                          }
                        })
                      }}
                      htmlType="submit"
                      disabled={form1.getFieldsError().filter(({ errors }) => errors.length).length > 0}>
                      Next
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </>
          )}
          {activeStep === 2 && (
            <>
              <Form
                className="step-2"
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete="off"
                form={form2}
                requiredMark="optional"
                layout="vertical">
                <Row gutter={16}>
                  <Col span={isSpecialistSelected ? 12 : 24}>
                    <Form.Item
                      name="career_stage"
                      label="Career stage"
                      required={true}
                      requiredMark={true}
                      rules={[{ required: true, message: 'Please select career stage' }]}>
                      <Select
                        placeholder="Select career stage"
                        onChange={(value) => setIsSpecialist(value === 'specialist')}
                        options={[
                          { value: 'student', label: 'Student' },
                          { value: 'resident', label: 'Resident/Trainee' },
                          { value: 'specialist', label: 'Specialist/Consultant' }
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  {isSpecialistSelected && (
                    <Col span={12}>
                      <Form.Item
                        name="years_of_experience"
                        label="Years of experience"
                        requiredMark={null}
                        required={false}>
                        <Select placeholder="Select years of experience">
                          <Select.Option value="1-5">1-5</Select.Option>
                          <Select.Option value="6-10">6-10</Select.Option>
                          <Select.Option value="11-20">11-20</Select.Option>
                          <Select.Option value="20+">20+</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
                {isSpecialistSelected && (
                  <Form.Item
                    name="specialty"
                    label="Subspecialty field (select all that apply)"
                    required={false}
                    requiredMark={null}>
                    <div className="specialty-tag-wrapper">
                      {SpecialtyOptions.map((specialty) => (
                        <CheckableTag
                          className="border-tag"
                          key={specialty}
                          checked={selectedSpecialty.includes(specialty)}
                          onChange={(checked) => handleSpecialtyChange(specialty, checked)}>
                          {specialty}
                        </CheckableTag>
                      ))}
                    </div>
                  </Form.Item>
                )}
                {selectedSpecialty.includes('Other') && (
                  <Form.Item
                    name="other_specialty"
                    label="Other subspecialty field"
                    rules={[{ required: true, message: 'Please enter other subspecialty' }]}>
                    <Input type="text" placeholder="Enter other specialty" />
                  </Form.Item>
                )}

                <Form.Item
                  name="referral_source"
                  label="How did you learn about Pathriver?"
                  requiredMark={null}
                  required={false}>
                  <Select
                    placeholder="How did you learn about Pathriver"
                    onChange={(value) => setSelectedReferral(value)}>
                    <Select.Option value="Social Media">Social Media</Select.Option>
                    <Select.Option value="Recommended by Colleague or Friend">
                      Recommended by Colleague or Friend
                    </Select.Option>
                    <Select.Option value="Search (Google, Bing, etc)">Search (Google, Bing, etc)</Select.Option>
                    <Select.Option value="Blog or Publication">Blog or Publication</Select.Option>
                    <Select.Option value="Conference">Conference</Select.Option>
                    <Select.Option value="Other">Other</Select.Option>
                  </Select>
                </Form.Item>
                {selectedReferral === 'Other' && (
                  <Form.Item
                    name="other_referral_source"
                    label="Other referral source"
                    rules={[{ required: true, message: 'Please enter other referral source' }]}>
                    <Input type="text" placeholder="Enter other referral source" />
                  </Form.Item>
                )}
                <Row gutter={16}>
                  <Col span={5}>
                    <Form.Item shouldUpdate className="full-width-button">
                      {() => (
                        <Button
                          type="default"
                          shape="round"
                          onClick={() => {
                            Mixpanel.trackEvent('Sign up page - Back to step 1')
                            setStep(1)
                          }}>
                          Back
                        </Button>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={19}>
                    <Form.Item shouldUpdate className="submit">
                      {() => (
                        <Button
                          type="primary"
                          color="primary"
                          htmlType="submit"
                          shape="round"
                          disabled={
                            form2.getFieldsError().filter(({ errors }) => errors.length).length > 0 ||
                            signupMutation.isLoading
                          }
                          loading={isLoading}>
                          Complete Sign Up
                        </Button>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </>
          )}
          {activeStep === 2 && (
            <div className="signup-terms">
              <span>
                By signing up you agree to our{' '}
                <a href="/terms" target="_blank" rel="noopener noreferrer" title="Terms and Conditions">
                  Terms and Conditions
                </a>
              </span>
            </div>
          )}
          {activeStep === 1 && (
            <div className="link-container">
              Already have an account? <NavLink to={AuthRoutes.LOGIN}>Login here</NavLink>
            </div>
          )}
        </div>
      )}
    </UnauthenticatedFormWrapper>
  )
}
