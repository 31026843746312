import { useQuery } from 'react-query'
import { httpClient } from '../../service/http/HttpClient'
import { DiseaseClient } from '../../service/http/DiseaseClient'
import { isValue } from '../../types/typeGuard'

type props = {
  diseaseId: number | undefined
  onSuccess?: () => void
  isEnabled?: boolean
}
export const useQueryGetDisease = ({ diseaseId, isEnabled = true, onSuccess }: props) => {
  return useQuery(
    DiseaseClient.queryKeys.getDisease(diseaseId),
    async () => {
      if (isValue(diseaseId)) {
        const { data } = await httpClient.disease.getById(diseaseId)
        return data
      }
      return undefined
    },
    {
      enabled: isValue(diseaseId) && isEnabled,
      onSuccess: () => {
        if (isValue(onSuccess)) {
          onSuccess()
        }
      }
    }
  )
}
