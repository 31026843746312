/* eslint-disable no-undef */
export const appImgBaseURL = 'https://dp4kbwjmld20t.cloudfront.net/'

export const secrets = {
  sentryDsn: process.env.REACT_APP_SENTRY_DSN,
  mixpanelId: process.env.REACT_APP_MIXPANEL_ID,
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  diseaseIndex: process.env.REACT_APP_ALGOLIA_DISEASE_INDEX_NAME,
  algoliaAppId: process.env.REACT_APP_ALGOLIA_APP_ID,
  algoliaToken: process.env.REACT_APP_ALGOLIA_TOKEN
}
