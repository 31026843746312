import { Step, Tab } from '../../../../../types/step'
import { DiffDiagnose, Disease, DiseaseInfoKeyword } from '../../../../../types/disease'
import { isValue } from '../../../../../types/typeGuard'
import { ImageSlider } from '../../../../../components/image-slider/ImageSlider'
import { injectHtml } from '../../../../../util/injectUtil'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Popover } from 'antd'

type Props = {
  disease: Disease
  step: Step
  selectedDiseasesIds: number[]
  onToggleDiffDisease: (id: number) => void
}

export const ComparisonItemTabContent = ({ disease, step, selectedDiseasesIds, onToggleDiffDisease }: Props) => {
  const diseaseDiffDiagnoses: DiffDiagnose[] | undefined = disease?.diff_diagnoses

  const activeTab = disease?.disease_info?.find((di) => di.tab_id === step.selectedTabId)

  const renderDiffDiagnoses = () => {
    if (!diseaseDiffDiagnoses?.length) {
      return (
        <div className="comparison-disease_diff_diagnoses">
          <p
            style={{
              paddingTop: '10px',
              color: '#707070',
              textAlign: 'center'
            }}>
            No differential diagnoses available
          </p>
        </div>
      )
    }

    return (
      <div className="comparison-disease_diff_diagnoses">
        {diseaseDiffDiagnoses
          ?.sort((a, b) => a.diff_disease_id - b.diff_disease_id)
          ?.map((diffDiagnose) => {
            const selected = selectedDiseasesIds.some((id) => id === diffDiagnose.diff_disease_id)
            return (
              <div
                key={diffDiagnose.diff_disease_id}
                className={`comparison-disease_diff_diagnose_container ql-editor ${selected ? 'selected' : ''}`}>
                <div className="comparison-disease_diff_diagnose_container-action">
                  <Popover content={selected ? 'Remove from comparison' : 'Add to comparison'}>
                    {selected ? (
                      <MinusOutlined onClick={() => onToggleDiffDisease(diffDiagnose.diff_disease_id)} />
                    ) : (
                      <PlusOutlined onClick={() => onToggleDiffDisease(diffDiagnose.diff_disease_id)} />
                    )}
                  </Popover>
                </div>

                <div className="comparison-disease_diff_diagnose_container-right">
                  <p className="title">{diffDiagnose.name}</p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: injectHtml(diffDiagnose.comment) ?? '-'
                    }}
                  />
                </div>
              </div>
            )
          })}
      </div>
    )
  }

  const renderGeneralInfo = () => {
    return (
      <div className="comparison-disease_definition_container ql-editor">
        <div
          dangerouslySetInnerHTML={{
            __html: injectHtml(disease.definition ?? '-')
          }}></div>

        <p className="icd-wrapper">
          {disease?.icds?.length > 0 ? (
            disease.icds.map((icd, i) => (
              <span
                key={i}
                style={{
                  marginRight: i === disease.icds.length - 1 ? 0 : 20
                }}>
                <b>ICD-O : </b>
                {!!icd.code || !!icd.category ? `${icd.code}/${icd.category}` : `Not applicable`}
              </span>
            ))
          ) : (
            <span>
              <b>ICD-O : </b> Not applicable
            </span>
          )}
        </p>
      </div>
    )
  }

  const renderTreatment = () => {
    return step?.tabs?.map((tab: Tab, i: number) => {
      const active = disease?.disease_info?.find((di) => di.tab_id === tab.id)
      return (
        <div
          className={`comparison-disease_definition_container ql-editor ${
            i >= step.tabs.length - 1 ? '--border-top --p-t-5' : ''
          }`}
          key={active?.tab_id}>
          <p className="comparison-disease_treatment_title">{tab?.name}</p>
          <div
            dangerouslySetInnerHTML={{
              __html: injectHtml(active?.description ?? '-')
            }}></div>
        </div>
      )
    })
  }

  const renderReferencesOrResource = (isReferences = false) => {
    return (
      <div
        className="comparison-disease_definition_container ql-edito"
        key={disease.id + (isReferences ? '-references' : '-resources')}>
        <p className="comparison-disease_references_title">{isReferences ? 'References' : 'Resources'}</p>
        <div
          dangerouslySetInnerHTML={{
            __html: injectHtml(isReferences ? disease?.reference ?? '-' : disease?.resources ?? '-')
          }}></div>
      </div>
    )
  }

  const sortIhcKeywords = (keywords: DiseaseInfoKeyword[]) => {
    const positiveIds = [64, 92, 63]
    const positiveKeywords: DiseaseInfoKeyword[] = keywords.filter((keyword) =>
      positiveIds.includes(keyword.keyword?.keyword_group_id)
    )
    const negativeKeywords: DiseaseInfoKeyword[] = keywords.filter(
      (keyword) => !positiveIds.includes(keyword.keyword?.keyword_group_id)
    )
    return [
      ...positiveKeywords.sort((a, b) => a.keyword?.name.localeCompare(b.keyword?.name)),
      ...negativeKeywords.sort((a, b) => a.keyword?.name.localeCompare(b.keyword?.name))
    ]
  }

  const renderIhcKeywords = () => {
    return (
      <>
        {step.id === 5 && (activeTab == null || activeTab?.keywords === null || activeTab?.keywords.length == 0) && (
          <div className="keyword-list-empty-space"></div>
        )}

        {!!activeTab?.tab_id && [25, 26, 27].includes(activeTab?.tab_id) && activeTab?.keywords?.length > 0 && (
          <ul key={activeTab?.tab_id} className="keyword-list-with-border-top">
            {sortIhcKeywords(activeTab?.keywords).map((keywordInfo: DiseaseInfoKeyword) => {
              // default value for  keywordInfo.weight is 50, so we don't need to show it
              return (
                <li key={keywordInfo.keyword_id}>
                  {keywordInfo.keyword?.name} {keywordInfo?.weight !== 50 ? `≈ ${keywordInfo.weight}` : ''}{' '}
                  {keywordInfo.comment}
                </li>
              )
            })}
          </ul>
        )}
      </>
    )
  }

  return (
    <div>
      {step.isImage && isValue(activeTab) && activeTab?.images?.length > 0 ? (
        <ImageSlider images={activeTab.images} />
      ) : (
        <>
          {step.selectedTabId === 28 && renderDiffDiagnoses()}
          {step.selectedTabId !== 28 && (
            <>
              {step.id === 1 && renderGeneralInfo()}
              {step.id === 7 && renderTreatment()}
              {step.id === 8 && (
                <>
                  {renderReferencesOrResource(true)}
                  {renderReferencesOrResource()}
                </>
              )}
              {step.id !== 1 && step.id !== 7 && step.id !== 8 && (
                <div
                  className="comparison-disease_definition_container ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: injectHtml(activeTab?.description ?? '-')
                  }}
                />
              )}
              {renderIhcKeywords()}
            </>
          )}
        </>
      )}
    </div>
  )
}
