import { MoreOutlined } from '@ant-design/icons'
import { MenuProps, Typography } from 'antd'
import Collapse from 'antd/es/collapse'
import Dropdown from 'antd/es/dropdown'
import { useMemo, useState } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { ImageSlider } from '../../../../../components/image-slider/ImageSlider'
import { DiseaseDetailsResponse } from '../../../../../types/disease'
import { DiseaseDetailsStep, Step, TabWithDiseaseContent } from '../../../../../types/step'
import { isValue } from '../../../../../types/typeGuard'
import { injectHtml } from '../../../../../util/injectUtil'
import styles from './DiseaseTabs.module.scss'

const stepNameMapping: Record<number, string> = {
  5: 'Additional methods',
  6: 'Differential diagnoses',
  7: 'Treatment and prognosis'
}

const stepsToMergeTabs = [7]

export const DiseaseTabs = ({ steps, disease }: { steps: Step[]; disease: DiseaseDetailsResponse }) => {
  const [activeTabPerStep, setActiveTabPerStep] = useState(
    steps.reduce((acc, curr) => {
      acc[curr.id] = 0

      return acc
    }, {})
  )

  const stepsWithDiseaseTabs: DiseaseDetailsStep[] = useMemo(() => {
    return (steps ?? [])
      .slice(1)
      .map((step) => {
        const clone = cloneDeep(step)
        if (Object.keys(stepNameMapping).some((it) => Number(it) === clone.id)) {
          clone.name = stepNameMapping[clone.id]
        }

        for (const tab of clone.tabs) {
          if (disease?.tabs[tab.id]) {
            tab.diseaseTab = disease?.tabs[tab.id]
          }
        }

        return clone as DiseaseDetailsStep
      })
      .reduce((acc: DiseaseDetailsStep[], curr: DiseaseDetailsStep) => {
        if (stepsToMergeTabs.includes(curr.id)) {
          curr.tabs = curr.tabs.reduce((tabs: TabWithDiseaseContent[], currTab: TabWithDiseaseContent) => {
            if (currTab.diseaseTab) {
              // eslint-disable-next-line max-len
              currTab.diseaseTab.description = `<h3 style="font-weight: bold">${currTab.name}:</h3><br />${currTab.diseaseTab.description}`
              if (tabs.length === 0) {
                tabs = tabs.concat(currTab)
              } else {
                if (isValue(tabs[0].diseaseTab)) {
                  tabs[0].diseaseTab.description += `<br />${currTab.diseaseTab.description}`
                }
                tabs[0].keyword_groups = tabs[0].keyword_groups.concat(currTab.keyword_groups)
              }
            } else {
              tabs = tabs.concat(currTab)
            }
            return tabs
          }, [])
        }

        curr.tabs = curr.tabs.reduce((tabs: TabWithDiseaseContent[], currTab: TabWithDiseaseContent) => {
          if (currTab.diseaseTab && currTab.diseaseTab.images) {
            const imagesTab = cloneDeep(currTab)
            imagesTab.diseaseTab.description = ''
            imagesTab.name = `${imagesTab.name} : Images`
            imagesTab.id = `${curr.id}${imagesTab.id}:Images:Clone`

            tabs = tabs.concat({ ...currTab, diseaseTab: { ...currTab.diseaseTab, images: null } })
            tabs = tabs.concat(imagesTab)
          } else {
            tabs = tabs.concat(currTab)
          }
          return tabs
        }, [])

        acc = acc.concat(curr)
        return acc
      }, [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Collapse expandIcon={() => <></>} expandIconPosition="end">
      {stepsWithDiseaseTabs.map((step) => {
        const additionalTabsItems: MenuProps['items'] = step.tabs.map((it, index) => {
          return {
            key: it.id,
            label: (
              <div
                data-parent-step={step.id}
                onClick={() => setActiveTabPerStep((old) => ({ ...old, [step.id]: index }))}>
                {activeTabPerStep[step.id] === index ? <b>{it.name}</b> : it.name}
              </div>
            )
          }
        })

        const content = () => {
          if (step.id !== 6 && step.tabs.length > 0) {
            return (
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{
                  __html:
                    isValue(step.tabs[activeTabPerStep[step.id]]) &&
                    isValue(step.tabs[activeTabPerStep[step.id]].diseaseTab) &&
                    isValue(step.tabs[activeTabPerStep[step.id]].diseaseTab.description)
                      ? injectHtml(step.tabs[activeTabPerStep[step.id]].diseaseTab.description)
                      : '-'
                }}
              />
            )
          } else if (step.id === 6 && isValue(disease.diff_diagnoses) && disease.diff_diagnoses.length > 0) {
            return disease.diff_diagnoses.map((diffDg) => (
              <div key={diffDg.diff_disease_id}>
                <Typography.Title level={5}>{diffDg.name}</Typography.Title>
                <div
                  className="ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: injectHtml(diffDg.comment || '-') ?? '-'
                  }}
                />
              </div>
            ))
          }

          return 'No content'
        }

        return (
          <Collapse.Panel
            data-step-id={step.id}
            className={styles.wrapper}
            key={step.id}
            header={
              <>
                <span>{`${step.name}${
                  isValue(step.tabs[activeTabPerStep[step.id]]?.name) &&
                  step.tabs[activeTabPerStep[step.id]]?.name !== step.name &&
                  step.tabs[activeTabPerStep[step.id]]?.id !== additionalTabsItems[0].key
                    ? ` - ${step.tabs[activeTabPerStep[step.id]]?.name}`
                    : ''
                }`}</span>
                {additionalTabsItems.length > 1 && (
                  <Dropdown
                    overlayClassName={styles['context-menu']}
                    menu={{
                      items: additionalTabsItems,
                      onClick: (e) => {
                        const isStepExpanded =
                          document
                            .querySelector(`div[data-step-id="${step.id}"] > .ant-collapse-header`)
                            ?.getAttribute('aria-expanded') === 'true'

                        if (isStepExpanded) {
                          e.domEvent.stopPropagation()
                        }
                      }
                    }}
                    trigger={['click', 'contextMenu']}>
                    <MoreOutlined onClick={(e) => e.stopPropagation()} />
                  </Dropdown>
                )}
              </>
            }>
            {content()}
            {step.tabs[activeTabPerStep[step.id]]?.diseaseTab?.images ?? [].length > 0 ? (
              <ImageSlider
                images={step.tabs[activeTabPerStep[step.id]]?.diseaseTab?.images}
                thumbPerView={10}
                hideHover={true}
              />
            ) : null}
          </Collapse.Panel>
        )
      })}
    </Collapse>
  )
}
