export enum CASES_TABLE_FILTERS {
  ALL = '',
  OPEN = 'open',
  RESOLVED = 'resolved',
  ARCHIVED = 'archive'
}

export const FiltersValueToTranslation: Record<CASES_TABLE_FILTERS, string> = {
  [CASES_TABLE_FILTERS.ALL]: 'All',
  [CASES_TABLE_FILTERS.OPEN]: 'Open',
  [CASES_TABLE_FILTERS.RESOLVED]: 'Resolved',
  [CASES_TABLE_FILTERS.ARCHIVED]: 'Archived'
}

export enum CasesSearchParams {
  PAGE = 'page',
  LIMIT = 'limit',
  QUERY = 'q',
  STATUS = 'status'
}
