import { Button, Form, Input } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { NavLink } from 'react-router-dom'

import { useMutationLogin } from '../../../mutations/login/useMutationLogin'
import { LoginRequest } from '../../../mutations/login/request'
import { emailRegex } from '../../../util/regex'
import { AuthRoutes } from '../../../router/routes'
import { UnauthenticatedFormWrapper } from '../components/UnauthenticatedFormWrapper'

export const LoginPage = () => {
  const [form] = useForm()
  const loginMutation = useMutationLogin()

  const onFinish = (values: LoginRequest) => loginMutation.mutate(values)

  return (
    <UnauthenticatedFormWrapper pageName="Login page" title="Login to your account">
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        requiredMark="optional"
        layout="vertical">
        <Form.Item
          name="email"
          label="Email Address"
          rules={[
            { required: true, message: 'Please enter a valid email' },
            { pattern: emailRegex, message: 'Email is not valid' }
          ]}>
          <Input path-id="login-page-email-input" type="email" placeholder="Enter your email" />
        </Form.Item>
        <Form.Item name="password" label="Password" rules={[{ required: true, message: 'Please enter your password' }]}>
          <Input path-id="login-page-password-input" type="password" placeholder="Enter your password" />
        </Form.Item>
        <div className="link-container align-left">
          <NavLink to={AuthRoutes.FORGOT_PASSWORD}>Forgot password?</NavLink>
        </div>
        <Form.Item shouldUpdate className="submit">
          {() => (
            <Button
              className="login-button"
              type="primary"
              color="primary"
              htmlType="submit"
              shape="round"
              disabled={
                form.getFieldsError().filter(({ errors }) => errors.length).length > 0 || loginMutation.isLoading
              }>
              Login
            </Button>
          )}
        </Form.Item>
      </Form>

      <div className="link-container">
        Don't have an account? <NavLink to={AuthRoutes.SIGNUP}>Sign up here</NavLink>
      </div>
    </UnauthenticatedFormWrapper>
  )
}
