import { AxiosInstance, AxiosResponse } from 'axios'
import { SearchParamsType } from '../../pages/authenticated/search/url'
import { Section } from '../../types/section'
import { Chapter } from '../../types/chapter'

export class SectionClient {
  static queryKeys = {
    section: ['Section'],
    getAll: (params: Partial<SearchParamsType>) => {
      const result = [...SectionClient.queryKeys.section, 'List']
      for (const param of Object.values(params)) {
        if (param) result.push(param)
      }
      return result
    },
    getChapters: (sectionId: number) => [...SectionClient.queryKeys.section, sectionId, 'chapters']
  }

  private client: AxiosInstance

  constructor(client: AxiosInstance) {
    this.client = client
  }

  getAll(queryString: string): Promise<AxiosResponse<Section[]>> {
    return this.client.get(`/sections${queryString}`)
  }

  getAllChapters(sectionId: number): Promise<AxiosResponse<Chapter[] | null>> {
    return this.client.get(`/sections/${sectionId}/chapters`)
  }
}
