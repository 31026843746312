import { useParams } from 'react-router-dom'
import { ActiveCase } from '../../../components/activeCase/ActiveCase'
import { Portal } from '../../../components/portal/Portal'
import { useQueryGetCase } from '../../../queries/cases/useQueryGetCase'
import { isValue } from '../../../types/typeGuard'
import { SearchPageContent } from './SearchPageContent'
import { SearchPageSkeleton } from './SearchPageSkeleton'

export const SearchPageWrapper = () => {
  const { caseId } = useParams<{ caseId: string | undefined }>()

  const {
    data: caseData,
    isFetching: isFetchingCaseData,
    isLoading: isGetCaseLoading
  } = useQueryGetCase({
    caseId
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const caseDiseases: any[] | [] =
    caseData?.selected_diseases?.map((d) => ({
      disease_id: d.id,
      title: d.title,
      definition: d.definition,
      section: d.section?.name,
      chapter: d.chapter?.name,
      alternative_titles: d.alternative_titles,
      title_image: d.title_image
    })) ?? []

  if (!isValue(caseData)) return <SearchPageSkeleton />
  return (
    <>
      <Portal elementPortalId="active-case-button">
        <ActiveCase caseData={caseData} />
      </Portal>

      <SearchPageContent
        caseId={caseId}
        caseName={caseData?.name}
        caseDiseases={caseDiseases}
        resultDiseaseId={caseData?.result_disease_id}
        caseStatus={caseData?.status}
        initialSearchTerm={isValue(caseData?.last_history) ? caseData?.last_history.data : ''}
        initialKeywordIds={caseData?.keywords?.map((it) => it.id)}
        isFetchingCaseData={isFetchingCaseData}
        isGetCaseLoading={isGetCaseLoading}
      />
    </>
  )
}
