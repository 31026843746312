import { AxiosInstance, AxiosResponse } from 'axios'
import { DtoUser } from '../../model/user'

export class UserClient {
  private client: AxiosInstance

  // https://tkdodo.eu/blog/effective-react-query-keys
  static queryKeys = {
    users: ['Users'],
    getUser: (id: number) => [...UserClient.queryKeys.users, id]
  }

  constructor(client: AxiosInstance) {
    this.client = client
  }

  getUserById(id: number): Promise<AxiosResponse<DtoUser>> {
    return this.client.get(`users/${id}`)
  }

  updateSubscription(request: { subscription_plan_id: number; user_id: number }): Promise<AxiosResponse<string>> {
    return this.client.post('users/subscription/update', request)
  }
}
