import { AxiosInstance, AxiosResponse } from 'axios'
import { SearchParamsType } from '../../pages/authenticated/search/url'
import { DiseaseDetailsResponse, Disease, ShortDisease } from '../../types/disease'

export class DiseaseClient {
  static queryKeys = {
    disease: ['Disease'],
    getDisease: (diseaseId: number | undefined) => [...DiseaseClient.queryKeys.disease, diseaseId],
    getComparisonDiseases: (caseId: string) => [...DiseaseClient.queryKeys.disease, 'Compare', caseId],
    getAllByChapter: (chapter: string, query: string) => [
      ...DiseaseClient.queryKeys.disease,
      chapter,
      query,
      'ByChapter'
    ],
    getAll: (params: Partial<SearchParamsType>) => {
      const result = [...DiseaseClient.queryKeys.disease, 'List']
      for (const param of Object.values(params)) {
        if (param) result.push(param)
      }
      return result
    },
    getAllShort: (params: Partial<SearchParamsType>) => {
      const result = [...DiseaseClient.queryKeys.disease, 'Short_List']
      for (const param of Object.values(params)) {
        if (param) result.push(param)
      }
      return result
    }
  }

  private client: AxiosInstance

  constructor(client: AxiosInstance) {
    this.client = client
  }

  getById(id: number): Promise<AxiosResponse<DiseaseDetailsResponse>> {
    return this.client.get(`/diseases/${id}`)
  }

  getAll(queryString: string): Promise<AxiosResponse<Disease[]>> {
    return this.client.get(`/diseases${queryString}`)
  }

  getShortDiseases(queryString: string): Promise<AxiosResponse<ShortDisease[]>> {
    return this.client.get(`/diseases/list_short${queryString}`)
  }
}
