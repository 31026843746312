import { useQuery } from 'react-query'
import { CaseClient } from '../../service/http/CaseClient'
import { httpClient } from '../../service/http/HttpClient'
import { useCasesSearchHandler } from '../../util/url'
import { PaginationData } from '../types'

export const useQueryGetCases = () => {
  const { params, queryString, setCasesSearchParams } = useCasesSearchHandler()

  return useQuery(
    CaseClient.queryKeys.getCases(params),
    async () => {
      const result = await httpClient.cases.getCases(queryString)
      return { cases: result.data, pagination: result.headers }
    },
    {
      keepPreviousData: true,
      onSuccess: (data) => {
        const setPage = params.page ?? 1
        const totalPages = data.pagination[PaginationData.TOTAL_PAGES]
        if (setPage > totalPages || setPage <= 0) {
          setCasesSearchParams({ page: '1' })
        }
      }
    }
  )
}
