import { useQuery } from 'react-query'
import { httpClient } from '../../service/http/HttpClient'
import { ChapterClient } from '../../service/http/ChapterClient'
import { isValue } from '../../types/typeGuard'

export const useQueryGetChapter = (chapterId: string | undefined, isEnabled: boolean) => {
  return useQuery(
    ChapterClient.queryKeys.getById(chapterId),
    async () => {
      if (isValue(chapterId)) {
        const { data } = await httpClient.chapter.getById(chapterId)
        return data
      }
      return undefined
    },
    {
      enabled: isValue(chapterId) && isEnabled
    }
  )
}
