import { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from 'react'
import { useQueryGetSupersections } from '../../../../queries/supersections/useQueryGetSupersections'
import { Section, SuperSection } from '../../../../types/section'
import { ChildrenOnlyProps } from '../../../../types/general'
import { Navigate, useMatch, useNavigate } from 'react-router-dom'
import { PathRiverRoutes } from '../../../../router/routes'
import { toKebabCase } from '../../../../util/stringUtil'
import { useQueryGetDiseasesByChapter } from '../../../../queries/diseases/useQueryGetDiseasesByChapter'
import { Disease } from '../../../../types/disease'
import { PaginationData } from '../../../../queries/types'
import { notification } from 'antd'
import { isValue } from '../../../../types/typeGuard'
import { useQueryClient } from 'react-query'
import { SectionClient } from '../../../../service/http/SectionClient'
import { Chapter } from '../../../../types/chapter'
import { useQueryGetChapter } from '../../../../queries/chapters/useQueryGetChapter'

type DirectoryContextProviderType = {
  sections: Section[] | undefined
  currentSection: Section | undefined
  superSections: SuperSection[] | undefined
  currentSuperSection: SuperSection
  chapter: Chapter
  diseases: Disease[] | undefined
  diseasesCurrentPage: number
  diseasesTotalPages: number
  params: {
    superSection: string
    sectionId: string
    chapterId: string
    diseaseId: string
  }
  isLoading: boolean
  isFetching: boolean
  setChapterDiseasesPage: Dispatch<SetStateAction<number>>
}

const DirectoryContext = createContext<DirectoryContextProviderType | null>(null)

const DirectoryProvider = ({ children }: ChildrenOnlyProps) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [chapterDiseasesPage, setChapterDiseasesPage] = useState<number>(1)

  const matchSuperSection = useMatch(PathRiverRoutes.DIRECTORY_SUPERSECTION)
  const matchSuperSectionSection = useMatch(PathRiverRoutes.DIRECTORY_SUPERSECTION_SECTION)
  const matchChapter = useMatch(PathRiverRoutes.DIRECTORY_CHAPTER)
  const matchDiseaseDetails = useMatch(PathRiverRoutes.DIRECTORY_DISEASE_DETAILS)

  const superSectionParam = (matchSuperSection ?? matchSuperSectionSection ?? matchChapter ?? matchDiseaseDetails)
    ?.params.superSection
  const sectionParam = (matchSuperSectionSection ?? matchChapter ?? matchDiseaseDetails)?.params.sectionId
  const chapterParam = (matchChapter ?? matchDiseaseDetails)?.params.chapterId
  const diseaseParam = matchDiseaseDetails?.params.diseaseId

  const cachedChapter = queryClient
    .getQueryData<Chapter[]>(SectionClient.queryKeys.getChapters(Number(chapterParam)))
    ?.find((it) => it.id === Number(matchChapter.params.chapterId))
  const { data: fetchedChapter, isLoading: isLoadingChapter } = useQueryGetChapter(
    chapterParam,
    !isValue(cachedChapter)
  )

  const { data: chapterDiseases, isLoading: isLoadingDiseases } = useQueryGetDiseasesByChapter(
    chapterDiseasesPage,
    () => {
      notification.warning({
        message: 'There was an issue with fetching chapter diseases',
        description: 'We are redirecting you to Directory page',
        placement: 'topRight'
      })
      navigate(PathRiverRoutes.DIRECTORY)
    }
  )

  const { data = [], isLoading: isLoadingSections, isFetching: isFecthingSections } = useQueryGetSupersections()

  const currentSuperSection = data.find((it) => toKebabCase(it.name) === superSectionParam)

  const sections = data
    .reduce((acc, curr) => {
      acc = acc.concat(curr.sections)
      return acc
    }, [])
    .filter((it) => isValue(it))

  const value = useMemo(
    () => ({
      sections,
      currentSection: sections.find((it) => String(it.id) === sectionParam),
      superSections: data,
      currentSuperSection,
      chapter: cachedChapter ?? fetchedChapter,
      diseases: chapterDiseases?.diseases,
      diseasesCurrentPage: chapterDiseasesPage,
      diseasesTotalPages: chapterDiseases?.pagination[PaginationData.TOTAL_ITEMS],
      params: {
        superSection: superSectionParam,
        sectionId: sectionParam,
        chapterId: chapterParam,
        diseaseId: diseaseParam
      },
      isLoading: isLoadingSections || isLoadingDiseases || isLoadingChapter,
      isFetching: isFecthingSections,
      setChapterDiseasesPage
    }),
    [
      cachedChapter,
      chapterDiseases?.diseases,
      chapterDiseases?.pagination,
      chapterDiseasesPage,
      chapterParam,
      currentSuperSection,
      data,
      diseaseParam,
      fetchedChapter,
      isFecthingSections,
      isLoadingChapter,
      isLoadingDiseases,
      isLoadingSections,
      sectionParam,
      sections,
      superSectionParam
    ]
  )

  if (
    !isLoadingDiseases &&
    !isLoadingSections &&
    !isFecthingSections &&
    !isLoadingChapter &&
    isValue(superSectionParam) &&
    !isValue(currentSuperSection)
  ) {
    notification.warning({
      message: `Super section ${superSectionParam} not found.`,
      description: 'We are redirecting you to Directory page',
      placement: 'topRight'
    })
    return <Navigate to={PathRiverRoutes.DIRECTORY} />
  }

  return <DirectoryContext.Provider value={value}>{children}</DirectoryContext.Provider>
}

const useDirectory = (): DirectoryContextProviderType => {
  const context = useContext(DirectoryContext)
  if (!context) throw new Error('The hook useDirectory must be used inside an DirectoryProvider')
  return context
}

export { DirectoryProvider, useDirectory }
