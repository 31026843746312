import { useMemo } from 'react'
import { EllipsisOutlined } from '@ant-design/icons'
import { Dropdown, MenuProps } from 'antd'

import { Step, Tab } from '../../../../../types/step'
import { DiseaseInfo } from '../../../../../types/disease'
import { isValue } from '../../../../../types/typeGuard'
import styles from './ComparisonItemTabMenu.module.scss'

type Props = {
  diseaseInfo: DiseaseInfo[] | undefined
  step: Step
  onReplaceTab: (params: { step: Step; tabId: number; isImage?: boolean }) => void
}

export const ComparisonItemTabMenu = ({ diseaseInfo, step, onReplaceTab }: Props) => {
  const checkForTabName = (tab: Tab) => {
    if (tab.id === 28) {
      return 'Differential diagnoses'
    }
    return tab.name
  }
  const items: MenuProps['items'] = useMemo(() => {
    return step.tabs.reduce((accumulator, tab) => {
      accumulator = accumulator.concat({
        label: <div onClick={() => onReplaceTab({ step, tabId: tab.id })}>{checkForTabName(tab)}</div>,
        key: tab.id,
        disabled: step.selectedTabId === tab.id && !step.isImage
      })

      if (isValue(diseaseInfo) && (diseaseInfo.find((di) => di.tab_id === tab.id)?.images ?? [])?.length > 0) {
        accumulator = accumulator.concat({
          label: <div onClick={() => onReplaceTab({ step, tabId: tab.id, isImage: true })}>{tab.name}:Images</div>,
          key: `${tab.id}:images`,
          disabled: step.isImage && step.selectedTabId === tab.id
        })
      }

      return accumulator
    }, [])
  }, [diseaseInfo, onReplaceTab, step])

  return (
    items.length > 1 && (
      <Dropdown overlayClassName={styles.dropdown} menu={{ items }} trigger={['click', 'contextMenu']}>
        <EllipsisOutlined style={{ fontSize: 24 }} />
      </Dropdown>
    )
  )
}
