import { Typography } from 'antd'
import Skeleton from 'antd/es/skeleton/Skeleton'
import { useMatch } from 'react-router-dom'
import { PathRiverRoutes } from '../../../../router/routes'
import { isValue } from '../../../../types/typeGuard'
import { useDirectory } from '../context/DirectoryContext'
import styles from './DirectoryHeader.module.scss'

export const DirectoryHeader = () => {
  const matchChapter = useMatch(PathRiverRoutes.DIRECTORY_CHAPTER)
  const matchDiseaseDetails = useMatch(PathRiverRoutes.DIRECTORY_DISEASE_DETAILS)
  const { superSections, chapter, currentSuperSection, isLoading } = useDirectory()

  if (isValue(matchDiseaseDetails)) return <span />

  let description = 'Browse diseases by sections and chapters'

  if (isValue(currentSuperSection) && isValue(superSections)) {
    description = 'Click on a section to expand it and show its chapters'
  }

  if (isValue(matchChapter) && isValue(chapter)) {
    description = 'Click on a disease to see details'
  }

  return (
    <div className={styles['directory-page-header']}>
      {isLoading ? (
        <Skeleton.Button active style={{ width: 200 }} />
      ) : (
        <>
          <Typography.Title className={styles['directory-page-header-title']} level={3}>
            Directory
          </Typography.Title>
          <Typography.Text className={styles['directory-page-header-subtitle']}>{description}</Typography.Text>
        </>
      )}
    </div>
  )
}
