import { AxiosInstance } from 'axios'
import { ForgotPasswordRequest, ResetPasswordRequest } from '../../mutations/resetPassword/request'

export class ResetPasswordClient {
  private client: AxiosInstance

  constructor(client: AxiosInstance) {
    this.client = client
  }

  forgotPassword(request: ForgotPasswordRequest) {
    return this.client.post('auth/forgot-password', request)
  }

  resetPassword(request: ResetPasswordRequest) {
    return this.client.post('auth/reset-password', request)
  }
}
