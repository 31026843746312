import { Onboarding, OnboardingStatus } from '../types/onboarding'
import { useAuth } from '../context/auth'
import { useMutationUpdateOnboarding } from '../mutations/profile/useMutationUpdateOnboarding'

export const useOnboarding = (tour: Onboarding) => {
  const { profile, isLoadingProfile } = useAuth()
  const onBoardingUpdate = useMutationUpdateOnboarding()

  // hook method for marking tour as seen && updating user profile api
  const markTourSeen = () => {
    onBoardingUpdate.mutate(tour)
  }

  return {
    isSeenTour: !isLoadingProfile && profile?.on_boarding_deserialized[tour] === OnboardingStatus.Seen,
    markTourSeen
  }
}
