import { PropsWithChildren, useEffect } from 'react'
import { createPortal } from 'react-dom'

// eslint-disable-next-line @typescript-eslint/ban-types
export const Portal = ({ elementPortalId, children }: PropsWithChildren<{ elementPortalId: string }>) => {
  const mount = document.getElementById(elementPortalId)
  const el = document.createElement('div')

  useEffect(() => {
    mount?.appendChild(el)
    return () => {
      mount?.removeChild(el)
    }
  }, [el, mount])

  return createPortal(children, el)
}
