import { Navigate, useNavigate, useRoutes } from 'react-router-dom'
import { UnauthenticatedLayout } from '../layouts/UnauthenticatedLayout'
import { AuthenticatedLayout } from '../layouts/AuthenticatedLayout'
import { LoginPage } from '../pages/unauthenticated/login/LoginPage'
import { SignupPage } from '../pages/unauthenticated/signup/SignupPage'
import { ForgotPasswordPage } from '../pages/unauthenticated/forgotPassword/ForgotPasswordPage'
import { ResetPasswordPage } from '../pages/unauthenticated/resetPassword/ResetPasswordPage'
import { ActivatePage } from '../pages/unauthenticated/activate/ActivatePage'
import { PrivacyPage } from '../pages/unauthenticated/privacy/PrivacyPage'
import { TermsPage } from '../pages/unauthenticated/terms/TermsPage'
import { LogoutPage } from '../pages/authenticated/LogoutPage'
import { AboutPage } from '../pages/authenticated/AboutPage'
import { HomePage } from '../pages/authenticated/HomePage'
import { CasesPage } from '../pages/authenticated/CasesPage'
import { DirectoryPage } from '../pages/authenticated/DirectoryPage'
import { SuperSectionList } from '../pages/authenticated/DirectoryPage/subPages/SuperSectionList'
import { SuperSection } from '../pages/authenticated/DirectoryPage/subPages/SuperSection'
import { Chapter } from '../pages/authenticated/DirectoryPage/subPages/Chapter'
import { DiseaseDetails } from '../pages/authenticated/DirectoryPage/subPages/DiseaseDetails'
import { CommunityPage } from '../pages/authenticated/CommunityPage'
import { SearchPageWrapper } from '../pages/authenticated/search/SearchPageWrapper'
import { PathRiverRoutes } from './routes'
import { httpClient } from '../service/http/HttpClient'
import { ComparePage } from '../pages/authenticated/compare/ComparePage'
import { SubscriptionsPage } from '../pages/authenticated/Subscriptions'
import { MyAccountPage } from '../pages/authenticated/MyAccountPage/MyAccountPage'

export const Router = () => {
  const navigate = useNavigate()
  httpClient.setRouterNavigateFunction(navigate)

  return useRoutes([
    {
      element: <AuthenticatedLayout />,
      children: [
        { path: PathRiverRoutes.LOGOUT, element: <LogoutPage /> },
        { path: PathRiverRoutes.HOME, element: <HomePage /> },
        { path: PathRiverRoutes.CASES, element: <CasesPage /> },
        {
          path: PathRiverRoutes.DIRECTORY,
          element: <DirectoryPage />,
          children: [
            { path: PathRiverRoutes.DIRECTORY, element: <SuperSectionList /> },
            { path: PathRiverRoutes.DIRECTORY_SUPERSECTION, element: <SuperSection /> },
            { path: PathRiverRoutes.DIRECTORY_SUPERSECTION_SECTION, element: <SuperSection /> },
            { path: PathRiverRoutes.DIRECTORY_CHAPTER, element: <Chapter /> },
            { path: PathRiverRoutes.DIRECTORY_DISEASE_DETAILS, element: <DiseaseDetails /> }
          ]
        },
        { path: PathRiverRoutes.COMMUNITY, element: <CommunityPage /> },
        { path: PathRiverRoutes.ABOUT, element: <AboutPage /> },
        { path: PathRiverRoutes.PROFILE, element: <MyAccountPage /> },
        { path: PathRiverRoutes.SEARCH_CASE, element: <SearchPageWrapper /> },
        { path: PathRiverRoutes.COMPARE, element: <ComparePage /> },
        { path: PathRiverRoutes.SUBSCRIPTIONS, element: <SubscriptionsPage /> },
        { path: '/', element: <Navigate to={PathRiverRoutes.HOME} /> },
        { path: '*', element: <Navigate to={PathRiverRoutes.HOME} /> },
        { path: PathRiverRoutes.TERMS, element: <TermsPage /> }
      ]
    },
    {
      element: <UnauthenticatedLayout />,
      children: [
        { path: PathRiverRoutes.LOGIN, element: <LoginPage /> },
        { path: PathRiverRoutes.SIGNUP, element: <SignupPage /> },
        { path: PathRiverRoutes.FORGOT_PASSWORD, element: <ForgotPasswordPage /> },
        { path: PathRiverRoutes.RESET_PASSWORD, element: <ResetPasswordPage /> },
        { path: PathRiverRoutes.ACTIVATE, element: <ActivatePage /> },
        { path: PathRiverRoutes.PRIVACY, element: <PrivacyPage /> },
        { path: PathRiverRoutes.TERMS, element: <TermsPage /> },
        { path: '*', element: <Navigate to={PathRiverRoutes.LOGIN} /> }
      ]
    }
  ])
}
