import axios from 'axios'
import { secrets } from '../../environment'

class TokenRefreshClient {
  async refreshToken(apiToken: string, refreshToken: string): Promise<string> {
    const response = await axios.post<string>(
      `${secrets.baseUrl}/auth/refresh-token`,
      { refresh_token: refreshToken },
      { headers: { Authorization: `Bearer ${apiToken}` } }
    )
    return Promise.resolve(response.data)
  }
}

export const tokenRefreshClient = new TokenRefreshClient()
