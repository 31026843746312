import { Row, Col, Card, Typography } from 'antd'

import { useAuth } from '../../context/auth'
import { Mixpanel } from '../../util/tracking'

const { Title, Paragraph } = Typography

export const AboutPage = () => {
  Mixpanel.trackEvent('Visit About page')
  const { user } = useAuth()

  return (
    <Row>
      <Col span={16} offset={4}>
        <Card size="default" className="about-page-card">
          <Title level={2}>Thank you for trying Pathriver {user ? user.firstname : null}</Title>
          <Paragraph className="about-page-text">
            Pathriver is in closed beta testing phase. You are one of the first users to have the access to the app.
          </Paragraph>
          <Paragraph className="about-page-text">
            We at Pathriver are building this product for pathologists. Our mission is to make your daily work easier
            and faster.
          </Paragraph>
          <Paragraph className="about-page-text">
            For the beginning, we are building a product centered around supporting your diagnostic decision making
            process. But we have even more ideas on how to make your life as a pathologist easier and we will be
            bringing those to you in the future.
          </Paragraph>
          <Paragraph className="about-page-text">
            We want to make Pathriver the best tool available for modern pathologists. Therefore, if you have any
            feedback, suggestions or ideas don't hesitate to share them with us -{' '}
            <strong>we are building this product for you</strong>.
          </Paragraph>
          <Paragraph className="about-page-text">
            You can share your feedback using the form available{' '}
            <a
              href="https://forms.gle/gLpj45mhHWVkz7NV8"
              target="_blank"
              rel="noopener noreferrer"
              title="Pathriver feedback form">
              here
            </a>
            .
          </Paragraph>

          <Paragraph className="about-page-text">Thank you!</Paragraph>
          <Paragraph className="about-page-text">Pathriver team</Paragraph>
        </Card>
      </Col>
    </Row>
  )
}
