import { message } from 'antd'
import { AxiosError } from 'axios'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { AuthRoutes } from '../../router/routes'
import { httpClient } from '../../service/http/HttpClient'
import { ResetPasswordRequest } from './request'

export const useMutationResetPassword = () => {
  const navigate = useNavigate()
  return useMutation(
    async (request: ResetPasswordRequest) => {
      return httpClient.resetPassword.resetPassword(request)
    },
    {
      onSuccess() {
        message.success({
          content: 'Your password was successfuly changed. You can now log in with your new password.',
          duration: 3
        })
        navigate(AuthRoutes.LOGIN)
      },
      onError(error: AxiosError) {
        console.error(error)
        message.error({
          content:
            // eslint-disable-next-line max-len
            'There was issue with resetting your password. Please make sure you use the link you got via an email and try again.',
          duration: 3
        })
      }
    }
  )
}
