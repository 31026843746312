import { useState, useLayoutEffect } from 'react'

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false)
  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return isMobile
}
