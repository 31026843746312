import { CaseStatusTag } from '../../../../../components/caseStatus/CaseStatusTag'
import { DateTime, DateTimeFormat } from '../../../../../util/dateTime'
import { Disease } from '../../../../../types/disease'

export const columns = [
  {
    title: 'Case title',
    dataIndex: 'name',
    key: 'case-title',
    render: (text: string) => <b>{text}</b>
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: number) => <CaseStatusTag status={status} />
  },
  {
    title: 'Diagnosis',
    dataIndex: 'result_disease',
    key: 'result_disease',
    render: (resultDisease: Disease) => <span>{resultDisease?.title}</span>
  },
  {
    title: 'Last update',
    key: 'updated_at',
    dataIndex: 'updated_at',
    render: (updatedAt: Date) => (
      <span title={DateTime.format(updatedAt, DateTimeFormat.LL)}>{DateTime.fromNow(updatedAt)}</span>
    )
  }
]
