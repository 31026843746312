import { useState, useEffect, Fragment } from 'react'
import { Link, Navigate, Outlet, useLocation, useMatch, useNavigate } from 'react-router-dom'
import { Button, Drawer, Dropdown, Layout, Menu, Skeleton } from 'antd'
import { MenuOutlined, UserOutlined } from '@ant-design/icons'

import { generateMenuItems } from './utils'
import { useAuth } from '../context/auth'
import { useIsMobile } from '../hooks/useIsMobile'
import { PathRiverRoutes } from '../router/routes'
import LogoImg from './../assets/logo.svg'
import { Mixpanel } from '../util/tracking'
import { isValue } from '../types/typeGuard'
import { DateTime, DateTimeFormat } from '../util/dateTime'
import { NavLink } from 'react-router-dom'
import './AuthenticatedLayout.scss'
import { Role } from '../model/user'
import { AuthenticatedFooter } from './../pages/authenticated/components/AuthenticatedFooter'
import { LocalStorage } from '../service/LocalStorage'

const { Header, Content } = Layout

export const AuthenticatedLayout = () => {
  const { accessToken, user, profile, isLoadingProfile, daysFromSubscriptionExpiry, logout } = useAuth()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isHomePage = useMatch(PathRiverRoutes.HOME)
  const isProfilePage = useMatch(PathRiverRoutes.PROFILE)
  const isSubscriptionsPage = useMatch(PathRiverRoutes.SUBSCRIPTIONS)
  const isSearchPage = useMatch(PathRiverRoutes.SEARCH_CASE)
  const isComparePage = useMatch(PathRiverRoutes.COMPARE)
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false)
  const isMobile = useIsMobile()

  useEffect(() => {
    try {
      Mixpanel.trackLink('#sendBugReport', 'Click Send Bug Report link')
      Mixpanel.trackLink('#pathriverWebsite', 'Click Pathriver Website link')
    } catch (err) {
      console.error(err)
    }
  }, [])

  const isAdminLogin = window.location.search.includes('adminLogin')
  if (isAdminLogin) {
    const cookies = document.cookie
    const cookieArray = cookies.split('; ')
    let sharedDataToken = null
    let sharedDataRefreshToken = null
    for (const cookie of cookieArray) {
      const [name, value] = cookie.split('=')
      if (name === 'sharedData') {
        sharedDataToken = value.split('_prdata_')[0]
        sharedDataRefreshToken = value.split('_prdata_')[1]
      }
    }

    // eslint-disable-next-line no-console
    console.log('Shared Data from admin: ' + sharedDataToken)

    if (sharedDataToken) {
      LocalStorage.setAccessToken(sharedDataToken)
      LocalStorage.setRefreshToken(sharedDataRefreshToken)
      setTimeout(() => {
        window.location.href = '/directory'
      }, 200)
      return
    }
  }

  if (!accessToken) {
    return <Navigate to={PathRiverRoutes.LOGIN} />
  }

  if (
    !isHomePage &&
    !isProfilePage &&
    !isSubscriptionsPage &&
    daysFromSubscriptionExpiry <= 0 &&
    user.role !== Role.SUPERADMIN
  ) {
    return <Navigate to={PathRiverRoutes.HOME} />
  }

  if (isLoadingProfile) {
    return (
      <Layout className="app-layout">
        <Header className="header">
          <Link id="nav-logo" to={PathRiverRoutes.HOME} className="logo">
            <img src={LogoImg} alt="pathriver logo" />
            <div className="beta-indicator" />
          </Link>
          <div id="app-menu">
            {Array.from({ length: 3 }).map((_, index) => (
              <Fragment key={index}>
                <Skeleton />
                &nbsp;
              </Fragment>
            ))}
          </div>
        </Header>
        <Content className="app-content-wrapper">
          <div className="skeleton">
            <Skeleton />
            <Skeleton />
          </div>
        </Content>
      </Layout>
    )
  }

  const handleLogout = () => {
    Mixpanel.trackEvent('Logout')
    logout(() => navigate(PathRiverRoutes.LOGIN))
  }

  const mainMenuItems = [
    generateMenuItems(pathname),
    ...[
      isMobile ? (
        <Menu.Item key="logout">
          <Link to="/" onClick={handleLogout}>
            Log Out
          </Link>
        </Menu.Item>
      ) : null
    ]
  ]

  const userMenuItems = (
    <Menu className="user-menu-dropdown">
      <Menu.Item className="with-bottom-separator" key="user_info">
        {profile?.email ?? '-'}
      </Menu.Item>
      <Menu.Item key={PathRiverRoutes.PROFILE}>
        <Link to={PathRiverRoutes.PROFILE}>My Account</Link>
      </Menu.Item>
      <Menu.Item id="sendBugReport" key="report">
        <a target="_blank" rel="noopener noreferrer" href="https://forms.gle/gLpj45mhHWVkz7NV8">
          Send a suggestion or bug report
        </a>
      </Menu.Item>
      <Menu.Item id="pathriverWebsite" key="pathriver" className="with-bottom-separator">
        <a target="_blank" rel="noopener noreferrer" href="https://www.pathriver.com?utm_source=public_app">
          Pathriver website
        </a>
      </Menu.Item>
      <Menu.Item key={PathRiverRoutes.LOGIN}>
        <Link to={PathRiverRoutes.LOGIN} onClick={handleLogout}>
          Log Out
        </Link>
      </Menu.Item>
    </Menu>
  )

  return (
    <Layout className="app-layout">
      {isMobile ? (
        <Drawer
          placement="right"
          closable={true}
          onClose={() => setIsMobileMenuVisible(false)}
          open={isMobileMenuVisible}
          className="app-menu-drawer">
          <Menu>{mainMenuItems}</Menu>
        </Drawer>
      ) : null}

      <Header className="header">
        <Link id="nav-logo" to={PathRiverRoutes.HOME} className="logo">
          <img src={LogoImg} alt="pathriver logo" />
          <div className="beta-indicator" />
        </Link>
        {daysFromSubscriptionExpiry < 7 && user.role !== Role.SUPERADMIN && (
          <Button
            title="Upgrade now"
            className={`subscription-button ${daysFromSubscriptionExpiry > 0 ? 'warning' : 'alert'}`}>
            <NavLink to={PathRiverRoutes.SUBSCRIPTIONS}>
              {daysFromSubscriptionExpiry > 0
                ? `Trial expiring in ${Math.floor(daysFromSubscriptionExpiry)} days - Upgrade`
                : `Trial expired at ${DateTime.format(
                    new Date(profile?.subscription_expires_at),
                    DateTimeFormat.LL
                  )} - Upgrade`}
            </NavLink>
          </Button>
        )}

        {isMobile ? (
          <div className="app-menu-toggler" onClick={() => setIsMobileMenuVisible(true)}>
            <MenuOutlined id="app-menu" />
          </div>
        ) : (
          <>
            <Menu theme="light" mode="horizontal" id="app-menu">
              {mainMenuItems}
            </Menu>
            <div className="user-menu">
              <Dropdown overlay={userMenuItems} placement="bottomRight" trigger={['click']} arrow>
                <div className="user-menu-wrapper" id="user-menu-wrapper">
                  <UserOutlined className="user-menu-icon" />
                  {isValue(profile) ? (
                    <div className="user-menu-name">{`${profile.first_name} ${profile.last_name}`.trim()}</div>
                  ) : (
                    '-'
                  )}
                </div>
              </Dropdown>
            </div>
          </>
        )}
      </Header>

      <Content className="app-content-wrapper">
        <Outlet />
        {!isSearchPage && !isComparePage ? <AuthenticatedFooter /> : null}
      </Content>
    </Layout>
  )
}
