import Skeleton from 'antd/es/skeleton/Skeleton'
import { useNavigate } from 'react-router-dom'
import { usePageVisit } from '../../../../hooks/usePageVisit'
import { PathParams, PathRiverRoutes } from '../../../../router/routes'
import { isValue } from '../../../../types/typeGuard'
import { toKebabCase } from '../../../../util/stringUtil'
import { useDirectory } from '../context/DirectoryContext'
import styles from './SuperSectionList.module.scss'

export const SuperSectionList = () => {
  const navigate = useNavigate()
  const { isFetching, superSections } = useDirectory()

  usePageVisit('Directory', 'Supersections')

  return (
    <div className={styles['supersections-wrapper']}>
      {isFetching || !isValue(superSections)
        ? Array(9)
            .fill(0)
            .map((_, index) => (
              <div key={index} className={styles['supersection-card']}>
                <Skeleton.Button active style={{ width: 150 }} />
                <Skeleton.Button active style={{ width: 150 }} />
              </div>
            ))
        : superSections.map((it) => (
            <div
              className={`${styles['supersection-card']} ${(it.sections ?? []).length === 0 ? styles.disabled : ''}`}
              key={it.name}
              onClick={() => {
                if ((it.sections ?? []).length > 0) {
                  navigate(
                    PathRiverRoutes.DIRECTORY_SUPERSECTION.replace(PathParams.superSection, toKebabCase(it.name))
                  )
                }
              }}>
              <div className={styles['section-number']}>
                {(it.sections ?? []).length === 0 ? 'No' : it.sections?.length}
                {it.sections?.length === 1 ? ' Section' : ' Sections'}
              </div>
              <div className={styles['section-letter']}>{it.name?.split('  ')[0]?.charAt(0)?.toUpperCase()}</div>
              <div className={styles['section-title']}>{it.name}</div>
            </div>
          ))}
    </div>
  )
}
