import { httpClient } from './../service/http/HttpClient'
import { PathRiverErrorResponse } from '../types/error'
import { notification } from 'antd'

export const errorHandler = (error: PathRiverErrorResponse) => {
  const errorCode = getErrorCode(error)
  const errorMessage = getErrorMessage(error)

  if (errorCode === 401) {
    httpClient.logout()
    notification.info({
      message: 'Session expired. Please log in again.',
      placement: 'topRight'
    })
    return
  }

  console.error(errorMessage)
  notification.error({
    message: 'Something went wrong. Please try again.',
    description: `${errorCode}: ${errorMessage}`,
    placement: 'topRight'
  })
}

export const getErrorCode = (error: PathRiverErrorResponse): number => {
  return error.response?.data.code ?? error.response?.status ?? 0
}

export const getErrorMessage = (error: PathRiverErrorResponse): string | undefined => {
  return error.response?.data.error
}
