export enum MenuItemRoutes {
  HOME = '/home',
  CASES = '/cases',
  DIRECTORY = '/directory',
  COMMUNITY = '/community',
  ABOUT = '/about',
  PROFILE = '/my-account'
}

export const PathVariables = {
  caseId: ':caseId'
}

export enum SubRoutes {
  SEARCH = '/search',
  SEARCH_CASE = '/search/:caseId',
  COMPARE = '/compare/:caseId',
  DIRECTORY_SUPERSECTION = '/directory/:superSection',
  DIRECTORY_SUPERSECTION_SECTION = '/directory/:superSection/:sectionId',
  DIRECTORY_CHAPTER = '/directory/:superSection/:sectionId/:chapterId',
  DIRECTORY_DISEASE_DETAILS = '/directory/:superSection/:sectionId/:chapterId/:diseaseId'
}

export enum AuthRoutes {
  LOGIN = '/login',
  LOGOUT = '/logout',
  SIGNUP = '/signup',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password/:token',
  TOKEN = '/token',
  ACTIVATE = '/activate/:token',
  SUBSCRIPTIONS = '/subscriptions'
}

export const PathParams = {
  caseId: ':caseId',
  superSection: ':superSection',
  chapterId: ':chapterId',
  sectionId: ':sectionId',
  diseaseId: ':diseaseId'
}

export const PublicRoutes = {
  PRIVACY: '/privacy',
  TERMS: '/terms'
}

export const PathRiverRoutes = { ...MenuItemRoutes, ...SubRoutes, ...AuthRoutes, ...PublicRoutes }
// eslint-disable-next-line prettier/prettier
export type PathRiverRoutes = (typeof PathRiverRoutes)[keyof typeof PathRiverRoutes]
