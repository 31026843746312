import { useAuth } from '../../../context/auth'
import { SubActiveHomePage } from './SubActiveHomePage'
import { SubInactiveHomePage } from './SubInactiveHomePage'
import { Role } from '../../../model/user'
import { isValue } from '../../../types/typeGuard'
import './HomePage.scss'

export const HomePage = () => {
  const { isLoadingProfile, profile, user, daysFromSubscriptionExpiry } = useAuth()

  if (isLoadingProfile && !isValue(profile)) {
    return <div className={`home-page active'}`}></div>
  }

  const isActiveSub = daysFromSubscriptionExpiry > 0 || user.role === Role.SUPERADMIN
  return (
    <div className={`home-page ${isActiveSub ? 'active' : 'inactive'}`}>
      {isActiveSub ? <SubActiveHomePage /> : <SubInactiveHomePage />}
    </div>
  )
}
