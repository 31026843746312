import { Skeleton } from 'antd'
import './SearchPageSkeleton.scss'

export const SearchPageSkeleton = () => {
  return (
    <div className="search-page-skeleton-wrapper">
      <div className="search-page-keywords">
        <Skeleton active />
      </div>
      <div className="search-page-result">
        <Skeleton active />
      </div>
    </div>
  )
}
