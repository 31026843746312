import { SearchOutlined } from '@ant-design/icons'
import { Button, Input, Radio, Table } from 'antd'
import { useState } from 'react'
import { useIsFetching } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { PathRiverRoutes } from '../../../../../router/routes'
import { CaseClient } from '../../../../../service/http/CaseClient'
import { Case } from '../../../../../types/case'
import { isEmptyString, isValue } from '../../../../../types/typeGuard'
import { useCasesSearchHandler } from '../../../../../util/url'
import { CasesSearchParams, CASES_TABLE_FILTERS, FiltersValueToTranslation } from './filters'
import { columns } from './tableConfig'

type Props = {
  cases: Case[]
  pageSize: number
  totalItems?: number
  currentPage?: number
  handleOnCreateCaseButtonClick: () => void
}

export const CasesDataTable = ({ cases, totalItems, currentPage, pageSize, handleOnCreateCaseButtonClick }: Props) => {
  const navigate = useNavigate()
  const { params, setCasesSearchParams } = useCasesSearchHandler()
  const isFetchingCases = useIsFetching(CaseClient.queryKeys.cases) > 0
  const [q, setQ] = useState(params.q)

  return (
    <>
      <div className="page-title">
        <div className="title">
          <h1>Search and Compare</h1>
        </div>
        <div className="actions">
          <Button onClick={handleOnCreateCaseButtonClick}>Create a New Comparison</Button>
        </div>
      </div>

      <div className="table-container">
        <div className="table-filters">
          <Radio.Group
            value={
              Object.values(CASES_TABLE_FILTERS).includes(params.status as CASES_TABLE_FILTERS) ? params.status : ''
            }
            onChange={(e) => setCasesSearchParams({ [CasesSearchParams.STATUS]: e.target.value })}>
            {Object.values(CASES_TABLE_FILTERS).map((filter: CASES_TABLE_FILTERS) => (
              <Radio.Button key={filter} value={filter}>
                {FiltersValueToTranslation[filter]}
              </Radio.Button>
            ))}
          </Radio.Group>
          <Input
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setCasesSearchParams({ [CasesSearchParams.QUERY]: q })
              }
            }}
            value={q}
            onChange={(e) => {
              setQ(e.target.value)
              if (isEmptyString(e.target.value ?? '')) {
                setCasesSearchParams({ [CasesSearchParams.QUERY]: '' })
              }
            }}
            placeholder="Search"
            className="search-filter"
            prefix={<SearchOutlined />}
            allowClear
          />
        </div>

        <div className="table-data">
          <Table
            rowKey="updated_at"
            dataSource={cases}
            columns={columns}
            onRow={(record) => ({
              onClick: () => navigate(`${PathRiverRoutes.SEARCH}/${record.id}`)
            })}
            loading={isFetchingCases}
            pagination={
              isValue(totalItems) &&
              isValue(currentPage) && {
                total: totalItems,
                pageSize,
                current: currentPage,
                responsive: true,
                showSizeChanger: true,
                pageSizeOptions: Array(4)
                  .fill(0)
                  .map((_, i) => (i + 1) * 15),
                onChange: (page, pageSize) =>
                  setCasesSearchParams({
                    [CasesSearchParams.PAGE]: String(page),
                    [CasesSearchParams.LIMIT]: String(pageSize)
                  })
              }
            }
          />
        </div>
      </div>
    </>
  )
}
