import { useMutation, useQueryClient } from 'react-query'
import { AxiosError } from 'axios'
import { message } from 'antd'

import { httpClient } from '../../service/http/HttpClient'
import { ProfileUpdateRequest } from './request'
import { Mixpanel } from '../../util/tracking'
import { ProfileClient } from '../../service/http/ProfileClient'
import { User } from '../../types/user'

export const useMutationUpdateProfile = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (request: ProfileUpdateRequest) => {
      const { data } = await httpClient.profile.update(request)
      return { data }
    },
    {
      onSuccess: (_, request) => {
        Mixpanel.trackEvent('Profile successfully updated')
        message.success({
          content: 'Profile successfully updated',
          duration: 3
        })

        queryClient.setQueryData<User>(ProfileClient.queryKeys.profile, (old) => {
          return {
            ...old,
            first_name: request.first_name,
            last_name: request.last_name
          }
        })
      },
      onError: (error: AxiosError) => {
        message.error({
          content: 'Update filed. Please try again.',
          duration: 3
        })
        console.error(error)
      }
    }
  )
}
