// https://google.github.io/styleguide/tsguide.html#exports
// https://google.github.io/styleguide/tsguide.html#interfaces-vs-type-aliases
export interface DtoUser {
  id: number
  email: string
  api_token: string
  refresh_token: string
  first_name: string
  last_name: string
  role: number
  active: boolean
  avatar: string
}

export interface DtoAssignedUser {
  active: boolean
  avatar: string | null
  career_stage: string
  created_at: string
  email: string
  first_name: string
  last_name: string
  id: number
  refferal_source: string
  role: number
  specialty: string
  token: string
  updated_at: string
  years_of_experience: string
}

export enum Role {
  SUPERADMIN = 0,
  ADMIN = 10,
  EDITOR = 20,
  AUTHOR = 30,
  USER = 40
}
