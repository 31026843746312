import { useEffect } from 'react'
import { isValue } from '../types/typeGuard'
import { Mixpanel } from '../util/tracking'

export const usePageVisit = (category: string, event: string | undefined) => {
  useEffect(() => {
    if (isValue(event)) {
      Mixpanel.trackEvent(`Page visit: ${category}: ${event}`)
      // eslint-disable-next-line no-console
      console.log(`Page visit: ${category}: ${event}`)
    }
  }, [category, event])
}
