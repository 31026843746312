import { AxiosInstance, AxiosResponse } from 'axios'

import { User } from './../../types/user'
import {
  ProfileUpdateRequest,
  PasswordUpdateRequest,
  OnBoardingUpdateRequest,
  SubscriptionUpdateRequest
} from '../../mutations/profile/request'
export class ProfileClient {
  private client: AxiosInstance

  // https://tkdodo.eu/blog/effective-react-query-keys
  static queryKeys = {
    profile: ['Profile']
  }

  constructor(client: AxiosInstance) {
    this.client = client
  }

  getProfile(): Promise<AxiosResponse<User>> {
    return this.client.get('profile')
  }

  update(request: ProfileUpdateRequest) {
    return this.client.put('profile', request)
  }

  updateOnboarding(request: OnBoardingUpdateRequest) {
    return this.client.put('profile/onboarding', { onboarding_status: JSON.stringify(request) })
  }

  updatePassword(request: PasswordUpdateRequest) {
    return this.client.put('profile/password', request)
  }

  updateSubscription(request: SubscriptionUpdateRequest) {
    return this.client.put('profile/subscription', request)
  }
}
