import { Col, Popover, Row } from 'antd'
import { InfoOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { DiseaseResult } from '../../../../types/disease'
import { appImgBaseURL } from '../../../../environment'
import { injectHtml } from '../../../../util/injectUtil'
import PlaceholderImg from '../../../../assets/coming-soon.svg'

import './DiseaseItem.scss'
import { isEmptyString, isNotEmptyString, isValue } from '../../../../types/typeGuard'
import { DateTime, DateTimeFormat } from '../../../../util/dateTime'

type Props = {
  disease: DiseaseResult
  selected: boolean
  onClick: (disease: DiseaseResult) => void
  onToggleDisease?: (disease: DiseaseResult) => void
}

export const DiseaseItem = ({ disease, selected, onToggleDisease, onClick }: Props) => {
  const alternativeTitles = disease.alternative_titles ?? []
  const getInfoContent = () => {
    return (
      <div>
        {isNotEmptyString(disease.user_name) && <p>Editors: {disease?.user_name}</p>}
        {disease.updated_at && <p>Last update: {DateTime.format(disease.updated_at, DateTimeFormat.LL)}</p>}
      </div>
    )
  }
  return (
    <Row className={`disease-item ${selected ? 'selected' : ''}`} gutter={10}>
      {isValue(onToggleDisease) && (
        <Col sm={2} lg={1} id="disease-item-actions" className="disease-item-actions">
          <Popover content={selected ? 'Remove from comparison' : 'Add to comparison'}>
            {selected ? (
              <MinusOutlined onClick={() => onToggleDisease(disease)} />
            ) : (
              <PlusOutlined onClick={() => onToggleDisease(disease)} />
            )}
          </Popover>
          {(isNotEmptyString(disease.user_name) || disease.updated_at) && (
            <Popover content={getInfoContent}>
              <InfoOutlined id="disease-item-action-info" />
            </Popover>
          )}
        </Col>
      )}
      <Col
        sm={isValue(onToggleDisease) ? 22 : 24}
        lg={isValue(onToggleDisease) ? 23 : 24}
        className="disease-item-content-wrapper"
        id="disease-item-content-wrapper"
        onClick={() => onClick(disease)}>
        <Row>
          <Col sm={6} lg={4} className="cursor-pointer disease-item-img-wrapper">
            <img
              alt=""
              width="100%"
              onError={(e) => {
                e.currentTarget.src = PlaceholderImg
              }}
              src={disease?.title_image ? `${appImgBaseURL}2/${disease?.title_image.filename}` : PlaceholderImg}
            />
          </Col>
          <Col sm={16} lg={18} className="disease-item-info">
            {disease?.section && disease?.chapter && (
              <>
                <p className="disease-item-section-chapter">
                  {disease.section} &#8226; {disease.chapter}
                </p>
              </>
            )}

            <div className="cursor-pointer disease-item-title">{disease.title}</div>

            {alternativeTitles.length > 0 && (
              <div className="alternative-titles" title={alternativeTitles.map((it) => it).join(' - ')}>
                {alternativeTitles.map((it, index) => (
                  <span key={index}>{it}</span>
                ))}
              </div>
            )}

            {isNotEmptyString(disease.definition) && (
              <div
                className="disease-item-content ql-editor"
                dangerouslySetInnerHTML={{ __html: injectHtml(disease.definition) }}></div>
            )}
            {isEmptyString(disease.definition) && <div>Details are coming soon</div>}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
