import { useMutation, useQueryClient } from 'react-query'
import { httpClient } from '../../service/http/HttpClient'
import { ProfileClient } from '../../service/http/ProfileClient'
import { Onboarding, OnboardingStatus } from '../../types/onboarding'
import { isValue } from '../../types/typeGuard'
import { User } from '../../types/user'

export const useMutationUpdateOnboarding = () => {
  const queryClient = useQueryClient()
  const profile = queryClient.getQueryData<User>(ProfileClient.queryKeys.profile)
  return useMutation(
    async (tour: Onboarding) => {
      if (isValue(profile)) {
        await httpClient.profile.updateOnboarding({
          ...profile.on_boarding_deserialized,
          [tour]: OnboardingStatus.Seen
        })

        return {
          ...profile,
          on_boarding_deserialized: {
            ...profile.on_boarding_deserialized,
            [tour]: OnboardingStatus.Seen
          }
        }
      }

      return undefined
    },
    {
      onSuccess: (data) => {
        if (isValue(data)) {
          queryClient.setQueryData<User>(ProfileClient.queryKeys.profile, (old) => {
            return {
              ...old,
              on_boarding_deserialized: data.on_boarding_deserialized
            }
          })
        }
      }
    }
  )
}
