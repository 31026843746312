import { Card } from 'antd'
import { BlockOutlined, ReadOutlined, TeamOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '../../../context/auth'
import { Mixpanel } from '../../../util/tracking'
import { isValue } from '../../../types/typeGuard'
import { PathRiverRoutes } from '../../../router/routes'
import { useEffect, useRef } from 'react'
import { useInViewport } from 'react-in-viewport'
import { useOnboarding } from '../../../hooks/onboarding'
import { Onboarding } from '../../../types/onboarding'
import { Boarding } from 'boarding.js'
import { getHomePageSteps } from '../../../util/boarding'
import './HomePage.scss'

const routes = {
  cases: {
    path: PathRiverRoutes.CASES,
    event: 'Clicked Search and Compare on Home page'
  },
  directory: {
    path: PathRiverRoutes.DIRECTORY,
    event: 'Clicked Directory on Home page'
  },
  community: {
    path: PathRiverRoutes.COMMUNITY,
    event: 'Clicked Community on Home page'
  }
}

export const SubActiveHomePage = () => {
  const { profile, isLoadingProfile } = useAuth()
  const navigate = useNavigate()
  const elementRef = useRef()
  const { inViewport } = useInViewport(elementRef)
  const { isSeenTour, markTourSeen } = useOnboarding(Onboarding.HomePage)

  useEffect(() => {
    if (!isLoadingProfile) {
      const boarding = new Boarding({
        opacity: 0.55,
        allowClose: false,
        onDeselected: (element) => {
          if (!boarding.isActivated) {
            const id = element.getElement().id
            Mixpanel.trackEvent(`(Tutorial Home) [id=${id}] - Close button clicked`)
            if (id !== 'user-menu-wrapper') markTourSeen()
          }
        },
        closeBtnText: 'Close Tour'
      })
      boarding.defineSteps(getHomePageSteps(markTourSeen))

      if (inViewport && !isSeenTour) {
        try {
          boarding.start()
        } catch (error) {
          console.error(error)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingProfile, inViewport, profile])

  Mixpanel.trackEvent('Visit Home page')

  const handleClick = (route: keyof typeof routes) => {
    Mixpanel.trackEvent(routes[route].event)
    navigate(routes[route].path)
  }
  return (
    <>
      <h1>Welcome {isValue(profile) ? profile?.first_name : ''}</h1>

      <div className="home-page-grid">
        <Card
          ref={elementRef}
          id="my-cases-card-page"
          className="my-cases-card-page"
          bordered={false}
          onClick={() => handleClick('cases')}>
          <BlockOutlined />
          <h2>Search and Compare</h2>
          <p>Solve diagnostic cases through powerful search and comparison</p>
        </Card>

        <Card
          id="directory-card-page"
          className="directory-card-page"
          bordered={false}
          onClick={() => handleClick('directory')}>
          <ReadOutlined />
          <h2>Directory</h2>
          <p>Browse diseases by sections and chapters</p>
        </Card>

        <Card
          id="community-card-page"
          className="community-card-page"
          bordered={false}
          onClick={() => handleClick('community')}>
          <TeamOutlined />
          <h2>Community</h2>
          <p>Connect with others, share your knowledge and collaborate</p>
        </Card>
      </div>
    </>
  )
}
