import { Dispatch, SetStateAction } from 'react'
import { useQuery } from 'react-query'
import { CaseClient } from '../../service/http/CaseClient'
import { httpClient } from '../../service/http/HttpClient'
import { Case } from '../../types/case'
import { Disease } from '../../types/disease'
import { isValue } from '../../types/typeGuard'

type Props = {
  caseId: string | undefined
  isEnabled?: boolean
  onSuccess?: (data) => void
}

export const useQueryGetCase = ({ caseId, isEnabled = true, onSuccess }: Props) => {
  return useQuery(
    CaseClient.queryKeys.getCase(caseId),
    async () => {
      if (isValue(caseId)) {
        const { data } = await httpClient.cases.getCase(caseId)
        return data
      }
      return undefined
    },
    {
      enabled: isValue(caseId) && isEnabled,
      keepPreviousData: false,
      onSuccess: (data) => {
        if (isValue(onSuccess)) {
          onSuccess(data)
        }
      }
    }
  )
}

type CachedProps = Props & {
  setKeywordIds?: Dispatch<SetStateAction<number[]>>
  setSelectedDiseasesIds?: Dispatch<SetStateAction<number[]>>
  setSelectedDiseases?: Dispatch<SetStateAction<Disease[]>>
}

export const useGetCase = ({
  caseId,
  setKeywordIds,
  setSelectedDiseasesIds
}: CachedProps): { data: Case | undefined; isLoading: boolean; isError: boolean; refetch: () => void } => {
  // const queryClient = useQueryClient()
  // const cachedData = queryClient.getQueryData<Case | undefined>(CaseClient.queryKeys.getCase(caseId))
  const { data, isLoading, isError, refetch } = useQueryGetCase({
    caseId,
    isEnabled: !isValue(caseId)
  })

  if (isValue(data)) {
    if (isValue(setKeywordIds)) {
      setKeywordIds(data.keywords.map((it) => it.id))
    }
    if (isValue(setSelectedDiseasesIds) && isValue(data.selected_diseases)) {
      setSelectedDiseasesIds(data.selected_diseases.map((d) => d.id))
    }
  }
  // const caseData = cachedData ?? data
  // if (isValue(caseData)) {
  //   if (isValue(setKeywordIds)) {
  //     setKeywordIds(caseData.keywords.map((it) => it.id))
  //   }
  //   if (isValue(setSelectedDiseasesIds) && isValue(caseData.selected_diseases)) {
  //     setSelectedDiseasesIds(caseData.selected_diseases.map((d) => d.id))
  //   }
  //
  //   return { data: caseData, isLoading: false, isError, refetch }
  // }
  return { data, isLoading, isError, refetch }
}
