import { AxiosInstance, AxiosResponse } from 'axios'
import { Chapter } from '../../types/chapter'

export class ChapterClient {
  static queryKeys = {
    chapter: ['Chapter'],
    getById: (chapterId: string | undefined) => [...ChapterClient.queryKeys.chapter, chapterId]
  }

  private client: AxiosInstance

  constructor(client: AxiosInstance) {
    this.client = client
  }

  getById(chapterId: string): Promise<AxiosResponse<Chapter>> {
    return this.client.get(`/chapters/${chapterId}`)
  }
}
