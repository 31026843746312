export const SpecialtyOptions: string[] = [
  'General pathology',
  'Breast',
  'Cardiovascular',
  'Cytopathology',
  'Dermatopathology',
  'Endocrine',
  'Gastrointestinal',
  'Genitourinary',
  'Gynecologic',
  'Head and neck',
  'Hematopathology',
  'Lung and mediastinum',
  'Molecular Genetic',
  'Neuropathology',
  'Pediatric',
  'Placental',
  'Renal / Medical kidney',
  'Soft tissue and bone pathology',
  'Transplant pathology',
  'Autopsy',
  'Forensic',
  'Other'
]

export const SEPPARATOR = '|' // used to separate multiple values in a single field
