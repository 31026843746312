import { useQuery, useQueryClient } from 'react-query'
import { httpClient } from '../../service/http/HttpClient'
import { StepsClient } from '../../service/http/StepsClient'
import { Keyword } from '../../types/keyword'
import { KeywordGroup } from '../../types/keyword_group'
import { isValue } from '../../types/typeGuard'
import { Dispatch, SetStateAction } from 'react'
import { getUniqueKeywords } from '../util'
import { Step } from '../../types/step'

export const useQueryGetSteps = (isEnabled: boolean, setKeywords?: Dispatch<SetStateAction<Keyword[]>>) => {
  return useQuery(
    StepsClient.queryKeys.getSteps(),
    async () => {
      const { data } = await httpClient.steps.getSteps()

      for (const step of data) {
        const keywordGroups: Record<number, KeywordGroup> = []
        for (const tab of step.tabs) {
          if (isValue(tab.keyword_groups)) {
            for (const kGroup of tab.keyword_groups) {
              if (isValue(kGroup.keywords)) {
                kGroup.keywords = kGroup.keywords.map((keyword: Keyword) => {
                  keyword.keyword_group = kGroup
                  return keyword
                })
              }
              keywordGroups[kGroup.id] = kGroup
            }
          }
        }
        step.keyword_groups = Object.values(keywordGroups)
        // this one is used to set default selected tab in the step
        step.selectedTabId = step.tabs[0]?.id

        // TODO: will be removed, sorting needs to be implemented on keyword_groups level as well
        if (step.id === 5) {
          const orderIds = [64, 121, 92, 93, 63, 122]
          step.keyword_groups = step.keyword_groups.sort((a, b) => {
            const aIndex = orderIds.indexOf(a.id)
            const bIndex = orderIds.indexOf(b.id)
            if (aIndex === -1) {
              return 1 // a's id is not in orderIds, so put it after b
            } else if (bIndex === -1) {
              return -1 // b's id is not in orderIds, so put it after a
            } else {
              return aIndex - bIndex // both a and b are in orderIds, so sort them by their index in orderIds
            }
          })
        }
      }
      return data.sort((a, b) => a.ordering - b.ordering)
    },
    {
      enabled: isEnabled,
      onSuccess: (data) => {
        if (isValue(data) && isValue(setKeywords)) {
          setKeywords(getUniqueKeywords(data))
        }
      }
    }
  )
}

export const useGetSteps = (
  setKeywords?: Dispatch<SetStateAction<Keyword[]>>
): { data: Step[]; isLoading: boolean; isError: boolean } => {
  const queryClient = useQueryClient()
  const cachedData = queryClient.getQueryData<Step[] | undefined>(StepsClient.queryKeys.getSteps())
  const { data, isLoading, isError } = useQueryGetSteps(!isValue(cachedData), setKeywords)
  if (isValue(cachedData)) {
    if (isValue(setKeywords)) {
      setKeywords(getUniqueKeywords(cachedData))
    }

    return { data: cachedData, isLoading: false, isError }
  }
  return { data, isLoading, isError }
}
