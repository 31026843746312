import { Button, Skeleton, Typography } from 'antd'
import { SubscriptionPlan } from '../../../../types/subscriptions'
import styles from './style.module.scss'

type Props = {
  plan: SubscriptionPlan
  disabled: boolean
  onClick: () => void
}
export const SubscriptionCard = ({ plan, disabled, onClick }: Props) => {
  return (
    <div className={styles.wrapper}>
      <Typography.Title>{plan.name}</Typography.Title>
      <div className={styles.price}>
        <div className={styles.old}>&nbsp;${plan.price}&nbsp;</div>
        <div className={styles.new}>${plan.price * 0.8}</div>
        <div className={styles.priceNote}>* 20% Early-Access discount is applied</div>
      </div>
      <ul className={styles.features}>
        <li>Unlimited access</li>
        <li>Unlimited cases</li>
        <li>Directory</li>
        <li>Community</li>
      </ul>
      <Button disabled={disabled} type="primary" className={styles.action} onClick={onClick}>
        Choose {plan.name}
      </Button>
    </div>
  )
}

export const SubscriptionCardSkeleton = () => {
  return (
    <div className={styles.wrapper}>
      <Typography.Title>
        <Skeleton.Input size="small" />
      </Typography.Title>
      <div className={styles.price}>
        <div className={styles.old}>
          <Skeleton.Input size="small" />
        </div>
        <div className={styles.old}>
          <Skeleton.Input size="small" />
        </div>
        <div className={styles.priceNote}>
          <Skeleton.Input size="small" />
        </div>
      </div>
      <ul className={styles.features}>
        <li>
          <Skeleton.Input size="small" />
        </li>
        <li>
          <Skeleton.Input size="small" />
        </li>
      </ul>
      <Button type="primary" className={styles.action}>
        <Skeleton.Input size="small" />
      </Button>
    </div>
  )
}
