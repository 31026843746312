import { RightOutlined } from '@ant-design/icons'
import { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { useBreadcrumbs } from '../hooks/useBreadcrumbs'
import styles from './DirectoryBreadcrumbs.module.scss'

export const DirectoryBreadcrumbs = () => {
  const breadCrumbs = useBreadcrumbs()

  if (breadCrumbs.length <= 1) {
    return null
  }

  return (
    <div className={styles.breadcrumbs} data-id="breadcrumbs">
      {breadCrumbs.map((it, index) => {
        return (
          <Fragment key={it.label}>
            <NavLink to={it.pathname}>{it.label}</NavLink>
            {index < breadCrumbs.length - 1 && <RightOutlined />}
          </Fragment>
        )
      })}
    </div>
  )
}
