import { Button, Typography } from 'antd'
import { NavLink } from 'react-router-dom'

import { PathRiverRoutes } from '../../../router/routes'
import { Mixpanel } from '../../../util/tracking'

const isQuoteVisible = false

export const SubInactiveHomePage = () => {
  Mixpanel.trackEvent('Display subscription expired splash page')

  return (
    <>
      <div>
        <div className="content-wrapper">
          <Typography.Title level={2}>
            Upgrade Now,
            <br />
            Elevate Your Pathology Productivity
          </Typography.Title>
          <div className="content">
            <Typography.Paragraph>
              Your trial period has concluded, but the path to unparalleled productivity and cutting-edge pathology
              awaits you. Unlock the full potential of Pathriver and streamline pathology workflows, accelerate learning
              and easily collaborate.
            </Typography.Paragraph>

            <Typography.Title level={5}>Limited-Time Offer</Typography.Title>
            <Typography.Paragraph>
              Pathriver will expand in functionallity and will provide even more ways to improve your work and learning.
              If you upgrade now you will get a special early-access discount and <strong>pay 20% less</strong>.
            </Typography.Paragraph>

            <Button type="primary">
              <NavLink to={PathRiverRoutes.SUBSCRIPTIONS}>Upgrade Now</NavLink>
            </Button>

            <Typography.Paragraph>
              Need more time to decide? <a href="mailto:info@pathriver.com">Contact us here</a> and we can work on
              extending your trial.
            </Typography.Paragraph>
          </div>
        </div>
      </div>
      {isQuoteVisible ? (
        <div>
          <div className="content-wrapper">
            <Typography.Title level={2}>Here's what our Pro users say </Typography.Title>

            <div className="content">
              <div>
                <img
                  src="https://wallpapers.com/images/hd/bart-simpson-from-the-simpsons-6wvtyk3vwcidzuw7.jpg"
                  alt="Ime i prezime"
                />
                <div>
                  <p>dr. Ime Prezime</p>
                  <p>Bone Pathology Specialist</p>
                  <p>Mayo Clinic, NY, USA</p>
                </div>
              </div>
              <Typography.Paragraph>
                "Pathriver has helped me <b>increase my productivity significantly.</b> The number of cases I complete
                every day has grown and helped me free up time so I can focus more on <b>advancing my skills</b> - which
                I can also do through Pathriver.
                <br />
                <br />I would definitely say that <b>Pathriver is a must</b> for any modern and productive pathologist."
              </Typography.Paragraph>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}
