import { useQuery } from 'react-query'
import { httpClient } from './../../service/http/HttpClient'
import { SuperSectionClient } from '../../service/http/SuperSectionClient'

export const useQueryGetSupersections = () => {
  return useQuery(SuperSectionClient.queryKeys.superSections, async () => {
    const { data } = await httpClient.superSection.getAll()
    return data
  })
}
