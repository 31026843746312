import { useMutation } from 'react-query'

import { httpClient } from '../../service/http/HttpClient'
import { SubscriptionUpdateRequest } from './request'
import { LocalStorage } from '../../service/LocalStorage'

export const useMutationUpdateSubscription = () => {
  return useMutation(
    async (request: SubscriptionUpdateRequest) => {
      const { data } = await httpClient.profile.updateSubscription(request)
      return { data }
    },
    {
      onSuccess: () => {
        LocalStorage.setSubscriptionPending()
      }
    }
  )
}
