import { useQuery } from 'react-query'
import { httpClient } from '../../service/http/HttpClient'
import { ProfileClient } from '../../service/http/ProfileClient'
import { isValue, isNotEmptyString } from '../../types/typeGuard'

export const useQueryGetProfile = ({ enabled = false }: { enabled?: boolean } = {}) => {
  return useQuery(
    ProfileClient.queryKeys.profile,
    async () => {
      const { data: profile } = await httpClient.profile.getProfile()

      return {
        ...profile,
        subscription_expires_at: profile.subscription_expires_at * 1000,
        on_boarding_deserialized:
          isValue(profile.on_boarding_status) && isNotEmptyString(profile.on_boarding_status)
            ? JSON.parse(profile.on_boarding_status)
            : {}
      }
    },
    {
      enabled
    }
  )
}
