import { useEffect, useState } from 'react'
import { Card, Space, Typography } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'

import { PathRiverLoginLogo } from '../../../components/logo/PathRiverLoginLogo'
import { Mixpanel } from '../../../util/tracking'
import { httpClient } from '../../../service/http/HttpClient'
import { PathRiverRoutes } from '../../../router/routes'

const { Title } = Typography

export const ActivatePage = () => {
  const { token } = useParams<{ token: string | undefined }>()
  const [isError, setIsError] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
    // eslint-disable-next-line no-prototype-builtins
    Mixpanel.trackEvent('Visit Activate page')
  }, [])

  httpClient.session
    .activate(token)
    .then(() => {
      navigate(`${PathRiverRoutes.HOME}`)
    })
    .catch((err) => {
      console.error(err)
      setIsError(true)
    })

  return (
    <div className="signup-content">
      <PathRiverLoginLogo />

      <Card>
        <Space id="signup-form-wrapper" direction="vertical" size="small">
          <Title level={3}>Activating account</Title>
          {!isError && (
            <div>
              Please wait while we activate your account. You will be redirected to login page in a few seconds.
            </div>
          )}
          {isError && (
            <div style={{ color: 'red' }}>
              Something went wrong while activating your account. Please contact us at{' '}
              <a href="mailto:info@pathriver.com?subject=Account%20activation%20failed">info@pathriver.com</a>
            </div>
          )}
        </Space>
      </Card>

      <div className="signup-footer">
        <div className="signup-footer-links">
          <a
            href="https://pathriver.com/terms-and-conditions"
            target="_blank"
            rel="noopener noreferrer"
            title="Terms and Conditions">
            Terms and Conditions
          </a>
          <a
            href="https://pathriver.com/privacy-policy"
            target="_blank"
            title="Privacy Policy"
            style={{
              margin: '0 15px'
            }}
            rel="noreferrer">
            Privacy Policy
          </a>
          <a href="https://pathriver.com" target="_blank" rel="noopener noreferrer" title="Landing page">
            Pathriver 2023
          </a>
        </div>
      </div>
    </div>
  )
}
