import { Button, Divider, message, Popconfirm, Popover, Typography } from 'antd'
import { Dispatch, SetStateAction, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useMutationUpdateCase } from '../../mutations/case/useMutationUpdateCase'
import { PathRiverRoutes } from '../../router/routes'
import { Case, CaseStatus } from '../../types/case'
import { DateTime } from '../../util/dateTime'
import { Mixpanel } from '../../util/tracking'
import { CaseStatusTag } from '../caseStatus/CaseStatusTag'
import './ActiveCase.scss'

type Props = {
  caseData: Case
  setSelectedDiseasesIds?: Dispatch<SetStateAction<number[]>>
}

export const ActiveCase = ({ caseData, setSelectedDiseasesIds }: Props) => {
  const navigate = useNavigate()
  const mutationUpdateCase = useMutationUpdateCase()
  const [open, setOpen] = useState(false)
  const [isConfirmOpen, setIsConfimOpen] = useState(false)

  const toggleOpenState = (newOpen: boolean) => {
    setOpen(newOpen)
    if (!newOpen) setIsConfimOpen(false)
  }

  const archiveCase = () => {
    Mixpanel.trackEvent('Archive case', {
      case_id: caseData.id,
      result_disease_id: caseData.result_disease_id,
      keyword_ids: caseData.keywords.map((keyword) => keyword.id),
      selected_disease_ids: caseData.selected_diseases?.map((it) => it.id)
    })

    mutationUpdateCase.mutate({
      caseId: String(caseData.id),
      body: {
        name: caseData.name,
        result_disease_id: caseData.result_disease_id,
        keyword_ids: caseData.keywords.map((keyword) => keyword.id),
        selected_disease_ids: caseData.selected_diseases?.map((it) => it.id),
        status: CaseStatus.Archived
      },
      setSelectedDiseasesIds,
      onSuccessCallback: () => {
        Mixpanel.trackEvent('Archive case success', {
          case_id: caseData.id
        })
        message.success({
          content: 'Case archived!',
          duration: 3
        })
        navigate(PathRiverRoutes.CASES)
      }
    })
  }

  return (
    <Popover
      content={
        <div className="active-case-button-wrapper">
          <Typography.Paragraph>Last saved {DateTime.humanizedDuration(caseData.updated_at)}</Typography.Paragraph>
          <Divider style={{ margin: 0 }} />
          {caseData.status === CaseStatus.Open ? (
            <Popconfirm
              title="Are you sure you want to archive this case?"
              open={open && isConfirmOpen}
              onConfirm={archiveCase}
              okButtonProps={{ loading: mutationUpdateCase.isLoading }}
              onCancel={() => setIsConfimOpen(false)}
              okText="Yes"
              cancelText="Dismiss"
              placement="bottomRight">
              <Button type="text" onClick={() => setIsConfimOpen(true)}>
                Archive case
              </Button>
            </Popconfirm>
          ) : (
            <CaseStatusTag status={caseData.status} />
          )}
        </div>
      }
      title={caseData.name}
      trigger="click"
      placement="bottomRight"
      open={open}
      onOpenChange={toggleOpenState}>
      <Button type="link" className="manage-case-btn">
        Case: {caseData.name}
      </Button>
    </Popover>
  )
}
