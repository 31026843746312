/* eslint-disable max-len */
import { Skeleton, Typography } from 'antd'
import { useMatch } from 'react-router-dom'
import { useMemo } from 'react'
import { ImageSlider } from '../../../../components/image-slider/ImageSlider'
import { Role } from '../../../../model/user'
import { useQueryGetDisease } from '../../../../queries/diseases/useQueryGetDisease'
import { useGetSteps } from '../../../../queries/steps/useQueryGetSteps'
import { PathRiverRoutes } from '../../../../router/routes'
import { isValue } from '../../../../types/typeGuard'
import { DateTime, DateTimeFormat } from '../../../../util/dateTime'
import { injectHtml } from '../../../../util/injectUtil'
import { DirectoryBreadcrumbs } from '../components/DirectoryBreadcrumbs'
import { useDirectory } from '../context/DirectoryContext'
import { DiseaseTabs } from './components/DiseaseTabs'
import PlaceholderImg from '../../../../assets/coming-soon.svg'
import styles from './DiseaseDetails.module.scss'
import { usePageVisit } from '../../../../hooks/usePageVisit'
import { Step, Tab } from '../../../../types/step'

const { Text, Title } = Typography

export const DiseaseDetails = () => {
  const match = useMatch(PathRiverRoutes.DIRECTORY_DISEASE_DETAILS)

  const { isLoading, currentSuperSection, currentSection, chapter } = useDirectory()
  const { isLoading: isLoadingStepsData, data: steps } = useGetSteps()
  const { data: disease, isFetching } = useQueryGetDisease({ diseaseId: Number(match?.params.diseaseId) })

  const images = useMemo(() => {
    const result = []
    const microscopicStep = steps?.find((step: Step) => step.id === 4)
    const ihcAndMolecularStep = steps?.find((step: Step) => step.id === 5)
    const microscopicImages = []
    const immunoImages = []

    if (isValue(disease)) {
      Object.entries(disease.tabs).forEach(([key, tab]) => {
        if (
          isValue(microscopicStep) &&
          microscopicStep.tabs.some((t: Tab) => String(t.id) === key) &&
          tab?.images?.length > 0
        ) {
          microscopicImages.push(...tab.images)
        } else if (
          isValue(ihcAndMolecularStep) &&
          ihcAndMolecularStep.tabs.some((t: Tab) => String(t.id) === key) &&
          tab?.images?.length > 0
        ) {
          immunoImages.push(...tab.images)
        } else if (tab?.images?.length > 0) {
          result.push(...tab.images)
        }
      })
    }

    return microscopicImages.concat(immunoImages).concat(result)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disease?.id, steps])

  const editors = disease?.assigned_users?.filter((it) => it.role < Role.AUTHOR) ?? []

  usePageVisit(
    'Directory',
    !isLoading
      ? `Supersection: ${currentSuperSection?.name}: Section: ${currentSection?.name}: Chapter: ${chapter?.name}: Disease: ${disease?.title}`
      : undefined
  )

  const renderIcd11 = () => {
    const icd11 = disease?.icd_11_coding?.split(',') ?? [] // TODO(harund): Mozes li molim te ovdje samo ostaviti komentar zasto se ovaj string razdvaja?

    if (!icd11.length) {
      return (
        <>
          <Title type="secondary" level={5}>
            ICD-11
          </Title>
          <Text>-</Text>
        </>
      )
    }

    return (
      <>
        <Title type="secondary" level={5}>
          ICD-11 category
        </Title>
        <div>
          {icd11.map((it, i) => (
            <Text key={`${it}-${i}`} className="mr-16">
              {it}
            </Text>
          ))}
        </div>
      </>
    )
  }

  return (
    <div className={styles.wrapper}>
      {isLoadingStepsData || isFetching || isLoading || !isValue(disease) ? (
        <Skeleton className="pt-16" />
      ) : (
        <>
          <DirectoryBreadcrumbs />
          <div className={styles['disease-content']}>
            <div>
              <Title level={2}>{disease.title}</Title>
              <div dangerouslySetInnerHTML={{ __html: injectHtml(disease.definition) }} />
              <div className={styles['info-group-wrapper']}>
                <Title type="secondary" level={5}>
                  Disease name synonyms
                </Title>
                <Text>{(disease.alternative_titles ?? []).join(', ') || '-'}</Text>
              </div>
              <div className={`${styles['info-group-wrapper']} ${styles['cols-2']}`}>
                <div>
                  {disease?.icds?.length > 0 ? (
                    <>
                      <Title type="secondary" level={5}>
                        ICD-O
                      </Title>
                      <div>
                        {disease.icds.map((icd) => (
                          <Text key={icd.id} className="mr-16">
                            {isValue(icd.code) && isValue(icd.category)
                              ? `${icd.code}/${icd.category}`
                              : 'Not applicable'}
                          </Text>
                        ))}
                      </div>
                    </>
                  ) : (
                    <>
                      <Title type="secondary" level={5}>
                        ICD-O
                      </Title>
                      <Text>Not applicable</Text>
                    </>
                  )}
                </div>

                <div>{renderIcd11()}</div>
              </div>
              <div className={styles['info-group-wrapper']}></div>
              <div className={styles['info-group-wrapper']}>
                <Title type="secondary" level={5}>
                  Snomed CT code
                </Title>
                <Text>{disease.snomed_ct_code ?? '-'}</Text>
              </div>
              <div className={`${styles['info-group-wrapper']} ${styles['cols-2']}`}>
                <div>
                  <Title type="secondary" level={5}>
                    Editors
                  </Title>
                  <Text>
                    {editors.length > 0
                      ? editors.map((it) => `${it.first_name} ${it.last_name}`.trim()).join(', ')
                      : '-'}
                  </Text>
                </div>
                <div>
                  <Title type="secondary" level={5}>
                    Last updated at
                  </Title>
                  <Text>
                    {isValue(disease.updated_at)
                      ? DateTime.format(new Date(disease.updated_at), DateTimeFormat.LL)
                      : '-'}
                  </Text>
                </div>
              </div>

              <div className={styles['tabs-wrapper']}>
                <DiseaseTabs steps={steps} disease={disease} />
              </div>
            </div>
          </div>
          <div className={styles['disease-images']}>
            {images.length > 0 ? (
              <ImageSlider images={images} thumbPerView={10} hideHover={true} mode="full" />
            ) : (
              <img alt="" height="250" width="100%" src={PlaceholderImg} />
            )}
          </div>
        </>
      )}
    </div>
  )
}
