import { Card, Button } from 'antd'

import './style.scss'

type Props = {
  onCreate: () => void
}

export const EmptyStateCard = (props: Props) => {
  return (
    <Card className="empty-state-card">
      <div className="pathriver-intro">
        <img src="https://pathriver.com/images/find.png" width="400" />
        <p>Solve diagnostic cases through powerful search and side-by-side comparison</p>
      </div>

      <Button type="primary" color="primary" onClick={props.onCreate} size="large">
        Create Your First Case
      </Button>
    </Card>
  )
}
