import { useQuery } from 'react-query'
import { SectionClient } from '../../service/http/SectionClient'
import { httpClient } from '../../service/http/HttpClient'
import { sortAlphabetically } from '../../util/arrayUtil'

export const useQueryGetSectionChapters = (sectionId: number) => {
  return useQuery(SectionClient.queryKeys.getChapters(sectionId), async () => {
    const response = await httpClient.section.getAllChapters(sectionId)
    return sortAlphabetically(response.data || [], 'name')
  })
}
