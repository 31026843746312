export const uniqueBy = <T>(arr: T[], key: keyof T): T[] =>
  Array.from(new Map(arr.map((item) => [item[key], item])).values())

export const sortAlphabetically = <T>(arr: T[], key: keyof T) => {
  return arr.sort((a, b) => {
    if (a[key] < b[key]) {
      return -1
    }
    if (a[key] > b[key]) {
      return 1
    }
    return 0
  })
}
