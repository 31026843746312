import { useMutation } from 'react-query'
import { AxiosError } from 'axios'
import { message } from 'antd'

import { httpClient } from '../../service/http/HttpClient'
import { PasswordUpdateRequest } from './request'
import { Mixpanel } from '../../util/tracking'

export const useMutationUpdatePassword = () => {
  return useMutation(
    async (request: PasswordUpdateRequest) => {
      const { data } = await httpClient.profile.updatePassword(request)
      return { data }
    },
    {
      onSuccess: () => {
        Mixpanel.trackEvent('Password changed successfully')
        message.success({
          content: 'Password changed successfully',
          duration: 3
        })
      },
      onError: (error: AxiosError) => {
        console.error(error)
        message.error({
          content: 'Update filed. Please try again.',
          duration: 3
        })
      }
    }
  )
}
