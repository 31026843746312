import { useState } from 'react'
import { Button, Collapse, Dropdown, MenuProps, Modal, Popconfirm, Popover, theme } from 'antd'
import { CheckCircleOutlined, CloseOutlined, MinusOutlined, PlusOutlined, EllipsisOutlined } from '@ant-design/icons'
import cloneDeep from 'lodash/cloneDeep'

import { DiseaseDetailsResponse } from '../../../../types/disease'
import { Step, Tab } from '../../../../types/step'
import { Image } from '../../../../types/image'
import { ImageSlider } from '../../../../components/image-slider/ImageSlider'
import { injectHtml } from '../../../../util/injectUtil'

import './DiseaseDetailModal.scss'
import { Keyword } from '../../../../types/keyword'
import { isEmptyArray } from 'formik'

const { Panel } = Collapse

type Props = {
  disease: DiseaseDetailsResponse
  visible
  onCloseModal: () => void
  onToggleDisease: (id: number) => void
  onDiseaseResolution: (disease: DiseaseDetailsResponse) => void
  steps: Step[]
  selectedDiseasesIds: number[]
}

export const DiseaseDetailModal = ({
  disease,
  steps,
  selectedDiseasesIds,
  visible,
  onCloseModal,
  onToggleDisease,
  onDiseaseResolution
}: Props) => {
  const [sortedSteps, setSteps] = useState<Step[]>(cloneDeep(steps) || [])
  const [activeTab, setActiveTabs] = useState<string[]>([])
  // sort images by tab_id step_id=4 => microscopic step id
  const microscopicStep = steps?.find((step: Step) => step.id === 4)
  const tempImages = []
  const microscopicImages = []

  Object.keys(disease.tabs).forEach((key) => {
    const tab = disease.tabs[key]
    if (microscopicStep.tabs.some((t: Tab) => t.id.toString() === key) && tab?.images?.length > 0) {
      microscopicImages.push(...tab.images)
    } else if (tab?.images?.length > 0) {
      tempImages.push(...tab.images)
    }
  })

  const allImages = [...microscopicImages, ...tempImages]
  // TODO: some bug on keen slider  needs to resize on open
  if (open) {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 10)
  }
  const getActiveTabHeader = (step: Step): string => {
    const selected = step.tabs.find((tab: Tab) => tab.id === step.selectedTabId)
    let stepName = step.name
    if (step.id === 6) {
      stepName = 'Differential diagnoses'
    }
    let name = step.selectedTabId !== step.tabs[0].id ? stepName + '-' : ''
    name += selected?.name

    if (step.id === 5) {
      // for IHC we want to show "Additional methods" if tab is not active
      // for IHC we want to show "Additional methods-<selected>" if tab is active
      const isActive = activeTab.includes('5')
      name = !isActive && step.selectedTabId === 25 ? 'Additional methods' : 'Additional methods-' + selected?.name
    }

    if (step.id === 6 && step.selectedTabId === 28) {
      name = 'Differential diagnoses'
    }
    // check if image is selected
    name += step.isImage ? ':Images' : ''

    return name
  }

  const handleTabClick = (step: Step, tabId: number, isImage?: boolean) => {
    const i = sortedSteps.findIndex((s: Step) => s.id === step.id)
    const lst = [...sortedSteps]
    lst[i].selectedTabId = tabId
    lst[i].isImage = isImage
    setSteps([...lst])
  }

  const handleToggleTabs = (tabIndex: string[]) => setActiveTabs(tabIndex)

  const handleCloseModal = () => {
    sortedSteps.forEach((step: Step) => (step.selectedTabId = step.tabs[0].id))
    setSteps([...sortedSteps])
    setActiveTabs([])
    onCloseModal()
  }
  const getItems = (step: Step, tabIndex: number) => {
    const items: MenuProps['items'] = []

    const checkForTabName = (tab: Tab) => {
      if (tab.id === 28) {
        return 'Differential diagnoses'
      }
      return tab.name
    }

    step.tabs.forEach((tab: Tab) => {
      items.push({
        label: (
          <div
            className="disease-detail-modal-context-menu-item"
            onClick={() => {
              handleTabClick(step, tab.id)
              if (!activeTab.some((it) => it === String(tabIndex))) {
                setActiveTabs([...activeTab, String(tabIndex)])
              }
            }}>
            {checkForTabName(tab)}
          </div>
        ),
        key: tab.id,
        disabled: step.selectedTabId === tab.id && !step.isImage
      })
      const selectedTab = disease.tabs[tab.id]

      if (selectedTab?.images?.length > 0) {
        items.push({
          label: (
            <div
              className="disease-detail-modal-context-menu-item"
              onClick={() => {
                handleTabClick(step, tab.id, true)
                if (!activeTab.some((it) => it === String(tabIndex))) {
                  setActiveTabs([...activeTab, String(tabIndex)])
                }
              }}>
              {tab.name}:Images
            </div>
          ),
          key: `${tab.id}:images`,
          disabled: step.isImage && step.selectedTabId === tab.id
        })
      }
    })
    return items
  }

  const renderImageGallery = (images: Image[] = []) =>
    images.length > 0 && <ImageSlider images={images} thumbPerView={10} hideHover={true} />

  const renderDIfDiagnoses = () => {
    if (!disease?.diff_diagnoses?.length) return <div>-</div>
    return disease?.diff_diagnoses
      ?.sort((a, b) => a.diff_disease_id - b.diff_disease_id)
      ?.map((diffDiagnose) => {
        const selected = selectedDiseasesIds.some((id) => id === diffDiagnose.diff_disease_id)
        return (
          <div
            key={diffDiagnose.diff_disease_id}
            className={`comparison-disease_diff_diagnose_container ql-editor ${selected ? 'selected' : ''}`}>
            <Popover placement="bottom" content={selected ? 'Remove from comparison' : 'Add to comparison'}>
              {selected ? (
                <MinusOutlined onClick={() => onToggleDisease(diffDiagnose.diff_disease_id)} />
              ) : (
                <PlusOutlined onClick={() => onToggleDisease(diffDiagnose.diff_disease_id)} />
              )}
            </Popover>

            <div className="comparison-disease_diff_diagnose_container-right">
              <p className="title">{diffDiagnose.name}</p>
              <div dangerouslySetInnerHTML={{ __html: injectHtml(diffDiagnose.comment) }}></div>
            </div>
          </div>
        )
      })
  }

  const renderGeneralInfo = () => {
    return (
      <div className="comparison-disease_definition_container ql-editor">
        <div
          dangerouslySetInnerHTML={{
            __html: injectHtml(disease.definition ?? '-')
          }}></div>

        <p className="icd-wrapper">
          {disease?.icds?.length > 0 ? (
            disease.icds.map((icd, i) => (
              <span
                key={i}
                style={{
                  marginRight: i === disease.icds.length - 1 ? 0 : 20
                }}>
                <b>ICD-O : </b>
                {!!icd.code || !!icd.category ? `${icd.code}/${icd.category}` : `Not applicable`}
              </span>
            ))
          ) : (
            <span>
              <b>ICD-O : </b> Not applicable
            </span>
          )}
        </p>
      </div>
    )
  }

  const sortIhcKeywords = (keywords: Keyword[]) => {
    const positiveIds = [64, 92, 63]
    const positiveKeywords: Keyword[] = keywords.filter((keyword) =>
      positiveIds.includes(keyword.keyword?.keyword_group_id)
    )
    const negativeKeywords: Keyword[] = keywords.filter(
      (keyword) => !positiveIds.includes(keyword.keyword?.keyword_group_id)
    )
    return [
      ...positiveKeywords.sort((a, b) => a.keyword?.name.localeCompare(b.keyword?.name)),
      ...negativeKeywords.sort((a, b) => a.keyword?.name.localeCompare(b.keyword?.name))
    ]
  }

  const renderTreatment = (step: Step) => {
    return step?.tabs?.map((tab: Tab, i: number) => {
      const active = disease.tabs[tab.id]
      return (
        <div
          className={`comparison-disease_definition_container ql-editor ${
            i >= step.tabs.length - 1 ? '--border-top --p-5' : ''
          }`}
          key={tab.id}>
          <p className="comparison-disease_treatment_title">{tab?.name}</p>
          <div
            dangerouslySetInnerHTML={{
              __html: injectHtml(active?.description ?? '-')
            }}></div>
        </div>
      )
    })
  }

  const renderReferencesOrResource = (isReference = false) => {
    return (
      <div
        className={`comparison-disease_definition_container ql-edito ${isReference ? '' : '--border-top  --p-5'}`}
        key={disease.id + (isReference ? '-references' : '-resources')}>
        <p className="comparison-disease_references_title">{isReference ? 'References' : 'Resources'}</p>
        <div
          dangerouslySetInnerHTML={{
            __html: injectHtml(isReference ? disease?.references ?? '-' : disease?.resources ?? '-')
          }}></div>
      </div>
    )
  }

  const renderTabContent = (step: Step) => {
    const activeTab = disease.tabs[step.selectedTabId]
    return (
      <div className="disease-detail-modal-tab-content">
        {step.isImage && activeTab?.images?.length > 0 ? (
          renderImageGallery(activeTab.images)
        ) : (
          <>
            {step.selectedTabId === 28 && renderDIfDiagnoses()}
            {step.id == 7 && renderTreatment(step)}
            {step.id == 8 && (
              <>
                {renderReferencesOrResource(true)}
                {renderReferencesOrResource()}
              </>
            )}
            {step.selectedTabId !== 28 && step.id !== 7 && step.id !== 8 && (
              <>
                {step.id === 1 && renderGeneralInfo()}
                {step.id !== 1 && (
                  <div
                    className="comparison-disease_definition_container ql-editor"
                    dangerouslySetInnerHTML={{
                      __html: injectHtml(activeTab?.description ?? '-')
                    }}></div>
                )}
                {!![25, 26, 27].includes(step.selectedTabId) && activeTab?.keywords?.length > 0 && (
                  <ul key={step.selectedTabId} className="--border-top">
                    {sortIhcKeywords(activeTab?.keywords)?.map((keywordInfo: Keyword) => {
                      // default value for  keywordInfo.weight is 50, so we don't need to show it
                      return (
                        <li key={keywordInfo.id}>
                          {keywordInfo.keyword.name} {keywordInfo?.weight !== 50 ? `≈ ${keywordInfo.weight}` : ''}{' '}
                          {keywordInfo.comment}
                        </li>
                      )
                    })}
                  </ul>
                )}
              </>
            )}
          </>
        )}
      </div>
    )
  }
  const renderTabMenu = (step: Step, tabIndex: number) => {
    const items = getItems(step, tabIndex)
    return (
      items.length > 1 && (
        <Dropdown menu={{ className: 'disease-detail-modal-context-menu', items }} trigger={['click', 'contextMenu']}>
          <EllipsisOutlined style={{ fontSize: 26 }} />
        </Dropdown>
      )
    )
  }
  const renderHeader = () => {
    const isSelectedDisease = selectedDiseasesIds.some((id) => disease.id === id)
    return (
      <div className="disease-detail-modal-header" id="disease-detail-modal-header">
        <div className="disease-detail-modal-header-toggle" id="disease-detail-modal-header-toggle">
          <Popover content={isSelectedDisease ? 'Remove from comparison' : 'Add to comparison'}>
            {isSelectedDisease ? (
              <MinusOutlined onClick={() => onToggleDisease(disease.id)} />
            ) : (
              <PlusOutlined onClick={() => onToggleDisease(disease.id)} />
            )}
          </Popover>

          {disease.title}
        </div>

        <div className="disease-detail-modal-header-actions">
          <Popconfirm
            placement="bottom"
            title="Confirm Your Selection"
            description="Are you sure you want to select this disease as case resolution?"
            onConfirm={() => onDiseaseResolution(disease)}
            okText="Yes"
            cancelText="No"
            icon={<CheckCircleOutlined />}>
            <Button icon={<CheckCircleOutlined />} id="disease-detail-modal-header-resolution-btn">
              Mark as Resolution
            </Button>
          </Popconfirm>

          <Button
            icon={<CloseOutlined />}
            onClick={handleCloseModal}
            className="disease-detail-modal-header-close-btn"
            id="disease-detail-modal-header-close-btn">
            Close
          </Button>
        </div>
      </div>
    )
  }

  const { token } = theme.useToken()

  const panelStyle = {
    marginBottom: 14,
    background: token.colorFillAlter,
    border: 'none'
  }

  return (
    <Modal
      centered
      open={visible}
      onCancel={handleCloseModal}
      footer={null}
      width={1000}
      title={renderHeader()}
      closeIcon={null}
      closable={false}
      className="disease-detail-modal">
      {disease && (
        <>
          <div className="top-image-container" id="top-image-container">
            {!isEmptyArray(allImages) && renderImageGallery(allImages)}
            {isEmptyArray(allImages) && <div className="disease-detail-modal-no-image">No images</div>}
          </div>
          <Collapse
            collapsible="header"
            onChange={handleToggleTabs}
            expandIcon={null}
            bordered={false}
            activeKey={activeTab}>
            {sortedSteps.map((step: Step, index: number) => (
              <Panel
                header={
                  <>
                    {step.id === 7 && 'Treatment and Prognosis'}
                    {step.id === 8 && 'References and Resources'}
                    {step.id !== 7 && step.id !== 8 && getActiveTabHeader(step)}
                  </>
                }
                key={step.id}
                style={panelStyle}
                extra={step.id !== 7 && renderTabMenu(step, index + 1)}
                showArrow={false}>
                {renderTabContent(step)}
              </Panel>
            ))}
          </Collapse>
        </>
      )}
    </Modal>
  )
}
