import { AxiosResponse } from 'axios'

export enum PaginationData {
  PAGE = 'page',
  LIMIT = 'limit',
  TOTAL_ITEMS = 'total-items',
  TOTAL_PAGES = 'total-pages'
}

export const DEFAULT_PAGE_SIZE = 30

export type PagedResponse<T> = Omit<AxiosResponse<T[]>, 'headers'> & {
  data: T[]
  headers: Record<PaginationData, number>
}
