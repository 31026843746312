import { Row, Col, Card, Form, Input, Button, Typography } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import { User } from '../../../../types/user'
import { useMutationUpdatePassword } from '../../../../mutations/profile/useMutationUpdatePassword'
import { useMutationUpdateProfile } from '../../../../mutations/profile/useMutationUpdateProfile'

type Props = {
  profile: User
}

export const ProfileForm = ({ profile }: Props) => {
  const [form] = useForm()
  const passwordUpdate = useMutationUpdatePassword()
  const profileUpdate = useMutationUpdateProfile()

  const onSubmit = () => {
    const oldPassword = form.getFieldValue('old_password')
    const newPassword = form.getFieldValue('password')
    const firstName = form.getFieldValue('first_name')
    const lastName = form.getFieldValue('last_name')

    if ((newPassword ?? '').length > 0 && (oldPassword ?? '').length > 0) {
      passwordUpdate.mutate(
        {
          old_password: oldPassword,
          new_password: newPassword
        },
        {
          onSuccess: () => {
            form.resetFields()
          }
        }
      )
    }
    if (firstName !== profile.first_name || lastName !== profile.last_name) {
      profileUpdate.mutate({
        first_name: firstName,
        last_name: lastName
      })
    }
  }
  return (
    <>
      <div className="top-header">
        <Typography.Title level={2}>My Account</Typography.Title>
        <Button type="primary" color="primary" htmlType="submit" shape="round" onClick={onSubmit}>
          Save Changes
        </Button>
      </div>
      <Card size="default" className="profile-page-card">
        <Form
          name="basic"
          initialValues={{ remember: true }}
          autoComplete="off"
          form={form}
          requiredMark="optional"
          layout="vertical">
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                name="first_name"
                label="First name"
                requiredMark={true}
                rules={[{ required: true, message: 'Please enter first name' }]}
                initialValue={profile.first_name}>
                <Input path-id="profile-page-first-name-input" type="text" placeholder="Enter your first name" />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                name="last_name"
                label="Last name"
                requiredMark={true}
                rules={[{ required: true, message: 'Please enter last name' }]}
                initialValue={profile.last_name}>
                <Input
                  path-id="profile-page-last-name-input"
                  type="text"
                  placeholder="Enter your last name"
                  value={profile.last_name}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            name="email"
            label="Email Address (can't be changed)"
            initialValue={profile.email}
            requiredMark={false}>
            <Input path-id="profile-page-email-input" disabled={true} type="email" placeholder="Enter your email" />
          </Form.Item>

          <Form.Item
            name="old_password"
            label="Old Password"
            requiredMark={true}
            rules={[{ required: true, message: 'Please enter your old password' }]}>
            <Input
              path-id="profile-page-old-password-input"
              type="password"
              placeholder="Enter your old password"
              autoComplete="old-password"
            />
          </Form.Item>
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                name="password"
                label="Password"
                requiredMark={true}
                rules={[{ required: true, message: 'Please enter your password' }]}>
                <Input
                  path-id="profile-page-password-input"
                  type="password"
                  placeholder="Enter your password"
                  autoComplete="new-password"
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                name="password_confirmation"
                label="Confirm Password"
                requiredMark={true}
                rules={[
                  { required: true, message: 'Please enter confirm password' },
                  {
                    required: true,
                    message: 'Password does not match',
                    validator: (_, value) => {
                      if (!value || form.getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(new Error('Password does not match'))
                    }
                  }
                ]}>
                <Input
                  path-id="profile-page-confirm-password-input"
                  type="password"
                  placeholder="Enter the password again"
                  autoComplete="confirm-new-password"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  )
}
