import React from 'react'
import { Typography } from 'antd'
import Result from 'antd/es/result'
import { useAuth } from '../../context/auth'

import styles from './style.module.scss'

export const SubscriptionResult = () => {
  const { profile } = useAuth()
  return (
    <Result
      className={styles.info}
      title="Your upgrade request has been received"
      extra={
        <Typography.Paragraph>
          Thank you for choosing to upgrade to Pathriver Pro.
          <br />
          <br />
          We have received your request and will soon send an invoice to your email:
          <br />
          <b>{profile?.email}</b>.
          <br />
          <br />
          Once you pay the invoice you will be able to continue using Pathriver and elevating your pathology work.
        </Typography.Paragraph>
      }
    />
  )
}
