import { Modal, Form, Input } from 'antd'
import { useForm } from 'antd/lib/form/Form'

import { CaseRequest } from '../../../../../mutations/case/request'
import { useMutationCreateCase } from '../../../../../mutations/case/useMutationCreateCase'

type Props = {
  visible: boolean
  onCloseModal: () => void
}

enum CreateCaseForm {
  FORM_NAME = 'createCaseForm',
  CASE_NAME = 'caseName'
}

export const CreateCaseModal = ({ visible, onCloseModal }: Props) => {
  const [form] = useForm()
  const createCaseMutation = useMutationCreateCase(onCloseModal)

  const onSubmitCreate = () => {
    const request: CaseRequest = { name: form.getFieldValue(CreateCaseForm.CASE_NAME) }
    createCaseMutation.mutate(request)
  }

  return (
    <Modal
      forceRender
      title="Enter the name for this case"
      open={visible}
      okText="Create Case"
      onOk={() => onSubmitCreate()}
      onCancel={onCloseModal}
      afterClose={() => form.resetFields()}
      destroyOnClose
      okButtonProps={{
        disabled:
          form.getFieldsError().filter(({ errors }) => errors.length).length > 0 || createCaseMutation.isLoading,
        className: 'pr-btn'
      }}>
      <Form name={CreateCaseForm.FORM_NAME} autoComplete="off" onFinish={onSubmitCreate} form={form}>
        <Form.Item
          name={CreateCaseForm.CASE_NAME}
          rules={[{ required: true, message: 'Please enter the name of the case' }]}>
          <Input autoFocus path-id="create-case-modal-name-input" type="text" placeholder="Enter the name here" />
        </Form.Item>
      </Form>
    </Modal>
  )
}
