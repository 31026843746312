import { Disease, DiseaseHistory } from './disease'
import { Keyword } from './keyword'

export interface Case {
  id: number
  name: string
  result_disease_id: number | null
  status: CaseStatus
  user_id: number
  created_at: string
  updated_at: string
  last_history: DiseaseHistory
  keywords: Keyword[]
  result_disease: Disease | null
  selected_diseases: Disease[] | null
}

export enum CaseStatus {
  Open = 10,
  Archived = 20,
  Resolved = 30
}

export const CaseStatusLabel: Record<CaseStatus, string> = {
  [CaseStatus.Open]: 'Open',
  [CaseStatus.Archived]: 'Archived',
  [CaseStatus.Resolved]: 'Resolved'
}
