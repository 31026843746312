import { Skeleton } from 'antd'

import { useAuth } from '../../../context/auth'
import { useMutationUpdateSubscription } from '../../../mutations/profile/useMutationUpdateSubscription'
import { useQueryGetSubscriptionPlans } from '../../../queries/subscriptionPlans/useQueryGetSubscriptionPlans'
import { LocalStorage } from '../../../service/LocalStorage'
import { SubscriptionCard, SubscriptionCardSkeleton } from './components/SubscriptionCard'
import { SubscriptionResult } from '../../../components/subscriptionResult/SubscriptionResult'
import styles from './style.module.scss'

import { Mixpanel } from '../../../util/tracking'

export const SubscriptionsPage = () => {
  Mixpanel.trackEvent('Visit Subscriptions page')

  const { isLoadingProfile, daysFromSubscriptionExpiry, isSuperAdmin } = useAuth()
  const { data, isLoading } = useQueryGetSubscriptionPlans()
  const subscriptionUpdate = useMutationUpdateSubscription()

  const renderContent = () => {
    if (isLoadingProfile) {
      return <Skeleton />
    }

    if (LocalStorage.isSubscriptionUpdatePending() && daysFromSubscriptionExpiry <= 0 && !isSuperAdmin) {
      return <SubscriptionResult />
    }

    if (isSuperAdmin || daysFromSubscriptionExpiry > 0) {
      LocalStorage.removeSubscriptionPendingState()
    }

    return (data ?? []).map((plan) => (
      <SubscriptionCard
        key={plan.name}
        plan={plan}
        disabled={subscriptionUpdate.isLoading}
        onClick={() => {
          Mixpanel.trackEvent('Click choose subscription plan', { plan })
          subscriptionUpdate.mutate({ plan_id: plan.id })
        }}
      />
    ))
  }

  return <div className={styles.wrapper}>{isLoading ? <SubscriptionCardSkeleton /> : renderContent()}</div>
}
