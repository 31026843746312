/* eslint-disable camelcase */
import qs from 'qs'
import { useQuery } from 'react-query'
import { useMatch } from 'react-router-dom'
import { PathRiverRoutes } from '../../router/routes'
import { DiseaseClient } from '../../service/http/DiseaseClient'
import { isValue } from '../../types/typeGuard'
import { httpClient } from './../../service/http/HttpClient'

export const useQueryGetDiseasesByChapter = (page: number, onError: () => void) => {
  const match = useMatch(PathRiverRoutes.DIRECTORY_CHAPTER)

  const queryString = isValue(match)
    ? qs.stringify({ chapter_id: match.params.chapterId, page, public: 'true' }, { addQueryPrefix: true })
    : undefined

  return useQuery(
    DiseaseClient.queryKeys.getAllByChapter(match?.params.chapterId, queryString),
    async () => {
      const { data, headers } = await httpClient.disease.getAll(queryString)
      return { diseases: data, pagination: headers }
    },
    {
      enabled: isValue(match) && isValue(match.params.chapterId),
      keepPreviousData: true,
      onError
    }
  )
}
