import { Alert, Skeleton } from 'antd'
import { NavLink } from 'react-router-dom'
import { useQueryGetSectionChapters } from '../../../../../queries/sections/useQueryGetSectionChapters'
import { PathParams, PathRiverRoutes } from '../../../../../router/routes'

import { Section } from '../../../../../types/section'
import { isValue } from '../../../../../types/typeGuard'

import styles from './SectionChapters.module.scss'

export const SectionChapters = ({ section, superSectionSlug }: { section: Section; superSectionSlug: string }) => {
  const { data: chapters, isLoading } = useQueryGetSectionChapters(section.id)

  if (isLoading) {
    return <Skeleton.Button active size="small" />
  }

  return (
    <div className={styles['chapters']}>
      {isValue(chapters) && chapters.length > 0 ? (
        chapters.map((it) => (
          <NavLink
            className={styles['chapter-link']}
            key={it.id}
            to={PathRiverRoutes.DIRECTORY_CHAPTER.replace(PathParams.superSection, superSectionSlug)
              .replace(PathParams.sectionId, String(section.id))
              .replace(PathParams.chapterId, String(it.id))}>
            {it.name}
          </NavLink>
        ))
      ) : (
        <Alert message="Selected section has no chapters attached to it" type="info" />
      )}
    </div>
  )
}
