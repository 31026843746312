import { useForm } from 'antd/es/form/Form'
import { Button, Form, Input, Typography } from 'antd'

import { UnauthenticatedFooter } from '../components/UnauthenticatedFooter'
import { UnauthenticatedFormWrapper } from '../components/UnauthenticatedFormWrapper'
import { useMutationForgotPassword } from '../../../mutations/resetPassword/useMutationForgotPassword'
import { ForgotPasswordRequest } from '../../../mutations/resetPassword/request'
import { NavLink } from 'react-router-dom'
import { AuthRoutes } from '../../../router/routes'
import { emailRegex } from '../../../util/regex'

export const ForgotPasswordPage = () => {
  const [form] = useForm()
  const mutation = useMutationForgotPassword()

  const onFinish = (values: ForgotPasswordRequest) => mutation.mutate(values)

  return (
    <UnauthenticatedFormWrapper pageName="Forgot password page" title="Forgot password?">
      {mutation.isSuccess ? (
        <div>
          <Typography.Text>
            We sent you an email with a reset password link. Please follow it to continue the password reset process.
          </Typography.Text>
          <div className="link-container" style={{ marginTop: 24 }}>
            <NavLink to={AuthRoutes.LOGIN}>Back to login</NavLink>
          </div>
        </div>
      ) : (
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          form={form}
          requiredMark="optional"
          layout="vertical">
          <Form.Item
            name="email"
            label="Email Address"
            rules={[
              { required: true, message: 'Please enter your email' },
              { pattern: emailRegex, message: 'Email is not valid' }
            ]}>
            <Input path-id="login-page-email-input" type="email" placeholder="Enter your email" />
          </Form.Item>
          <Form.Item shouldUpdate className="submit">
            {() => (
              <Button
                type="primary"
                color="primary"
                htmlType="submit"
                shape="round"
                disabled={
                  form.getFieldsError().filter(({ errors }) => errors.length).length > 0 ||
                  mutation.isLoading ||
                  form.getFieldValue('email') === ''
                }>
                Submit
              </Button>
            )}
          </Form.Item>
          <div className="link-container">
            <NavLink to={AuthRoutes.LOGIN}>Back to login</NavLink>
          </div>
        </Form>
      )}
      <UnauthenticatedFooter />
    </UnauthenticatedFormWrapper>
  )
}
