import './PathRiverLoginLogo.scss'
import LogoImg from './../../assets/logo.svg'

export const PathRiverLoginLogo = () => {
  return (
    <div className="path-river-login-logo">
      <img src={LogoImg} alt="pathriver logo"></img>
    </div>
  )
}
