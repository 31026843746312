import { createContext, useState, useContext, useMemo, useCallback } from 'react'
import decodeJwt from 'jwt-decode'

import { ChildrenOnlyProps } from '../types/general'
import { httpClient } from '../service/http/HttpClient'
import { LocalStorage } from '../service/LocalStorage'
import { JwtClaims } from '../types/session'
import { isValue } from '../types/typeGuard'
import { User } from '../types/user'
import { useQueryGetProfile } from '../queries/profile/useQueryGetProfile'
import { useQueryClient } from 'react-query'
import { DateTime } from '../util/dateTime'
import { Role } from '../model/user'
import { isSubscriptionVisible } from '../util/url'

type AuthContextProviderType = {
  accessToken: string | null
  user: JwtClaims | null
  profile: User | null
  isLoadingProfile: boolean
  daysFromSubscriptionExpiry: number
  isSuperAdmin: boolean
  logout: (logoutCallback: () => void) => void
  setAuthUser: (user: JwtClaims) => void
}

const AuthContext = createContext<AuthContextProviderType | null>(null)

// https://fettblog.eu/typescript-react-why-i-dont-use-react-fc/
const AuthProvider = ({ children }: ChildrenOnlyProps) => {
  const queryClient = useQueryClient()
  const accessToken = LocalStorage.getAccessToken()
  const { data: profile, isLoading: isLoadingProfile } = useQueryGetProfile({ enabled: isValue(accessToken) })
  const [user, setUser] = useState<JwtClaims | null>(() => {
    const accessToken = LocalStorage.getAccessToken()

    if (isValue(accessToken)) {
      try {
        const decodedToken = decodeJwt(accessToken) as {
          ID: number
          Email: string
          Firstname: string
          Lastname: string
          Role: number
          SubscriptionExpiresAt: number
          exp: number
        }

        if (isValue(decodedToken)) {
          return {
            id: decodedToken?.ID,
            email: decodedToken?.Email,
            firstname: decodedToken?.Firstname,
            lastname: decodedToken?.Lastname,
            role: decodedToken?.Role,
            subscriptionExpiresAt: decodedToken?.SubscriptionExpiresAt,
            exp: decodedToken?.exp
          }
        } else {
          throw Error('Error with decoding token')
        }
      } catch (e) {
        return null
      }
    }
    return null
  })

  const setAuthUser = useCallback((user: JwtClaims) => {
    setUser(user)
  }, [])

  const logout = useCallback((logoutCallback: () => void) => {
    setUser(null)
    LocalStorage.removeAccessToken()
    LocalStorage.removeRefreshToken()
    logoutCallback()
    queryClient.clear()
  }, [])

  httpClient.setContextLogout(logout)

  const daysFromSubscriptionExpiry = DateTime.durationInDaysFromNow(new Date(profile?.subscription_expires_at))
  const isSuperAdmin = user?.role === Role.SUPERADMIN

  if (isSuperAdmin || daysFromSubscriptionExpiry > 0) {
    LocalStorage.removeSubscriptionPendingState()
  }

  const value = useMemo(
    () => ({
      accessToken,
      user,
      profile,
      isLoadingProfile,
      // TODO: Koristi `daysFromSubscriptionExpiry` kada subscription bude gotov
      daysFromSubscriptionExpiry: isSubscriptionVisible ? daysFromSubscriptionExpiry : 100000,
      isSuperAdmin,
      logout,
      setAuthUser
    }),
    [accessToken, user, logout, setAuthUser, profile, isLoadingProfile, daysFromSubscriptionExpiry, isSuperAdmin]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const useAuth = (): AuthContextProviderType => {
  const context = useContext(AuthContext)
  if (!context) throw new Error('The hook useAuth must be used inside an AuthProvider')
  return context
}

export { AuthProvider, useAuth }
