import { Skeleton } from 'antd'
import { useAuth } from '../../../context/auth'
import { useQueryGetProfile } from '../../../queries/profile/useQueryGetProfile'
import { Mixpanel } from '../../../util/tracking'
import { isSubscriptionVisible } from '../../../util/url'
import { ProfileForm } from './components/ProfileForm'
import { ProfileSubscription } from './components/ProfileSubscription'
import './MyAccountPage.scss'

export const MyAccountPage = () => {
  Mixpanel.trackEvent('Visit Profile page')

  const { isSuperAdmin, daysFromSubscriptionExpiry, isLoadingProfile } = useAuth()
  const { data: profileData, isLoading } = useQueryGetProfile()

  return (
    <div className="profile-page">
      <div className="profile-page-content-wrapper">
        <div className="profile-page-form">
          {isLoadingProfile || isLoading ? <Skeleton /> : <ProfileForm profile={profileData} />}
        </div>
        {isSubscriptionVisible && (
          <>
            <div className="profile-page-separator" />
            <div className="profile-page-subscription">
              {isLoadingProfile || isLoading ? (
                <Skeleton />
              ) : (
                <ProfileSubscription
                  profile={profileData}
                  isSuperAdmin={isSuperAdmin}
                  daysFromSubscriptionExpiry={daysFromSubscriptionExpiry}
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
