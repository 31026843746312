import { Row, Col, Card, Typography } from 'antd'
import { TeamOutlined } from '@ant-design/icons'
import { Mixpanel } from '../../../util/tracking'

const { Title, Paragraph } = Typography

export const CommunityPage = () => {
  Mixpanel.trackEvent('Visit Community page')

  return (
    <div
      style={{
        background: 'whitesmoke',
        height: 'calc(100% - 40px)'
      }}>
      <Row>
        <Col span={12} offset={6}>
          <Card size="default" className="about-page-card">
            <div className="coming-soon-badge">Coming Soon</div>
            <Title level={1}>
              <TeamOutlined /> Community
            </Title>
            <Paragraph className="about-page-text">
              With Pathriver we are building a global community of pathologists who are passionate about improving
              outcomes for patients.
            </Paragraph>
            <Paragraph className="about-page-text">
              Community page will allow you to connect with other pathologists, to create private (closed) or public
              groups to share your cases and learn from others.
            </Paragraph>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
