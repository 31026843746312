import { Card, Typography } from 'antd'
import { PropsWithChildren, useEffect } from 'react'
import { PathRiverLoginLogo } from '../../../components/logo/PathRiverLoginLogo'
import { Mixpanel } from '../../../util/tracking'
import { UnauthenticatedFooter } from '../components/UnauthenticatedFooter'

type Props = PropsWithChildren<{ pageName: string; title?: string; className?: string }>

export const UnauthenticatedFormWrapper = ({ pageName, title, className, children }: Props) => {
  useEffect(() => {
    Mixpanel.trackEvent(`Visit ${pageName} page`)
  }, [pageName])

  return (
    <div className={`unauthtenticated-form-content ${className}`}>
      <PathRiverLoginLogo />
      <Card>
        {title && <Typography.Title level={3}>{title}</Typography.Title>}
        {children}
      </Card>
      <UnauthenticatedFooter />
    </div>
  )
}
