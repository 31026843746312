import { useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import { AxiosError } from 'axios'
import { message } from 'antd'

import { httpClient } from '../../service/http/HttpClient'
import { CaseRequest } from './request'
import { PathRiverRoutes } from '../../router/routes'
import { Mixpanel } from '../../util/tracking'

export const useMutationCreateCase = (closeCreationModal: () => void) => {
  const navigate = useNavigate()

  let _caseName = ''

  return useMutation(
    async (request: CaseRequest) => {
      _caseName = request.name
      const { data } = await httpClient.cases.create(request)
      return data
    },
    {
      onSuccess(response) {
        Mixpanel.trackEvent('Case created', {
          case_id: response,
          name: _caseName
        })
        closeCreationModal()
        navigate(`${PathRiverRoutes.SEARCH}/${response}`)
      },
      onError(error: AxiosError) {
        Mixpanel.trackEvent('Case creation error')
        console.error(error)
        message.error({
          content: 'Creating the case failed. Please try again.',
          duration: 5
        })
      }
    }
  )
}
