import { useForm } from 'antd/es/form/Form'
import { Button, Form, Input, message } from 'antd'

import { UnauthenticatedFooter } from '../components/UnauthenticatedFooter'
import { UnauthenticatedFormWrapper } from '../components/UnauthenticatedFormWrapper'
import { useMutationResetPassword } from '../../../mutations/resetPassword/useMutationResetPassword'
import { NavLink, useMatch } from 'react-router-dom'
import { AuthRoutes } from '../../../router/routes'
import { isValue } from '../../../types/typeGuard'

export const ResetPasswordPage = () => {
  const [form] = useForm()

  const match = useMatch(AuthRoutes.RESET_PASSWORD)
  const mutation = useMutationResetPassword()

  const onFinish = (values: { password: string }) => {
    if (isValue(match) && isValue(match.params.token)) {
      mutation.mutate({ password: values.password, token: match.params.token })
    } else {
      message.error({
        content:
          // eslint-disable-next-line max-len
          'Looks like you are not using the proper reset password link. Please make sure you use the link you got via an email and try again.',
        duration: 3
      })
    }
  }

  return (
    <UnauthenticatedFormWrapper pageName="Reset password page" title="Reset your password">
      <Form<{ password: string }>
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        requiredMark="optional"
        layout="vertical">
        <Form.Item
          name="password"
          label="Password"
          requiredMark={true}
          rules={[{ required: true, message: 'Please enter your password' }]}>
          <Input
            path-id="signup-page-password-input"
            type="password"
            placeholder="Enter your password"
            autoComplete="new-password"
          />
        </Form.Item>
        <Form.Item
          name="password_confirmation"
          label="Confirm Password"
          requiredMark={true}
          rules={[
            { required: true, message: 'Please enter confirm password' },
            {
              required: true,
              message: 'Password does not match',
              validator: (_, value) => {
                if (!value || form.getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('Password does not match'))
              }
            }
          ]}>
          <Input type="password" placeholder="Enter the password again" autoComplete="confirm-password" />
        </Form.Item>

        <Form.Item shouldUpdate className="submit">
          {() => (
            <Button
              type="primary"
              color="primary"
              htmlType="submit"
              shape="round"
              disabled={form.getFieldsError().filter(({ errors }) => errors.length).length > 0 || mutation.isLoading}>
              Reset password
            </Button>
          )}
        </Form.Item>
      </Form>
      <div className="link-container" style={{ marginTop: 24 }}>
        <NavLink to={AuthRoutes.LOGIN}>Back to login</NavLink>
      </div>
      <UnauthenticatedFooter />
    </UnauthenticatedFormWrapper>
  )
}
