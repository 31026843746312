import { Menu } from 'antd'
import { matchPath, Link } from 'react-router-dom'

import { MenuItemRoutes } from '../router/routes'
import { Mixpanel } from '../util/tracking'

const RouteMenuItemLabel: Record<MenuItemRoutes, { title: string; isVisible: boolean }> = {
  [MenuItemRoutes.HOME]: { title: 'Home', isVisible: true },
  [MenuItemRoutes.CASES]: { title: 'Search and Compare', isVisible: true },
  [MenuItemRoutes.DIRECTORY]: { title: 'Directory', isVisible: true },
  [MenuItemRoutes.COMMUNITY]: { title: 'Community', isVisible: true },
  [MenuItemRoutes.ABOUT]: { title: 'About', isVisible: false },
  [MenuItemRoutes.PROFILE]: { title: 'My Account', isVisible: false }
}

export const generateMenuItems = (pathname: string) => {
  return Object.values(MenuItemRoutes).map(
    (route: MenuItemRoutes) =>
      RouteMenuItemLabel[route].isVisible && (
        <Menu.Item
          key={RouteMenuItemLabel[route].title}
          className={matchPath(pathname, route) && 'ant-menu-item-selected'}
          onClick={() => Mixpanel.trackEvent(`Click ${RouteMenuItemLabel[route].title} in nav menu`)}>
          <Link to={route}>{RouteMenuItemLabel[route].title}</Link>
        </Menu.Item>
      )
  )
}
