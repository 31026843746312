import { message, Spin, Modal, Button } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CheckCircleOutlined } from '@ant-design/icons'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import cloneDeep from 'lodash/cloneDeep'

import { useQueryGetSteps } from '../../../queries/steps/useQueryGetSteps'
import { ComparisonItem } from './compareItem/ComparisonItem'
import { Step } from '../../../types/step'
import { Disease } from '../../../types/disease'
import { useMutationUpdateCase } from '../../../mutations/case/useMutationUpdateCase'
import { PathRiverRoutes, PathVariables } from '../../../router/routes'
import { isValue } from '../../../types/typeGuard'
import { Portal } from '../../../components/portal/Portal'
import { ActiveCase } from '../../../components/activeCase/ActiveCase'
import { CaseStatus } from '../../../types/case'
import { Mixpanel } from '../../../util/tracking'
import { Boarding } from 'boarding.js'
import { useOnboarding } from '../../../hooks/onboarding'
import { Onboarding } from '../../../types/onboarding'
import { getComparePageSteps } from '../../../util/boarding'
import { useAuth } from '../../../context/auth'
import { useQueryGetCase } from '../../../queries/cases/useQueryGetCase'
import { useQueryClient } from 'react-query'
import { CaseClient } from '../../../service/http/CaseClient'
import 'overlayscrollbars/overlayscrollbars.css'
import './ComparePage.scss'

const { info } = Modal

export const ComparePage = () => {
  const queryClient = useQueryClient()
  const { caseId } = useParams<{ caseId: string }>()
  const [steps, setSteps] = useState<Step[]>([])
  const [activeTabs, setActiveTabs] = useState<string[]>([])
  const [selectedDiseasesIds, setSelectedDiseasesIds] = useState<number[]>([])
  const { isLoading: isLoadingStepsData, data: stepsData, isSuccess: isSuccessStepsData } = useQueryGetSteps(true)
  const caseUpdateMutation = useMutationUpdateCase()
  const {
    data: caseData,
    isFetching: isLoadingCaseData,
    isError: isErrorCaseData
  } = useQueryGetCase({
    caseId,
    onSuccess: (data) => {
      setSelectedDiseasesIds(data.selected_diseases.map((d) => d.id))
    }
  })
  const { isLoadingProfile } = useAuth()
  const { isSeenTour, markTourSeen } = useOnboarding(Onboarding.ComparePage)
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoadingProfile && !isLoadingCaseData) {
      const boarding = new Boarding({
        opacity: 0.55,
        allowClose: false,
        scrollIntoViewOptions: 'no-scroll',
        onDeselected: (element) => {
          if (!boarding.isActivated) {
            const id = element.getElement().id
            Mixpanel.trackEvent(`(Tutorial Compare) [id=${id}] - Close button clicked`)
            markTourSeen()
          }
        }
      })

      boarding.defineSteps(getComparePageSteps(boarding, markTourSeen))
      if (!isSeenTour) {
        boarding.start()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingProfile, isLoadingCaseData])

  useEffect(() => {
    Mixpanel.trackEvent('Visit Compare page for case', {
      case_id: caseId,
      disease_ids: selectedDiseasesIds
    })

    if (isSuccessStepsData) {
      stepsData.forEach((step: Step) => (step.selectedTabId = step.tabs[0].id))
      setSteps(cloneDeep(stepsData))
      setActiveTabs([])
    }
  }, [isSuccessStepsData, stepsData])

  const onToggleTabs = (tabIndex: string[]) => {
    Mixpanel.trackEvent('Open tab in comparison', {
      case_id: caseId,
      tab_index: tabIndex
    })
    setActiveTabs(tabIndex)
  }

  const onReplaceTab = useCallback(
    (stepIndex: number) =>
      ({ step, tabId, isImage = false }: { step: Step; tabId: number; isImage?: boolean }) => {
        const i = steps.findIndex((s: Step) => s.id === step.id)
        steps[i].selectedTabId = tabId
        steps[i].isImage = isImage

        const _tab = step.tabs.find((t) => t.id === tabId)

        Mixpanel.trackEvent('Open tab in comparison', {
          case_id: caseId,
          step_id: steps[i].id,
          step_name: steps[i].name,
          tab_id: tabId,
          tab_name: _tab.name,
          replacement: true
        })

        setSteps([...steps])
        if (!activeTabs.some((it) => it === String(stepIndex))) {
          setActiveTabs(activeTabs.concat(String(stepIndex)))
        }
      },
    [activeTabs, steps, caseId]
  )
  const handleToggleDiffDisease = (id: number) => {
    const ids = selectedDiseasesIds ? [...selectedDiseasesIds] : []
    const index = selectedDiseasesIds?.findIndex((selected) => selected === id)
    if (index > -1) {
      Mixpanel.trackEvent('Deselect disease in compare-page under differential tab', {
        case_id: caseId,
        disease_id: id
      })
      ids.splice(index, 1)
    } else {
      Mixpanel.trackEvent('Select disease in compare-page under differential tab', {
        case_id: caseId,
        disease_id: id
      })
      ids.push(id)
    }
    // TODO: get case data from disease detail api and append to compare list
    caseUpdateMutation.mutate({
      caseId,
      body: {
        name: caseData.name,
        result_disease_id: caseData.result_disease_id,
        keyword_ids: caseData.keywords.map((keyword) => keyword.id),
        selected_disease_ids: ids,
        status: caseData.status
      },
      onSuccessCallback: () => {
        message.success(index > -1 ? 'Disease removed from comparison' : 'Disease added to comparison')
        setSelectedDiseasesIds(ids)
        if (index === -1) {
          queryClient.refetchQueries(CaseClient.queryKeys.getCase(caseId))
        }
      }
    })
  }
  const onDiseaseRemove = (disease: Disease) => {
    Mixpanel.trackEvent('Remove disease from comparison', {
      case_id: caseId,
      disease_id: disease.id
    })

    const newIds = selectedDiseasesIds.filter((sId) => sId !== disease.id)
    caseUpdateMutation.mutate({
      caseId,
      body: {
        name: caseData.name,
        result_disease_id: caseData.result_disease_id,
        keyword_ids: caseData.keywords.map((keyword) => keyword.id),
        selected_disease_ids: newIds,
        status: caseData.status
      },
      setSelectedDiseasesIds,
      onSuccessCallback: () => {
        message.success('Disease removed from comparison')
      }
    })
  }

  const onDiseaseResolution = (disease: Disease) => {
    Mixpanel.trackEvent('Select disease as resolution', {
      case_id: caseId,
      disease_id: disease.id
    })

    caseUpdateMutation.mutate({
      caseId,
      body: {
        name: caseData.name,
        result_disease_id: disease.id,
        keyword_ids: caseData.keywords.map((keyword) => keyword.id),
        selected_disease_ids: selectedDiseasesIds,
        status: CaseStatus.Resolved
      },
      setSelectedDiseasesIds,
      onSuccessCallback: () => {
        Mixpanel.trackEvent('Case resolved', {
          case_id: caseId,
          disease_id: disease.id
        })

        // TODO: we can check from the user DB records instead of doing it via LocalStorage
        const isFirstEverCase = false
        if (!isFirstEverCase) {
          message.success({
            content: 'Case resolved!',
            duration: 3
          })
          navigate(`${PathRiverRoutes.CASES}`)
        } else {
          info({
            title: 'First Case Resolved',
            icon: <CheckCircleOutlined />,
            content: 'You have resolved your first case with Pathriver!',
            okText: 'Dismiss',
            onOk: () => {
              navigate(`${PathRiverRoutes.CASES}`)
            }
          })
        }
      }
    })
  }

  const handleNavigateBackToSearch = () => {
    Mixpanel.trackEvent('Click back to search on Compare page', {
      case_id: caseId
    })
    navigate(PathRiverRoutes.SEARCH_CASE.replace(PathVariables.caseId, caseId))
  }

  const diseasesForComparison = (caseData?.selected_diseases ?? []).filter((it) => selectedDiseasesIds.includes(it.id))

  // dispatch resize event to fix the issue with the image slider
  setTimeout(() => {
    window.dispatchEvent(new Event('resize'))
  }, 10)

  return (
    <>
      {!isLoadingStepsData && isValue(caseData) && !isLoadingCaseData && (
        <Portal elementPortalId="active-case-button">
          <ActiveCase caseData={caseData} />
        </Portal>
      )}
      <div className="compare-page">
        <div className="compare-page_header">
          <h1>
            <span id="compare-page-header-case-name">{caseData ? caseData.name : ''}</span> Comparison
          </h1>
          <Button type="link" onClick={handleNavigateBackToSearch} className="back-btn" id="back-btn">
            Back to Search
          </Button>
        </div>

        <div className="comparison-container">
          <OverlayScrollbarsComponent
            options={{
              paddingAbsolute: true,
              overflow: {
                x: 'scroll',
                y: 'visible'
              },
              scrollbars: {
                theme: 'os-theme-dark',
                visibility: 'visible',
                autoHide: 'never'
              }
            }}>
            <div className="comparison-wrapper">
              {isLoadingCaseData || isLoadingStepsData ? (
                <div className="comparison-loading">
                  <Spin size="large" />
                </div>
              ) : (
                <>
                  {isErrorCaseData ? (
                    <h4>Please check selected diseases, and try again</h4>
                  ) : (
                    diseasesForComparison?.length > 0 &&
                    diseasesForComparison
                      .sort((a, b) => a.id - b.id)
                      .map((it) => (
                        <ComparisonItem
                          key={it.id}
                          disease={it}
                          steps={steps}
                          activeTabKey={activeTabs}
                          selectedDiseasesIds={selectedDiseasesIds}
                          onToggleDiffDisease={handleToggleDiffDisease}
                          onReplaceTab={onReplaceTab}
                          onToggleTabs={onToggleTabs}
                          onDiseaseRemove={diseasesForComparison.length > 2 ? onDiseaseRemove : undefined}
                          onDiseaseResolution={onDiseaseResolution}
                        />
                      ))
                  )}
                </>
              )}
            </div>
          </OverlayScrollbarsComponent>
        </div>
      </div>
    </>
  )
}
