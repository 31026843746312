import { useKeenSlider } from 'keen-slider/react'
import { Tooltip, Image as AImage } from 'antd'
import { useState } from 'react'
import 'keen-slider/keen-slider.min.css'

import './ImageSlider.scss'
import { Image } from '../../types/image'
import { appImgBaseURL } from '../../environment'
import { Mixpanel } from '../../util/tracking'

type Props = {
  images: Image[]
  hidePreview?: boolean
  hideHover?: boolean
  thumbPerView?: number
  mode?: 'full' | 'normal' | null
}

const ThumbnailPlugin = (mainRef) => {
  return (slider) => {
    function removeActive() {
      slider.slides.forEach((slide) => {
        slide.classList.remove('active')
      })
    }
    function addActive(idx) {
      slider.slides[idx].classList.add('active')
    }

    function addClickEvents() {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener('click', () => {
          if (mainRef.current) mainRef.current.moveToIdx(idx)
        })
      })
    }

    slider.on('created', () => {
      if (!mainRef.current) return
      addActive(slider.track.details.rel)
      addClickEvents()
      mainRef.current.on('animationStarted', (main) => {
        removeActive()
        const next = main.animator.targetIdx || 0
        addActive(main.track.absToRel(next))
        slider.moveToIdx(Math.min(slider.track.details.maxIdx, next))
      })
    })
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Arrow = (props: any) => {
  const disabeld = props?.disabled ? ' arrow--disabled' : ''
  return (
    <svg
      onClick={props.onClick}
      className={`arrow ${props.left ? 'arrow--left' : 'arrow--right'} ${disabeld}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24">
      {props.left && <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />}
      {!props.left && <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />}
    </svg>
  )
}
export const ImageSlider = ({ images, hidePreview, thumbPerView, hideHover, mode }: Props) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slides: images.length,
    loop: true,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
      handleChange(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    }
  })
  const [thumbnailRef] = useKeenSlider(
    {
      initial: 0,
      slides: {
        perView: thumbPerView || 8,
        spacing: 10
      }
    },
    // eslint-disable-next-line new-cap
    [ThumbnailPlugin(instanceRef)]
  )

  const handleChange = (nextImageIndex) => {
    Mixpanel.trackEvent('Change image in carousel on comparison', {
      next_image_index: nextImageIndex
    })
  }

  const sortedImages = images?.sort((a, b) => a.ordering - b.ordering)

  // dispatch resize event to fix the issue with the image slider
  setTimeout(() => {
    window.dispatchEvent(new Event('resize'))
  }, 100)

  return (
    <div className={`keen-slider-container --mode-${mode}`} id="keen-slider-container">
      {loaded && instanceRef.current && (
        <>
          <div className="arrow-wrapper">
            <Arrow
              left
              onClick={(e) => e.stopPropagation() || instanceRef.current?.prev()}
              disabled={currentSlide === 0}
            />
          </div>
          <div className="arrow-wrapper arrow-right">
            <Arrow
              onClick={(e) => e.stopPropagation() || instanceRef.current?.next()}
              disabled={currentSlide === instanceRef.current.track.details.slides.length - 1}
            />
          </div>
        </>
      )}
      <div ref={sliderRef} className="keen-slider">
        {sortedImages.map((img) => (
          <div key={img.id} className="keen-slider__slide">
            {!hidePreview && (
              <AImage
                className="keen-slider__slide-img"
                src={`${appImgBaseURL}1/${img.filename}`}
                preview={{
                  src: `${appImgBaseURL}0/${img.filename}`
                }}
              />
            )}
            {hidePreview && (
              <img className="keen-slider__slide-img" src={`${appImgBaseURL}1/${img.filename}`} alt={img.filename} />
            )}
            {!hideHover && (
              <Tooltip placement="bottom" title={img.description}>
                <p className="keen-slider__slide-legend">{img.description}</p>
              </Tooltip>
            )}
            {!!hideHover && <p className="keen-slider__slide-legend-full">{img.description}</p>}
          </div>
        ))}
      </div>
      <div ref={thumbnailRef} className="keen-slider thumbnail">
        {sortedImages.map((img) => (
          <div key={img.id} className="keen-slider__slide">
            <img src={`${appImgBaseURL}2/${img.filename}`} alt={img.filename} />
          </div>
        ))}
      </div>
    </div>
  )
}
