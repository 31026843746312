import { message } from 'antd'
import { AxiosError } from 'axios'
import { useMutation } from 'react-query'

import { httpClient } from '../../service/http/HttpClient'
import { ForgotPasswordRequest } from './request'

export const useMutationForgotPassword = () => {
  return useMutation(
    async (request: ForgotPasswordRequest) => {
      return httpClient.resetPassword.forgotPassword(request)
    },
    {
      onError(error: AxiosError) {
        console.error(error)
        message.error({
          content: 'There was an issue with reseting your password. Please check your email and try again.',
          duration: 3
        })
      }
    }
  )
}
