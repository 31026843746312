import qs from 'qs'
import { useSearchParams } from 'react-router-dom'
import { CASES_TABLE_FILTERS } from '../pages/authenticated/CasesPage/components/CasesDataTable/filters'
import { isEmptyString, isValue } from '../types/typeGuard'

export enum CasesSearchParams {
  PAGE = 'page',
  LIMIT = 'limit',
  QUERY = 'q',
  STATUS = 'status'
}

export type CasesSearchParamsType = Record<CasesSearchParams, string>

export const useCasesSearchHandler = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const params: Partial<CasesSearchParamsType> = qs.parse(String(searchParams)) as CasesSearchParamsType

  const queryString = qs.stringify(params, { addQueryPrefix: true })

  const setCasesSearchParams = (paramsToSet: Partial<CasesSearchParamsType>) => {
    if (
      isValue(paramsToSet.status) &&
      (!Object.values(CASES_TABLE_FILTERS).includes(paramsToSet.status as CASES_TABLE_FILTERS) ||
        isEmptyString(paramsToSet.status))
    ) {
      delete params.status
      delete paramsToSet.status
    }

    if (isValue(paramsToSet.q) && isEmptyString(paramsToSet.q)) {
      delete params.q
      delete paramsToSet.q
    }
    setSearchParams({ ...params, ...paramsToSet })
  }

  const isFilteringData = isValue(params.q) || isValue(params.status)

  return { params, queryString, isFilteringData, setCasesSearchParams }
}

export const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
export const isDevelop = window.location.hostname === 'app.dev.pathriver.com'
export const isSubscriptionVisible = isDevelop || isLocalhost
