import { AxiosInstance, AxiosResponse } from 'axios'
import { CaseRequest, CaseUpdateRequest } from '../../mutations/case/request'
import { PagedResponse } from '../../queries/types'
import { Case } from '../../types/case'
import { Disease } from '../../types/disease'
import { CasesSearchParamsType } from '../../util/url'

export class CaseClient {
  private BASE_URL = '/cases'
  private client: AxiosInstance

  static queryKeys = {
    cases: ['Cases'],
    getCase: (caseId: string | undefined) => [...CaseClient.queryKeys.cases, caseId],
    getCases: (params: Partial<CasesSearchParamsType>) => {
      const result = [...CaseClient.queryKeys.cases, 'List']
      for (const param of Object.values(params)) {
        if (param) result.push(param)
      }
      return result
    }
  }

  constructor(client: AxiosInstance) {
    this.client = client
  }

  create(request: CaseRequest): Promise<AxiosResponse<number>> {
    return this.client.post(this.BASE_URL, request)
  }

  update(caseId: string, request: CaseUpdateRequest): Promise<AxiosResponse<Case>> {
    return this.client.put(`${this.BASE_URL}/${caseId}`, request)
  }

  getCase(caseId: string): Promise<AxiosResponse<Case>> {
    return this.client.get(`${this.BASE_URL}/${caseId}`)
  }

  getCases(query: string): Promise<PagedResponse<Case>> {
    return this.client.get(`${this.BASE_URL}${query}`)
  }

  searchDiseases(caseId: number, request): Promise<PagedResponse<Disease>> {
    return this.client.post(`/cases/${caseId}/search?page=${request?.page}`, request)
  }
}
