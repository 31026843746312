import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Layout, Row, Col } from 'antd'

import { PathRiverRoutes } from '../router/routes'
import { useAuth } from './../context/auth'
import './UnauthenticatedLayout.scss'

export const UnauthenticatedLayout = () => {
  const auth = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (auth.user?.email) {
      navigate(PathRiverRoutes.HOME)
    }
  })

  return (
    <Layout className="unauthenticated-page-layout">
      <Row className="unauthenticated-page__container">
        {/* <Col sm={24} md={12} className="promotional-content__column"> */}
        {/** Ovdje mozemo staviti neki promotivni sadrzaj, sneak peak u aplikaciju ili neke
         * obavijesti bitne za korisnike */}
        {/* Harun: slazem se, neka stoji za kasnije. U prvoj verziji cemo ostaviti zakomentarisano. */}
        {/* <BookOutlined /> */}
        {/* </Col> */}
        <Col xs={24} className="page-content__column">
          <Outlet />
        </Col>
      </Row>
    </Layout>
  )
}
