import React from 'react'

import { Modal } from 'antd'

export const AuthenticatedFooter = () => {
  const [isDisclaimerModalOpen, setIsDisclaimerModalOpen] = React.useState(false)

  const showDisclaimerModal = () => {
    setIsDisclaimerModalOpen(true)
  }

  const handleDisclaimerOk = () => {
    setIsDisclaimerModalOpen(false)
  }

  const handleDisclaimerClose = () => {
    setIsDisclaimerModalOpen(false)
  }

  return (
    <>
      <div className="footer">
        <div className="footer-links">
          <span>
            <a href="/terms" target="_blank" rel="noopener noreferrer" title="Terms and Conditions">
              Terms and Conditions
            </a>
          </span>
          <span onClick={showDisclaimerModal}>Disclaimer</span>
          <span>
            <a href="/terms#copyright1" target="_blank" title="Copyright" rel="noreferrer">
              Copyright
            </a>
          </span>
        </div>
      </div>
      <Modal
        title="Disclaimer"
        open={isDisclaimerModalOpen}
        onOk={handleDisclaimerOk}
        onCancel={handleDisclaimerClose}
        okText="Close"
        cancelButtonProps={{ style: { display: 'none' } }}
        footer={null}>
        <p>
          Pathriver is an aiding tool for pathologists and should not be considered a replacement for professional
          judgment.
        </p>
        <p>
          It is meant to assist in the diagnostic process but does not provide direct medical advice or independent
          diagnoses.
        </p>
      </Modal>
    </>
  )
}
