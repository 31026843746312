/* eslint-disable camelcase */
import { message } from 'antd'
import { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { PathRiverRoutes, PathVariables } from '../../router/routes'

import { httpClient } from '../../service/http/HttpClient'
import { isValue } from '../../types/typeGuard'
import { CaseMutationRequest } from './request'
import { CaseClient } from '../../service/http/CaseClient'

export const useMutationUpdateCase = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return useMutation(
    async ({ caseId, body }: CaseMutationRequest) => {
      return httpClient.cases.update(caseId, body)
    },
    {
      onSuccess(
        { data },
        {
          caseId,
          body,
          shouldNavigateToCompare = false,
          shouldRefetchCaseData = false,
          setSelectedDiseases,
          setSelectedDiseasesIds,
          onSuccessCallback
        }
      ) {
        if (isValue(onSuccessCallback)) onSuccessCallback()

        if (isValue(data.selected_diseases)) {
          if (isValue(setSelectedDiseasesIds)) {
            setSelectedDiseasesIds(body.selected_disease_ids)
          }
          if (shouldNavigateToCompare) {
            navigate(PathRiverRoutes.COMPARE.replace(PathVariables.caseId, caseId))
          }
          if (isValue(setSelectedDiseases)) {
            setSelectedDiseases(data.selected_diseases as any)
          }
        }
        if (shouldRefetchCaseData) {
          queryClient.refetchQueries(CaseClient.queryKeys.getCase(caseId))
        }
      },
      onError(error: AxiosError) {
        console.error(error)
        message.error({
          content: 'Update case failed. Please try again.',
          duration: 3
        })
      }
    }
  )
}
