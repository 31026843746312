import { message } from 'antd'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'

import { httpClient } from '../../service/http/HttpClient'
import { LoginRequest } from './request'
import { DtoUser } from '../../model/user'
import { PathRiverRoutes } from '../../router/routes'
import { useAuth } from '../../context/auth'
import { Mixpanel } from '../../util/tracking'
import { LocalStorage } from '../../service/LocalStorage'
import { JwtClaims } from '../../types/session'

export const useMutationLogin = () => {
  const navigate = useNavigate()
  const { setAuthUser } = useAuth()

  return useMutation(
    async (request: LoginRequest) => {
      return httpClient.session.login(request)
    },
    {
      onSuccess(result) {
        const user = result.data as DtoUser
        Mixpanel.identify(user.id, {
          Email: user.email,
          email: user.email,
          first_name: user.first_name,
          last_name: user.last_name
        })

        Mixpanel.trackEvent('Login')
        LocalStorage.setAccessToken(user.api_token)
        LocalStorage.setRefreshToken(user.refresh_token)

        setAuthUser({
          id: user.id,
          email: user.email,
          firstname: user.first_name,
          lastname: user.last_name,
          role: user.role
        } as JwtClaims)
        navigate(PathRiverRoutes.HOME, { replace: true })
      },
      onError(error: AxiosError) {
        console.error(error)
        message.error({
          content:
            error?.response?.data === 'not active'
              ? 'Please verify your account before logging in. Check your email for the verification link.'
              : 'Login failed. Please try again.',
          duration: 3
        })
      }
    }
  )
}
