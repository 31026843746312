import React from 'react'

export const UnauthenticatedFooter = () => {
  return (
    <div className="footer">
      <div className="footer-links">
        <span>
          <a href="/terms" target="_blank" rel="noopener noreferrer" title="Terms and Conditions">
            Terms and Conditions
          </a>
        </span>
        <a
          href="https://app.pathriver.com/privacy"
          target="_blank"
          title="Privacy Policy"
          style={{
            margin: '0 15px'
          }}
          rel="noreferrer">
          Privacy Policy
        </a>
        <a href="https://pathriver.com" target="_blank" rel="noopener noreferrer" title="Landing page">
          Pathriver 2023
        </a>
      </div>
    </div>
  )
}
