/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import algoliasearch from 'algoliasearch/lite'
import {
  Configure,
  InstantSearch,
  Pagination,
  SearchBox,
  Stats,
  useClearRefinements,
  useHits,
  useRefinementList
} from 'react-instantsearch-hooks-web'
import { CheckCircleOutlined, CloseCircleOutlined, MinusOutlined, SearchOutlined } from '@ant-design/icons'
import { message, Modal } from 'antd'
import { useNavigate } from 'react-router-dom'
import debounce from 'lodash/debounce'
import { useInViewport } from 'react-in-viewport'
import { Boarding } from 'boarding.js'

import { Keyword } from '../../../types/keyword'
import { DiseaseResult } from '../../../types/disease'
import { Mixpanel } from '../../../util/tracking'
import { DiseaseItem } from './components/DiseaseItem'
import { CaseClient } from '../../../service/http/CaseClient'
import { httpClient } from '../../../service/http/HttpClient'
import { DiseaseDetailModal } from './components/DiseaseDetailModal'
import { useGetSteps } from '../../../queries/steps/useQueryGetSteps'
import { InstructionModal } from '../CasesPage/components/InstructionModal'
import { useQueryGetDisease } from '../../../queries/diseases/useQueryGetDisease'
import { useMutationUpdateCase } from '../../../mutations/case/useMutationUpdateCase'
import { CaseStatus } from '../../../types/case'
import { PathRiverRoutes, PathVariables } from '../../../router/routes'
import { secrets } from '../../../environment'

import { useAuth } from '../../../context/auth'
import { useOnboarding } from '../../../hooks/onboarding'
import { Onboarding } from '../../../types/onboarding'
import { getSearchPageSteps } from '../../../util/boarding'
import './SearchPage.scss'

const { info } = Modal

// eslint-disable-next-line no-undef
const searchClient = algoliasearch(secrets.algoliaAppId, secrets.algoliaToken)

type Props = {
  caseId: string
  caseName: string
  initialSearchTerm: string
  initialKeywordIds: number[]
  caseDiseases: DiseaseResult[]
  resultDiseaseId: number
  caseStatus: number
  isFetchingCaseData: boolean
  isGetCaseLoading: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const KeywordFilter = (props: any) => {
  const groupsWhitelist = ['Disease group', 'Location', 'IHC Positive', 'IHC Negative']

  const { items, refine } = useRefinementList({
    attribute: 'keywords.keyword_id',
    operator: 'and',
    limit: 3000
  })

  if (!items.length) {
    return <div className="keyword-filter-empty">No filters available</div>
  }

  let transformedItems = items.map((item) => {
    const keyword = props.allKeywords ? props.allKeywords.find((k) => k.id === Number(item.label)) : null
    if (!keyword) return null
    return {
      ...item,
      label: keyword?.name,
      keyword_group: keyword?.keyword_group.name
    }
  })

  transformedItems = transformedItems.filter((it) => it !== null)

  // group transformed items by keyword group
  const groupedItems = transformedItems.reduce((acc, item) => {
    const group = item.keyword_group

    if (!groupsWhitelist.includes(group)) return acc

    if (!acc[group]) {
      acc[group] = []
    }
    if (acc[group].length < 15) acc[group].push(item)
    return acc
  }, {})
  const groupNames = Object.keys(groupedItems)

  return (
    <div className="ais-RefinementList">
      {groupNames.length === 0 && <div className="keyword-filter-empty">No filters available</div>}

      {groupNames.map((groupName, i) => (
        <div key={i} className="ais-RefinementList-group">
          <div className="ais-RefinementList-label">{groupName}</div>
          <ul className="ais-RefinementList-list">
            {groupedItems[groupName].map((item, k) => (
              <li key={k}>
                <label>
                  <input
                    type="checkbox"
                    checked={item.isRefined}
                    onChange={() => {
                      refine(item.value)
                      props.onToggleKeyword(item.value)
                    }}
                  />
                  {item.label}
                  <span className="ais-RefinementList-count">{item.count}</span>
                </label>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  )
}

const KeywordFilterClearer = (props: any) => {
  const { canRefine: canClear, refine: clear } = useClearRefinements()

  if (!canClear) {
    return null
  }

  return (
    <div className="keyword-filter-clearer-wrapper">
      <div
        className="keyword-filter-clearer"
        onClick={() => {
          clear()
          props.onClear()
        }}>
        <CloseCircleOutlined
          style={{
            marginRight: 3
          }}
        />
        Clear filters
      </div>
    </div>
  )
}

const Results = ({ handleToggleDisease, handleViewDisease, selectedDiseasesIds }: any) => {
  const { hits } = useHits()

  if (!hits.length) return <div className="disease-results-empty">No diseases found</div>

  return (
    <React.Fragment>
      <div className="ais-Hits disease-results-wrapper">
        <ol className="ais-Hits-list disease-results-list">
          {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            hits.map((hit: any) => (
              <li key={hit.objectID} id="disease-results-item" className="ais-Hits-item disease-results-item">
                <DiseaseItem
                  key={hit.disease_id}
                  disease={{
                    disease_id: hit.disease_id,
                    title: hit.title,
                    alternative_titles: hit.alternative_titles,
                    section: hit.section,
                    chapter: hit.chapter,
                    definition: hit.definition,
                    title_image: {
                      id: hit.title_image,
                      filename: hit.title_image,
                      alt: hit.title,
                      created_at: hit.created_at,
                      updated_at: hit.updated_at,
                      description: '',
                      ordering: 0
                    },
                    user_name: hit.editors?.join(','),
                    updated_at: hit.updated_at
                  }}
                  onToggleDisease={handleToggleDisease}
                  onClick={handleViewDisease}
                  selected={selectedDiseasesIds?.some((selectedDiseaseId) => selectedDiseaseId === hit.disease_id)}
                />
              </li>
            ))
          }
        </ol>
      </div>

      <Pagination
        classNames={{
          root: 'search-pagination',
          list: 'search-pagination-list',
          item: 'search-pagination-item',
          selectedItem: 'search-pagination-item-selected'
        }}
      />
    </React.Fragment>
  )
}

export const SearchPageContent = ({
  caseId,
  caseName,
  initialKeywordIds,
  caseDiseases = [],
  resultDiseaseId,
  caseStatus,
  initialSearchTerm
}: // isFetchingCaseData,
// isGetCaseLoading
Props) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { isLoadingProfile } = useAuth()
  const [allKeywords, setAllKeywords] = useState<Keyword[]>([])
  const [allKeywordsOriginal, setAllKeywordsOriginal] = useState<Keyword[]>([])
  const [selectedKeywordIds, setSelectedKeywordIds] = useState<number[]>(initialKeywordIds ?? [])
  const [searchTerm, setSearchTerm] = useState<string>(initialSearchTerm ?? '')
  const [selectedDiseaseId, setDiseaseDetailId] = useState<number>()
  const [selectedDiseases, setSelectedDiseases] = useState<DiseaseResult[]>(caseDiseases)
  const [isInstructionModalVisible, setIsInstructionModalVisible] = useState<boolean>(false)
  const [isDiseaseDetailModalVisible, setIsDiseaseDetailModalVisible] = useState<boolean>(false)
  const [isComparisonWidgetActive, setIsComparisonWidgetActive] = useState<boolean>(false)

  const ref = useRef()
  const { isSeenTour, markTourSeen } = useOnboarding(Onboarding.SearchPage)
  const { inViewport } = useInViewport(ref)

  useEffect(() => {
    if (!isLoadingProfile) {
      const boarding = new Boarding({
        opacity: 0.55,
        allowClose: false,
        scrollIntoViewOptions: 'no-scroll',
        onDeselected: (element) => {
          if (!boarding.isActivated) {
            const id = element.getElement().id
            Mixpanel.trackEvent(`(Tutorial Search) [id=${id}] - Close button clicked`)
            if (id !== 'comparison-widget-footer-button-compare') markTourSeen()
          }
        }
      })
      boarding.defineSteps(getSearchPageSteps(boarding, markTourSeen))
      if (!boarding.isActivated && inViewport && !isSeenTour) {
        try {
          boarding.start()
        } catch (error) {
          console.error(error)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingProfile, inViewport])

  const setKeywordsLists = (k) => {
    setAllKeywords(k)
    setAllKeywordsOriginal(k)
  }

  const { data: stepsData } = useGetSteps(allKeywordsOriginal.length === 0 ? setKeywordsLists : undefined)

  const { data: selectedDiseaseDetail } = useQueryGetDisease({
    diseaseId: selectedDiseaseId,
    onSuccess: () => setIsDiseaseDetailModalVisible(true)
  })

  const caseUpdateMutation = useMutationUpdateCase()
  const selectedDiseasesIds = selectedDiseases?.map((it: DiseaseResult) => it.disease_id) || []

  // Show instruction modal for first time use
  useEffect(() => {
    // if (!LocalStorage.getInstructionModalStatus()) showInstructionModal()

    return () => {
      queryClient.removeQueries(CaseClient.queryKeys.getCase(caseId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hideInstructionModal = () => {
    setIsInstructionModalVisible(false)
  }

  const handleToggleKeyword = (keywordId: number) => {
    keywordId = Number(keywordId)
    const updatedIds = [...selectedKeywordIds]
    const index = updatedIds.findIndex((id) => id === keywordId)
    if (index > -1) {
      updatedIds.splice(index, 1)
    } else {
      updatedIds.push(keywordId)
    }
    setSelectedKeywordIds(updatedIds)

    // Clear filters search input
    const el = document.getElementById('search-filters-input') as HTMLInputElement
    if (el && el.value) {
      // eslint-disable-next-line no-console
      el.value = ''
      handleSearchFilters('')
    }

    Mixpanel.trackEvent('Update search keywords', {
      case_id: caseId,
      keyword_ids: updatedIds
    })
  }

  const handleClearKeywords = () => {
    setSelectedKeywordIds([])
    Mixpanel.trackEvent('Clear search keywords', {
      case_id: caseId
    })
    httpClient.cases.update(caseId, {
      name: caseName,
      status: caseStatus,
      query: searchTerm,
      keyword_ids: [],
      result_disease_id: resultDiseaseId,
      selected_disease_ids: selectedDiseasesIds
    })
  }

  const handleSearchStateChange = ({ uiState, setUiState }) => {
    const st = uiState[secrets.diseaseIndex]?.query ?? ''
    const kw =
      uiState[secrets.diseaseIndex]?.refinementList?.['keywords.keyword_id'].map((it: string) => Number(it)) ?? []

    Mixpanel.trackEvent('Update search', {
      case_id: caseId,
      keyword_ids: kw,
      search_term: st
    })

    setUiState(uiState)
    setSearchTerm(st)

    httpClient.cases.update(caseId, {
      name: caseName,
      status: caseStatus,
      selected_disease_ids: selectedDiseasesIds,
      keyword_ids: kw,
      query: st,
      result_disease_id: resultDiseaseId
    })
  }

  const handleViewDisease = (disease: DiseaseResult) => {
    Mixpanel.trackEvent('View disease in search results', {
      case_id: caseId,
      disease_id: disease.disease_id
    })
    if (disease.disease_id !== selectedDiseaseId) {
      setDiseaseDetailId(disease.disease_id)
    } else {
      setIsDiseaseDetailModalVisible(true)
    }
  }

  const handleToggleDiseaseFromModal = (id: number) => {
    _toggleDiseaseSelection({
      diseaseId: id,
      mustRemove: false,
      isModal: true
    })
  }

  const handleToggleDisease = (disease: DiseaseResult, mustRemove = false) => {
    _toggleDiseaseSelection({
      diseaseId: disease.disease_id,
      mustRemove,
      isModal: false
    })
  }

  const _toggleDiseaseSelection = (data: { diseaseId: number; mustRemove?: boolean; isModal: boolean }) => {
    const ids = selectedDiseasesIds ? [...selectedDiseasesIds] : []
    const index = ids?.findIndex((id) => id === data.diseaseId)

    if (index > -1 || data.mustRemove) {
      Mixpanel.trackEvent(data.isModal ? 'Deselect disease from modal' : 'Deselect disease in search results', {
        case_id: caseId,
        disease_id: data.diseaseId
      })
      ids.splice(index, 1)
    } else {
      Mixpanel.trackEvent(data.isModal ? 'Select disease from modal' : 'Select disease in search results', {
        case_id: caseId,
        disease_id: data.diseaseId
      })
      ids.push(data.diseaseId)
    }

    caseUpdateMutation.mutate({
      caseId,
      body: {
        name: caseName,
        keyword_ids: selectedKeywordIds,
        query: searchTerm,
        result_disease_id: resultDiseaseId,
        selected_disease_ids: ids,
        status: caseStatus
      },
      setSelectedDiseases: (d: any) => {
        const _d = d.map((it) => ({
          disease_id: it.id,
          title: it.title,
          alternative_titles: it.alternative_titles,
          section: it.section,
          chapter: it.chapter,
          definition: it.definition,
          title_image: it.title_image
        }))
        setSelectedDiseases(_d)
      },
      onSuccessCallback: () => {
        message.success(index > -1 ? 'Disease removed from comparison' : 'Disease added to comparison')
      }
    })
    // fix for unselecting when there's only 1 disease selected (Linear PR-116):
    if (!ids || !ids.length) {
      setSelectedDiseases([])
    }
  }

  const handleDeselectAllDiseases = () => {
    Mixpanel.trackEvent('Deselect all diseases on Search', {
      case_id: caseId
    })

    const ids = []
    caseUpdateMutation.mutate({
      caseId,
      body: {
        name: caseName,
        keyword_ids: selectedKeywordIds,
        query: searchTerm,
        result_disease_id: resultDiseaseId,
        selected_disease_ids: ids,
        status: caseStatus
      },
      setSelectedDiseases: (d: any) => {
        const _d = d.map((it) => ({
          disease_id: it.id,
          title: it.title,
          alternative_titles: it.alternative_titles,
          section: it.section,
          chapter: it.chapter,
          definition: it.definition,
          title_image: it.title_image
        }))
        setSelectedDiseases(_d)
      }
    })
    setSelectedDiseases([])
  }

  const handleCompare = () => {
    if (selectedDiseasesIds?.length < 2) return

    Mixpanel.trackEvent('Click compare diseases', {
      case_id: caseId,
      disease_ids: selectedDiseasesIds
    })
    navigate(PathRiverRoutes.COMPARE.replace(PathVariables.caseId, caseId))
  }

  const handleDiseaseResolution = (disease: any) => {
    Mixpanel.trackEvent('Select disease as resolution', {
      case_id: caseId,
      disease_id: disease.disease_id || disease.id
    })

    caseUpdateMutation.mutate({
      caseId,
      body: {
        name: caseName,
        result_disease_id: disease.disease_id || disease.id,
        keyword_ids: selectedKeywordIds,
        query: searchTerm,
        selected_disease_ids: selectedDiseasesIds,
        status: CaseStatus.Resolved
      },
      setSelectedDiseases: (d: any) => {
        const _d = d.map((it) => ({
          disease_id: it.id,
          title: it.title,
          alternative_titles: it.alternative_titles,
          section: it.section,
          chapter: it.chapter,
          definition: it.definition,
          title_image: it.title_image
        }))
        setSelectedDiseases(_d)
      },
      onSuccessCallback: () => {
        Mixpanel.trackEvent('Case resolved', {
          case_id: caseId,
          disease_id: disease.disease_id
        })

        // TODO: we can check from the user DB records instead of doing it via LocalStorage
        const isFirstEverCase = false
        if (!isFirstEverCase) {
          message.success({
            content: 'Case resolved!',
            duration: 3
          })
          navigate(`${PathRiverRoutes.CASES}`)
        } else {
          info({
            title: 'First Case Resolved',
            icon: <CheckCircleOutlined />,
            content: 'You have resolved your first case with Pathriver!',
            okText: 'Dismiss',
            onOk: () => {
              navigate(`${PathRiverRoutes.CASES}`)
            }
          })
        }
      }
    })
  }

  const renderComparisonWidget = () => {
    return (
      <div
        id="comparison-widget"
        className={`comparison-widget ${selectedDiseasesIds?.length > 1 ? '--enabled' : ''} ${
          isComparisonWidgetActive ? '--active' : ''
        }`}>
        <div
          className="comparison-widget-heading"
          id="comparison-widget-heading"
          onClick={() => {
            const a = !isComparisonWidgetActive
            setIsComparisonWidgetActive(a)
          }}>
          <span className="comparison-widget-heading-count">{selectedDiseasesIds?.length || '0'}</span>
          <span className="comparison-widget-heading-title">Comparison List</span>
        </div>

        <div className="comparison-widget-content">
          {selectedDiseasesIds?.length ? (
            selectedDiseases.map((disease: DiseaseResult) => (
              <div
                id="comparison-widget-content-item"
                className="comparison-widget-content-item"
                key={disease.disease_id}>
                <div
                  id="comparison-widget-content-item-title"
                  className="comparison-widget-content-item-title"
                  onClick={() => {
                    handleViewDisease(disease)
                  }}>
                  {disease.title}
                </div>
                <div className="comparison-widget-content-item-remove">
                  <MinusOutlined onClick={() => handleToggleDisease(disease, true)} />
                </div>
              </div>
            ))
          ) : (
            <div className="comparison-widget-content-empty">
              No diseases selected for comparison.<br></br>Select at least two diseases.
            </div>
          )}
        </div>

        <div className="comparison-widget-footer">
          <div className="comparison-widget-footer-button --clear" onClick={handleDeselectAllDiseases}>
            Deselect all
          </div>
          <div
            id="comparison-widget-footer-button-compare"
            className={`comparison-widget-footer-button --compare ${
              selectedDiseasesIds?.length < 2 ? '--disabled' : ''
            }`}
            onClick={handleCompare}>
            Compare
          </div>
        </div>
      </div>
    )
  }

  const handleSearchFilters = debounce((value: string) => {
    // eslint-disable-next-line no-console
    const filtered = allKeywordsOriginal.filter((it) => it.name.toLowerCase().includes(value.toLowerCase()))
    setAllKeywords(filtered)

    Mixpanel.trackEvent('Search keyword filters', {
      case_id: caseId,
      search_term: value
    })
  }, 500)

  return (
    <>
      <div className="search-page" ref={ref}>
        {/* Modals */}
        <InstructionModal visible={isInstructionModalVisible} onCloseModal={hideInstructionModal} />
        {selectedDiseaseDetail && (
          <DiseaseDetailModal
            visible={isDiseaseDetailModalVisible}
            onCloseModal={() => setIsDiseaseDetailModalVisible(false)}
            onToggleDisease={handleToggleDiseaseFromModal}
            onDiseaseResolution={handleDiseaseResolution}
            disease={selectedDiseaseDetail}
            steps={stepsData}
            selectedDiseasesIds={selectedDiseasesIds}
          />
        )}

        <div className="search-page-header">
          <h1>
            <span id="search-page-header-case-name" className="search-page-header-case-name">
              {caseName}
            </span>{' '}
            Search
          </h1>
        </div>

        <div className="search-page-wrapper">
          {/* eslint-disable-next-line no-undef */}
          <InstantSearch
            searchClient={searchClient}
            indexName={secrets.diseaseIndex}
            onStateChange={handleSearchStateChange}
            initialUiState={{
              [secrets.diseaseIndex]: {
                query: initialSearchTerm,
                refinementList: {
                  'keywords.keyword_id': initialKeywordIds?.map((it) => it.toString())
                }
              }
            }}>
            <Configure filters="status:4" />

            <div className="search-sidebar">
              <div id="keyword-filter-wrapper" className="keyword-filter-wrapper">
                <div>
                  <h3>Filters</h3>
                </div>
                <div>
                  <input
                    id="search-filters-input"
                    className="search-filters-input"
                    type="text"
                    placeholder="Search filters"
                    onChange={
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      (e: any) => {
                        const { currentTarget } = e
                        const { value } = currentTarget
                        handleSearchFilters(value)
                      }
                    }
                  />
                </div>
                <KeywordFilter allKeywords={allKeywords} onToggleKeyword={handleToggleKeyword} />
              </div>

              <KeywordFilterClearer onClear={handleClearKeywords} />
            </div>

            <div id="disease-results" className="content">
              <div className="search-container">
                <h1>Search</h1>

                <div className="search-content">
                  <div id="search-input-wrapper" className="search-input-wrapper">
                    <SearchOutlined />

                    <SearchBox
                      classNames={{
                        root: 'search-box',
                        form: 'search-box-form',
                        input: 'search-box-input',
                        submit: 'search-box-submit',
                        reset: 'search-box-reset'
                      }}
                      // queryHook={searchBoxQueryHook}
                      searchAsYouType={false}
                      placeholder="Filter by disease name. Hit Enter to search."
                    />
                  </div>

                  <div id="result-count" className="result-count">
                    <Stats
                      translations={{
                        stats(nbHits) {
                          return `${nbHits.nbHits} diseases found`
                        }
                      }}
                    />
                  </div>
                </div>

                {/* {renderSelectedKeywords()} */}
              </div>

              <div className="results-container">
                <Results
                  handleToggleDisease={handleToggleDisease}
                  handleViewDisease={handleViewDisease}
                  selectedDiseasesIds={selectedDiseasesIds}
                />
              </div>

              <div className="comparison-widget-container">{renderComparisonWidget()}</div>
            </div>
          </InstantSearch>
        </div>
      </div>
    </>
  )
}
