import { useQuery } from 'react-query'
import { httpClient } from '../../service/http/HttpClient'
import { SubscriptionPlansClient } from '../../service/http/SubscriptionPlansClient'

export const useQueryGetSubscriptionPlans = () => {
  return useQuery(SubscriptionPlansClient.queryKeys.getAll(), async () => {
    const { data } = await httpClient.subscriptionPlans.getAll()

    return data
  })
}
