import { AxiosInstance } from 'axios'
import { LoginRequest } from '../../mutations/login/request'
import { SignupRequest } from '../../mutations/signup/request'

export class SessionClient {
  private client: AxiosInstance

  constructor(client: AxiosInstance) {
    this.client = client
  }

  login(request: LoginRequest) {
    return this.client.post('auth/login', request)
  }
  signup(request: SignupRequest) {
    return this.client.post('auth/signup', request)
  }
  activate(token: string) {
    return this.client.post('auth/activate', { token })
  }
}
