import { Outlet } from 'react-router-dom'
import { DirectoryHeader } from './components/DirectoryHeader'
import { DirectoryProvider } from './context/DirectoryContext'
import styles from './DirectoryPage.module.scss'

export const DirectoryPage = () => {
  return (
    <DirectoryProvider>
      <div className={styles['directory-page-container']}>
        <DirectoryHeader />
        <div className={styles['directory-page-content']}>
          <Outlet />
        </div>
      </div>
    </DirectoryProvider>
  )
}
