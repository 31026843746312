import { Pagination, Skeleton } from 'antd'
import { useMatch, useNavigate } from 'react-router-dom'
import { usePageVisit } from '../../../../hooks/usePageVisit'
import { PathParams, PathRiverRoutes } from '../../../../router/routes'
import { Mixpanel } from '../../../../util/tracking'
import { DiseaseItem } from '../../search/components/DiseaseItem'
import { DirectoryBreadcrumbs } from '../components/DirectoryBreadcrumbs'
import { useDirectory } from '../context/DirectoryContext'
import styles from './Chapter.module.scss'

export const Chapter = () => {
  const navigate = useNavigate()
  const match = useMatch(PathRiverRoutes.DIRECTORY_CHAPTER)

  const {
    isLoading,
    diseases,
    diseasesCurrentPage,
    currentSuperSection,
    currentSection,
    diseasesTotalPages,
    chapter,
    setChapterDiseasesPage
  } = useDirectory()

  usePageVisit(
    'Directory',
    !isLoading
      ? `Supersection: ${currentSuperSection.name}: Section: ${currentSection.name}: Chapter: ${chapter.name}`
      : undefined
  )

  if (isLoading)
    return (
      <div className={`${styles['chapter']} pt-16`}>
        <Skeleton />
      </div>
    )

  return (
    <div className={styles['chapter']}>
      <div className={styles['content-header']}>
        <DirectoryBreadcrumbs />
        {(diseases ?? []).length > 0 && (
          <div className={styles['pagination']}>
            <Pagination
              defaultCurrent={1}
              current={diseasesCurrentPage}
              total={diseasesTotalPages}
              showSizeChanger={false}
              defaultPageSize={30}
              onChange={(page) => {
                Mixpanel.trackEvent('Change page on directory chapter disease list', {
                  page
                })
                setChapterDiseasesPage(page)
              }}
            />
          </div>
        )}
      </div>
      <div className={styles['diseases-list']}>
        {(diseases ?? []).length > 0 ? (
          diseases?.map((it) => (
            <DiseaseItem
              key={it.id}
              disease={{
                disease_id: it.id,
                title: it.title,
                alternative_titles: it.alternative_titles?.map((title) => title.title) ?? [],
                section: it.section.name,
                chapter: it.chapter.name,
                definition: it.definition,
                title_image: it.title_image,
                user_name: it.user?.first_name + ' ' + it.user?.last_name,
                updated_at: it.updated_at
              }}
              onClick={() =>
                navigate(
                  `${PathRiverRoutes.DIRECTORY_DISEASE_DETAILS.replace(
                    PathParams.superSection,
                    match.params.superSection
                  )
                    .replace(PathParams.sectionId, match.params.sectionId)
                    .replace(PathParams.chapterId, match.params.chapterId)
                    .replace(PathParams.diseaseId, it.id.toString())}`
                )
              }
              selected={false}
            />
          ))
        ) : (
          <div className={styles['no-diseases']}>No diseases in this chapter yet.</div>
        )}
      </div>
    </div>
  )
}
