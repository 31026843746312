import { createRoot } from 'react-dom/client'

import { ReactQueryDevtools } from 'react-query/devtools'
import { QueryClient, QueryClientProvider } from 'react-query'
import duration from 'dayjs/plugin/duration'
import dayjs from 'dayjs'

import { Root } from './Root'
import { PathRiverColors, setAppColors } from './styles/colors'
import { AuthProvider } from './context/auth'
import { errorHandler } from './util/errorHandler'
import { Mixpanel, Sentry } from './util/tracking'

import './index.less'
import './index.scss'
import { ConfigProvider } from 'antd'

Sentry.init()
Mixpanel.init()

const root = createRoot(document.getElementById('root')) // createRoot(container!) if you use TypeScript

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler
    }
  }
})

setAppColors()
dayjs.extend(duration)

root.render(
  <ConfigProvider theme={{ token: { colorPrimary: PathRiverColors.purple600 } }}>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Root />
      </AuthProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </ConfigProvider>
)
