import { AxiosInstance, AxiosResponse } from 'axios'
import { SuperSection } from '../../types/section'

export class SuperSectionClient {
  static queryKeys = {
    superSections: ['Supersections']
  }

  private client: AxiosInstance

  constructor(client: AxiosInstance) {
    this.client = client
  }

  getAll(): Promise<AxiosResponse<SuperSection[]>> {
    return this.client.get(`/supersections`)
  }
}
