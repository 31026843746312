import axios, { AxiosInstance } from 'axios'
import { NavigateFunction } from 'react-router-dom'
import decodeJwt from 'jwt-decode'
import { PathRiverRoutes } from '../../router/routes'
import { secrets } from '../../environment'
import { LocalStorage } from '../LocalStorage'
import { DiseaseClient } from './DiseaseClient'
import { SectionClient } from './SectionClient'
import { SuperSectionClient } from './SuperSectionClient'
import { UserClient } from './UserClient'
import { SessionClient } from './SessionClient'
import { CaseClient } from './CaseClient'
import { ResetPasswordClient } from './ResetPasswordClient'
import { StepsClient } from './StepsClient'
import { tokenRefreshClient } from './TokenRefreshClient'
import { ChapterClient } from './ChapterClient'
import { JwtClaims } from '../../types/session'
import { DateTime } from '../../util/dateTime'
import { PathRiverErrorResponse } from '../../types/error'
import { getErrorCode } from '../../util/errorHandler'
import { ProfileClient } from './ProfileClient'
import { SubscriptionPlansClient } from './SubscriptionPlansClient'

class HttpClient {
  private readonly client: AxiosInstance

  public readonly users: UserClient
  public readonly session: SessionClient
  public readonly cases: CaseClient // used plural since case is a reserved name
  public readonly steps: StepsClient
  public readonly disease: DiseaseClient
  public readonly section: SectionClient
  public readonly superSection: SuperSectionClient
  public readonly chapter: ChapterClient
  public readonly resetPassword: ResetPasswordClient
  public readonly profile: ProfileClient
  public readonly subscriptionPlans: SubscriptionPlansClient

  private contextLogout?: (logoutCallback: () => void) => void
  private routerNavigate?: NavigateFunction

  constructor() {
    this.client = axios.create({ baseURL: secrets.baseUrl })

    this.client.interceptors.request.use((config) => {
      const accessToken = LocalStorage.getAccessToken()
      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`
      }
      return config
    })

    this.client.interceptors.response.use(
      (response) => response,
      async (error: PathRiverErrorResponse) => {
        const accessToken = LocalStorage.getAccessToken()
        if (accessToken && getErrorCode(error) === 401) {
          const decodedToken = decodeJwt<JwtClaims>(accessToken)
          const expDate = new DateTime(decodedToken.exp * 1000)
          const now = Date.now()

          if (expDate.isBefore(now)) {
            const refreshToken = LocalStorage.getRefreshToken()
            const newToken = await tokenRefreshClient.refreshToken(accessToken, refreshToken)

            LocalStorage.setAccessToken(newToken)

            return this.client.request(error.config ?? {})
          }
        }

        throw error
      }
    )

    this.users = new UserClient(this.client)
    this.session = new SessionClient(this.client)
    this.cases = new CaseClient(this.client)
    this.steps = new StepsClient(this.client)
    this.disease = new DiseaseClient(this.client)
    this.section = new SectionClient(this.client)
    this.superSection = new SuperSectionClient(this.client)
    this.chapter = new ChapterClient(this.client)
    this.resetPassword = new ResetPasswordClient(this.client)
    this.profile = new ProfileClient(this.client)
    this.subscriptionPlans = new SubscriptionPlansClient(this.client)
  }

  logout() {
    this.contextLogout(() => this.routerNavigate(PathRiverRoutes.LOGIN))
  }

  setContextLogout(contextLogout: (logoutCallback: () => void) => void) {
    this.contextLogout = contextLogout
  }

  setRouterNavigateFunction(routerNavigate?: NavigateFunction) {
    this.routerNavigate = routerNavigate
  }
}

export const httpClient = new HttpClient()
