import { useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { matchPath, useLocation } from 'react-router-dom'
import { PathRiverRoutes } from '../../../../router/routes'
import { DiseaseClient } from '../../../../service/http/DiseaseClient'
import { DiseaseDetailsResponse } from '../../../../types/disease'
import { isValue } from '../../../../types/typeGuard'
import { useDirectory } from '../context/DirectoryContext'

const breadCrumbRoutes = [
  PathRiverRoutes.DIRECTORY,
  PathRiverRoutes.DIRECTORY_SUPERSECTION,
  PathRiverRoutes.DIRECTORY_SUPERSECTION_SECTION,
  PathRiverRoutes.DIRECTORY_CHAPTER,
  PathRiverRoutes.DIRECTORY_DISEASE_DETAILS
]

export const useBreadcrumbs = () => {
  const queryClient = useQueryClient()
  const location = useLocation()
  const matchRoutes = breadCrumbRoutes
    .map((it) => matchPath({ path: it, end: false }, location.pathname))
    .filter((it) => isValue(it))
  const { params, chapter, currentSection, currentSuperSection } = useDirectory()
  const disease = queryClient.getQueryData<DiseaseDetailsResponse>(
    DiseaseClient.queryKeys.getDisease(isValue(params.diseaseId) ? Number(params.diseaseId) : -1)
  )

  const breadCrumbs = useMemo(() => {
    return matchRoutes.reduce((acc, curr) => {
      if (curr.pattern.path === PathRiverRoutes.DIRECTORY) {
        acc = acc.concat({
          pathname: curr.pathname,
          label: 'Directory'
        })
      } else if (curr.pattern.path === PathRiverRoutes.DIRECTORY_SUPERSECTION && isValue(currentSuperSection)) {
        acc = acc.concat({
          pathname: curr.pathname,
          label: currentSuperSection.name
        })
      } else if (
        curr.pattern.path === PathRiverRoutes.DIRECTORY_SUPERSECTION_SECTION &&
        location.pathname !== PathRiverRoutes.DIRECTORY_SUPERSECTION &&
        isValue(currentSection)
      ) {
        acc = acc.concat({
          pathname: curr.pathname,
          label: currentSection.name
        })
      } else if (curr.pattern.path === PathRiverRoutes.DIRECTORY_CHAPTER && isValue(chapter)) {
        acc = acc.concat({
          pathname: curr.pathname,
          label: chapter.name
        })
      } else if (curr.pattern.path === PathRiverRoutes.DIRECTORY_DISEASE_DETAILS && isValue(disease)) {
        acc = acc.concat({
          pathname: curr.pathname,
          label: disease.title
        })
      }

      return acc
    }, [])
  }, [chapter, currentSection, currentSuperSection, disease, location.pathname, matchRoutes])

  return breadCrumbs
}
