import { Boarding } from 'boarding.js'
import { BoardingSteps } from 'boarding.js/dist/boarding-types'
import { Mixpanel } from './tracking'
import { simulateMouseClick } from './helper'

export const getHomePageSteps = (markTourSeen: () => void): BoardingSteps => {
  return [
    {
      element: '#nav-logo',
      popover: {
        title: 'Welcome to Pathriver',
        description: `Let's take a quick tour to discover how Pathriver helps you elevate your pathology workflow.`,
        prefferedSide: 'bottom',
        alignment: 'center'
      },
      onNext: () => {
        Mixpanel.trackEvent('Onboarding - Home - Logo - Next button clicked')
      }
    },
    {
      element: '#my-cases-card-page',
      popover: {
        title: 'Search and Compare',
        description: 'Solve diagnostic cases with powerful search, filtering and side-by-side comparison.',
        prefferedSide: 'top',
        alignment: 'center'
      },
      onNext: () => {
        Mixpanel.trackEvent('Onboarding - Home - Search and Compare card - Next button clicked')
      }
    },
    {
      element: '#directory-card-page',
      popover: {
        title: 'Directory',
        description:
          'When not working on a case, you can browse the directory to learn about diseases organized in sections and chapters.',
        prefferedSide: 'top',
        alignment: 'center'
      },
      onNext: () => {
        Mixpanel.trackEvent('Onboarding - Home - Directory card - Next button clicked')
      }
    },
    {
      element: '#community-card-page',
      popover: {
        title: 'Community',
        description:
          '<span style="border: 1px solid #7f56d9; padding: 4px 6px; border-radius: 6px; color: #7f56d9; margin-bottom: 4px; font-weight: 700; font-size: 10px; transform: translateY(0px); position: absolute; top: 17px; left: 125px;">Coming soon</span> <p>Connect with other pathologists, share your knowledge and collaborate.</p>',
        prefferedSide: 'top'
      },
      onNext: () => {
        Mixpanel.trackEvent('Onboarding - Home - Community card - Next button clicked')
      }
    },
    {
      element: '#app-menu',
      popover: {
        title: 'Main navigation',
        description: 'Use this menu to navigate through the app.',
        prefferedSide: 'bottom',
        alignment: 'center'
      },
      onNext: () => {
        Mixpanel.trackEvent('Onboarding - Home - Main navigation - Next button clicked')
      }
    },
    {
      element: '#user-menu-wrapper',
      popover: {
        title: 'Your Account',
        description: 'Here you can manage your account, share feedback and contact support.',
        prefferedSide: 'left',
        alignment: 'center',
        doneBtnText: 'Got it!'
      },
      onNext: () => {
        Mixpanel.trackEvent('Onboarding - Home - Account - Next button clicked - Done')
        markTourSeen()
      }
    }
  ]
}

export const getSearchPageSteps = (boarding: Boarding, markTourSeen: () => void): BoardingSteps => {
  return [
    {
      element: '.search-page-header h1',
      popover: {
        title: 'Search page',
        description: `<p>This page allows you to solve diagnostic with powerful search, filtering and side-by-side comparison.</p>
        <p>The name of the case you are working on is displayed here.</p>`,
        prefferedSide: 'right'
      },
      onNext: () => {
        Mixpanel.trackEvent('Onboarding - Search - Case name - Next button clicked')
      }
    },
    {
      element: '#keyword-filter-wrapper',
      popover: {
        title: 'Keyword filter',
        description:
          '<p>Select one or more keywords to filter diseases.</p><p>Number of diseases with that keyword is displayed next to it.</p>',
        prefferedSide: 'right'
      },
      onNext: () => {
        Mixpanel.trackEvent('Onboarding - Search - Keyword filter - Next button clicked')
      }
    },
    {
      element: '#search-filters-input',
      popover: {
        title: 'Search for keywords',
        description: 'You can search for keywords by typing in this search bar.',
        prefferedSide: 'right'
      },
      onNext: () => {
        Mixpanel.trackEvent('Onboarding - Search - Keyword filter search - Next button clicked')
      }
    },
    {
      element: '#disease-results',
      popover: {
        title: 'Results',
        description:
          '<p>Here you can see list of diseases that match your search criteria.</p> <p>They are ordered by relevance.</p>',
        prefferedSide: 'top'
      },
      onNext: () => {
        Mixpanel.trackEvent('Onboarding - Search - Disease results - Next button clicked')
      }
    },
    {
      element: '#search-input-wrapper',
      popover: {
        title: 'Search by name',
        description: 'You can search diseases by name using this search bar.',
        prefferedSide: 'bottom'
      },
      onNext: () => {
        Mixpanel.trackEvent('Onboarding - Search - Search input - Next button clicked')
      }
    },
    {
      element: '#result-count',
      popover: {
        title: 'Result count',
        description: 'The number of diseases that match your search criteria is displayed here.',
        prefferedSide: 'left'
      },
      onNext: () => {
        Mixpanel.trackEvent('Onboarding - Search - Result count - Next button clicked')
      }
    },
    {
      element: '#disease-item-content-wrapper',
      popover: {
        title: 'Disease result',
        description:
          '<p>You can see the general information about each disease</p><p>Clicking on it displays more details.</p>',
        prefferedSide: 'top',
        alignment: 'center'
      },
      onNext: () => {
        Mixpanel.trackEvent('Onboarding - Search - Disease first item - Next button clicked')
        clickOnElementById({ boarding, id: 'disease-item-content-wrapper' })
        // TODO event when disease is loaded from backend should continue boarding
      }
    },
    {
      element: '#disease-detail-modal-header',
      popover: {
        title: 'Disease detail',
        description: 'Here you can mark this disease as resolution for your case.',
        prefferedSide: 'bottom',
        alignment: 'center'
      },
      onNext: () => {
        Mixpanel.trackEvent('Onboarding - Search - Disease detail - Next button clicked')
      },
      onPrevious: () => {
        Mixpanel.trackEvent('Onboarding - Search - Disease results - Previous button clicked')
        clickOnElementById({ boarding, id: 'disease-detail-modal-header-close-btn' })
      }
    },
    {
      element: '#disease-detail-modal-header-toggle span',
      popover: {
        title: 'Add & Remove from comparison shortlist',
        description: 'Here you can add or remove disease from comparison shortlist.',
        prefferedSide: 'right',
        alignment: 'center'
      },
      onNext: () => {
        Mixpanel.trackEvent('Onboarding - Search - Disease action - Next button clicked')
      }
    },
    {
      element: '#top-image-container',
      popover: {
        title: 'Disease images',
        description: 'Here you see all images related to the disease.',
        prefferedSide: 'bottom',
        alignment: 'center'
      },
      onNext: () => {
        Mixpanel.trackEvent('Onboarding - Search - Disease detail images - Next button clicked')
      }
    },
    {
      element: '.ant-collapse',
      popover: {
        title: 'Disease information',
        description: 'Here you see detail disease information broken down into sections.',
        prefferedSide: 'top',
        alignment: 'center'
      },
      onNext: () => {
        Mixpanel.trackEvent('Onboarding - Search - Disease detail steps - Next button clicked')
        clickOnElementById({
          boarding,
          id: 'ant-collapse-header-text',
          isClassSelector: true,
          timeoutDuration: 10
        })
      }
    },
    {
      element: '.ant-collapse-item',
      popover: {
        title: 'General information',
        description: 'General information about the disease in the first tab.',
        prefferedSide: 'top',
        alignment: 'center'
      },
      onNext: () => {
        Mixpanel.trackEvent('Onboarding - Search - Disease detail step general - Next button clicked')
        clickOnElementById({ boarding, id: 'disease-detail-modal-header-close-btn' })
      }
    },
    {
      element: '#disease-results-item:first-child #disease-item-actions:first-child span',
      popover: {
        title: 'Add & Remove from comparison shortlist',
        description: 'You can also add or remove disease from comparison shortlist here.',
        prefferedSide: 'right',
        alignment: 'center'
      },
      onNext: () => {
        Mixpanel.trackEvent('Onboarding - Search - Disease item actions - Next button clicked')
      },
      onPrevious: () => {
        Mixpanel.trackEvent('Onboarding - Search - Disease item actions - Previous button clicked')
        clickOnElementById({ boarding, id: 'comparison-widget-content-item-title' })
      }
    },
    {
      element: '#disease-item-action-info',
      popover: {
        title: 'Info',
        description: 'Hover for more information such as last updated date and editors.',
        prefferedSide: 'right',
        alignment: 'center'
      },
      onNext: () => {
        Mixpanel.trackEvent('Onboarding - Search - Disease item action info - Next button clicked')
      }
    },
    {
      element: '#comparison-widget',
      popover: {
        title: 'Comparison shortlist',
        description: 'Click here to see diseases added for comparison.',
        prefferedSide: 'top',
        alignment: 'center'
      },
      onNext: () => {
        Mixpanel.trackEvent('Onboarding - Search - Disease comparison widget - Next button clicked')
        clickOnElementById({ boarding, id: 'comparison-widget-heading' })
      }
    },
    {
      element: '#comparison-widget-content-item',
      popover: {
        title: 'Disease',
        description:
          'If you want to remove disease from comparison, you can do it by clicking on minus icon on the right side of the disease name.',
        prefferedSide: 'left',
        alignment: 'center'
      },
      onNext: () => {
        Mixpanel.trackEvent('Onboarding - Search - Disease comparison widget item - Next button clicked')
      }
    },
    {
      element: '#comparison-widget-footer-button-compare',
      popover: {
        title: 'Comparison mode',
        description:
          '<p>Click here to enter comparison mode where you can see all selected diseases side by side.</p><p>You can do this if you select at least 2 diseases.</p>',
        prefferedSide: 'left',
        doneBtnText: 'Got it!'
      },
      onNext: () => {
        Mixpanel.trackEvent('Onboarding - Search - Done button clicked')
        markTourSeen()
        setTimeout(() => {
          clickOnElementById({ boarding, id: 'comparison-widget-footer-button-compare' })
        }, 500)
      }
    }
  ]
}

export const getComparePageSteps = (boarding: Boarding, markTourSeen: () => void): BoardingSteps => {
  return [
    {
      element: '.compare-page_header h1',
      popover: {
        title: 'Case name',
        description: 'The name of the case you are working on is displayed here.',
        prefferedSide: 'right'
      },
      onNext: () => {
        Mixpanel.trackEvent('Onboarding - Comparison - Case name - Next button clicked')
      }
    },
    {
      element: '#back-btn',
      popover: {
        title: 'Back to Search page',
        description: 'Click here to go back to the Search page.',
        prefferedSide: 'left',
        alignment: 'center'
      },
      onNext: () => {
        Mixpanel.trackEvent('Onboarding - Comparison - Back to Search - Next button clicked')
      }
    },
    {
      element: '#comparison-card__container',
      popover: {
        title: 'Disease details',
        description: 'Here you can see all the information about each disease you are comparing.',
        prefferedSide: 'right',
        alignment: 'center'
      },
      onNext: () => {
        Mixpanel.trackEvent('Onboarding - Comparison - Disease details - Next button clicked')
      }
    },
    {
      element: '#keen-slider-container',
      popover: {
        title: 'Disease images',
        description: 'Here you can see all images for this disease.',
        prefferedSide: 'bottom',
        alignment: 'center'
      },
      onNext: () => {
        Mixpanel.trackEvent('Onboarding - Comparison - Disease images - Next button clicked')
        clickOnElementById({ boarding, id: 'ant-collapse-header-text', isClassSelector: true, timeoutDuration: 10 })
      }
    },
    {
      element: '.ant-collapse-item',
      popover: {
        title: 'General information',
        description: 'Here you can see general information about this disease.',
        prefferedSide: 'bottom',
        alignment: 'center'
      },
      onNext: () => {
        Mixpanel.trackEvent('Onboarding - Comparison - General information - Next button clicked')
        clickOnElementById({ boarding, id: 'ant-collapse-header-text', isClassSelector: true, timeoutDuration: 10 })
      }
    },
    {
      element: '.ant-collapse-extra span',
      popover: {
        title: 'More information',
        description: 'Switch active tabs to see more information about this disease.',
        prefferedSide: 'top',
        alignment: 'center'
      },
      onNext: () => {
        Mixpanel.trackEvent('Onboarding - Comparison - More icon - Next button clicked')
      }
    },
    {
      element: '#comparison-top-right span:first-child',
      popover: {
        title: 'Mark as resolution',
        description: 'If you want to mark your case as resolved with this disease, click here.',
        prefferedSide: 'left',
        alignment: 'center'
      },
      onNext: () => {
        Mixpanel.trackEvent('Onboarding - Comparison - Mark as resolution - Next button clicked')
      }
    },
    {
      element: '#comparison-top-right span:last-child',
      popover: {
        title: 'Remove from comparison',
        description: 'If you want to remove a disease from comparison, click here.',
        prefferedSide: 'left',
        alignment: 'center',
        doneBtnText: 'Got it!'
      },
      onNext: () => {
        Mixpanel.trackEvent('Onboarding - Comparison - Remove from comparison - Next button clicked')
        markTourSeen()
      }
    }
  ]
}

export const clickOnElementById = (params: {
  boarding: Boarding
  id: string
  isClassSelector?: boolean
  timeoutDuration?: number
  ignoreClickTimeout?: boolean
}) => {
  let element = document.getElementById(params.id)
  if (params.isClassSelector) {
    element = document.getElementsByClassName(params.id)[0] as HTMLElement
  }
  if (element) {
    params.boarding.preventMove()
    if (!params.ignoreClickTimeout) {
      element.addEventListener('click', () => {
        setTimeout(() => {
          params.boarding.continue()
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          element.removeEventListener('click', () => {})
        }, params.timeoutDuration || 500)
      })
    }
    simulateMouseClick(element)
  }
}
