export enum PathRiverColors {
  baseBlack = '#000',
  baseWhite = '#FFF',

  backgroundWhite = '#f5f5f5',
  backgroundWhite2 = '#f6f6f6',
  backgroundInput = '#f6f6f6',

  purple200 = '#F9F5FF',
  purple300 = '#dbd2ec',
  purple400 = '#9b67f4',
  purple600 = '#7F56D9',
  purple800 = '#4d1ca1',

  orange600 = '#E2C044',

  red600 = '#DF2935',

  gray200 = '#E4E7EC',
  gray300 = '#D0D5DD',
  gray400 = '#9c9c9c',
  gray500 = '#667085',
  gray700 = '#344054',
  gray900 = '#101828'
}

export const setAppColors = () => {
  for (const [key, value] of Object.entries(PathRiverColors)) {
    document.documentElement.style.setProperty(`--${key}`, value)
  }
}
