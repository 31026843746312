import { Modal, Button } from 'antd'
import { LocalStorage } from '../../../../../service/LocalStorage'

import './style.scss'

type Props = {
  visible: boolean
  onCloseModal: () => void
}

export const InstructionModal = ({ visible, onCloseModal }: Props) => {
  const onClose = () => {
    LocalStorage.setInstructionModalStatus()
    onCloseModal()
  }

  return (
    <Modal
      open={visible}
      destroyOnClose
      className="search-instruction-modal"
      width={600}
      onCancel={onClose}
      footer={[
        <Button key="1" onClick={onClose} type="primary" size="large">
          Dismiss
        </Button>
      ]}>
      <h1>Solve your first case</h1>
      <p>Start working on your case using the keyword filters on the left.</p>
      <p>You can also use the free text search to find diseases.</p>
      <p>After narrowing down the search results click 2 or more diseases and compare them.</p>
      <p>Once you found the diagnosis you can mark it as the resolution for this case.</p>
    </Modal>
  )
}
