import { useState } from 'react'
import { Spin, Alert } from 'antd'

import { CasesDataTable } from './components/CasesDataTable'
import { CreateCaseModal } from './components/CreateCaseModal'
import { EmptyStateCard } from './components/EmptyStateCard'
import { useQueryGetCases } from '../../../queries/cases/useQueryGetCases'
import { DEFAULT_PAGE_SIZE, PaginationData } from '../../../queries/types'
import { Mixpanel } from '../../../util/tracking'
import { useCasesSearchHandler } from '../../../util/url'
import './style.scss'

export const CasesPage = () => {
  Mixpanel.trackEvent('Visit Cases page')
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false)

  const { params, isFilteringData } = useCasesSearchHandler()

  const handleClickCreate = () => {
    Mixpanel.trackEvent('Click create new comparison')
    setIsCreateModalVisible(true)
  }
  const handleCloseCreateModal = () => {
    Mixpanel.trackEvent('Close create new comparison modal')
    setIsCreateModalVisible(false)
  }

  const { isLoading, data } = useQueryGetCases()

  if (isLoading) return <Spin className="cases-page-spinner" />

  const { cases, pagination } = data || { cases: [], pagination: {} }
  const totalItems = pagination[PaginationData.TOTAL_ITEMS]
  const currentPage = pagination[PaginationData.PAGE]
  const pageSize = params.limit ?? DEFAULT_PAGE_SIZE

  const mobileMsg = `Pathriver is not yet optimized for usage on mobile and tablet devices. 
  We are working on mobile version, and until then, please use desktop for best experience.`

  return (
    <div className="cases-page">
      <div className="cases-page-content">
        {totalItems > 0 || cases?.length > 0 || isFilteringData ? (
          <>
            <CasesDataTable
              cases={cases}
              totalItems={totalItems}
              currentPage={currentPage}
              pageSize={Number(pageSize)}
              handleOnCreateCaseButtonClick={handleClickCreate}
            />
          </>
        ) : (
          <div>
            <Alert
              message="Pathriver works best on desktop"
              description={mobileMsg}
              type="info"
              showIcon
              className="mobile-support-notice"
            />
            <EmptyStateCard onCreate={handleClickCreate} />
          </div>
        )}
      </div>
      <CreateCaseModal visible={isCreateModalVisible} onCloseModal={handleCloseCreateModal} />
    </div>
  )
}
