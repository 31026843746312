import { Alert, Collapse, Skeleton } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { usePageVisit } from '../../../../hooks/usePageVisit'
import { isValue } from '../../../../types/typeGuard'
import { DirectoryBreadcrumbs } from '../components/DirectoryBreadcrumbs'
import { useDirectory } from '../context/DirectoryContext'
import { SectionChapters } from './components/SectionChapters'
import styles from './SuperSection.module.scss'

export const SuperSection = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { isFetching, currentSuperSection, params } = useDirectory()
  usePageVisit('Directory', !isFetching ? `Supersection: ${currentSuperSection.name}` : undefined)

  if (isFetching) {
    return (
      <div className={`${styles['supersection']} pt-16`}>
        <Skeleton />
      </div>
    )
  }

  return (
    <div className={styles['supersection']}>
      <DirectoryBreadcrumbs />
      <div>
        {currentSuperSection.sections.length > 0 ? (
          <Collapse
            expandIconPosition="end"
            defaultActiveKey={params.sectionId}
            onChange={() => {
              if (isValue(params.sectionId)) {
                navigate(location.pathname.substring(0, location.pathname.lastIndexOf('/')))
              }
            }}>
            {currentSuperSection.sections.map((section) => (
              <Collapse.Panel className={styles['section-panel']} header={section.name} key={section.id}>
                <SectionChapters section={section} superSectionSlug={params.superSection} />
              </Collapse.Panel>
            ))}
          </Collapse>
        ) : (
          <Alert message="Selected super section has no sections attached to it" type="info" />
        )}
      </div>
    </div>
  )
}
