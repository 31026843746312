import { Disease } from '../../../../types/disease'
import { Card, Collapse, Tooltip, Popconfirm } from 'antd'
import { CloseCircleOutlined, CheckCircleOutlined, CaretRightOutlined } from '@ant-design/icons'

import { Step, Tab } from '../../../../types/step'
import { Image } from '../../../../types/image'
import { isValue } from '../../../../types/typeGuard'
import { ComparisonItemTabMenu } from './components/ComparisonItemTabMenu'

import { ComparisonItemTabContent } from './components/ComparisonItemTabContent'
import { useMemo } from 'react'
import { ImageSlider } from '../../../../components/image-slider/ImageSlider'

type Props = {
  disease: Disease
  steps: Step[]
  activeTabKey: string | string[]
  selectedDiseasesIds: number[]
  onToggleDiffDisease: (id: number) => void
  onToggleTabs: (key: string | string[]) => void
  onReplaceTab: (stepIndex: number) => (params: { step: Step; tabId: number; isImage?: boolean }) => void
  onDiseaseRemove: (disease: Disease) => void | undefined
  onDiseaseResolution: (disease: Disease) => void
}

const { Panel } = Collapse

export const ComparisonItem = ({
  disease,
  steps,
  activeTabKey,
  onToggleTabs,
  onReplaceTab,
  onDiseaseRemove,
  onDiseaseResolution,
  selectedDiseasesIds,
  onToggleDiffDisease
}: Props) => {
  const allImages: Image[] = useMemo(() => {
    return (
      disease.disease_info?.reduce((acc, curr) => {
        if (isValue(curr.images) && curr.images.length > 0) {
          acc = acc.concat(curr.images)
        }
        return acc
      }, []) ?? []
    )
  }, [disease.disease_info])

  const getActiveTabHeader = (step: Step, isActive: boolean): string => {
    const selected = step.tabs.find((tab: Tab) => tab.id === step.selectedTabId)

    let stepName = step.name
    if (step.id === 6) {
      stepName = 'Differential diagnoses'
    }
    let name = step.selectedTabId !== step.tabs[0].id ? stepName + '-' : ''
    name += selected?.name

    if (step.id === 5) {
      // for IHC we want to show "Additional methods" if tab is not active
      // for IHC we want to show "Additional methods-<selected>" if tab is active
      name = !isActive && step.selectedTabId === 25 ? 'Additional methods' : 'Additional methods-' + selected?.name
    }

    if (step.id === 6 && step.selectedTabId === 28) {
      name = 'Differential diagnoses'
    }
    // check if image is selected
    name += step.isImage ? ':Images' : ''

    return name
  }

  const panelStyle = {
    marginBottom: 0,
    background: 'white',
    border: 'none'
  }

  const renderImages = () => {
    // sort images by tab_id step_id=4 => microscopic step id
    const microscopicStep = steps?.find((step: Step) => step.id === 4)

    const microscopicImages = allImages?.sort((a, b) => {
      const aIndex = microscopicStep?.tabs.findIndex((t: Tab) => t.id === a.id)
      const bIndex = microscopicStep?.tabs.findIndex((t: Tab) => t.id === b.id)
      if (aIndex === -1) {
        return 1
      } else if (bIndex === -1) {
        return -1
      } else {
        return aIndex - bIndex
      }
    })

    return (
      <>
        {microscopicImages?.length > 0 && <ImageSlider images={microscopicImages} mode="full" />}
        {microscopicImages?.length === 0 && (
          <div className="comparison-card__container-no-image">
            <p>No images</p>
          </div>
        )}
      </>
    )
  }

  return (
    <div>
      <div className="comparison-top-right" id="comparison-top-right">
        <Popconfirm
          placement="bottom"
          title="Confirm Your Selection"
          description="Are you sure you want to select this disease as case resolution?"
          onConfirm={() => onDiseaseResolution(disease)}
          okText="Yes"
          cancelText="No"
          icon={<CheckCircleOutlined />}>
          <Tooltip placement="top" title="Mark disease as resolution">
            <CheckCircleOutlined style={{ fontSize: 22, marginRight: 6 }} />
          </Tooltip>
        </Popconfirm>
        <Popconfirm
          placement="bottom"
          title="Confirm Your Selection"
          description="Are you sure you want to remove this disease from comparison list?"
          onConfirm={() => {
            if (isValue(onDiseaseRemove)) onDiseaseRemove(disease)
          }}
          disabled={!isValue(onDiseaseRemove)}
          okText="Yes"
          cancelText="No"
          icon={<CheckCircleOutlined />}>
          <Tooltip
            placement="top"
            title={
              isValue(onDiseaseRemove)
                ? 'Remove Disease from Comparison'
                : 'Can not remove disease as minimum number for comparison is reached'
            }>
            <CloseCircleOutlined className={!isValue(onDiseaseRemove) ? 'disabled' : ''} style={{ fontSize: 22 }} />
          </Tooltip>
        </Popconfirm>
      </div>
      <Card className="comparison-card__container" id="comparison-card__container">
        {renderImages()}
        {disease?.section?.name && disease?.chapter?.name && (
          <text className="comparison-disease_section_chapter">
            {disease.section.name} &#8226; {disease.chapter.name}
          </text>
        )}
        <text className="comparison-disease__title">
          <Tooltip placement="top" title={disease.title}>
            {disease.title}
          </Tooltip>
        </text>
        <Collapse collapsible="header" ghost onChange={onToggleTabs} activeKey={activeTabKey} expandIcon={null}>
          {steps.map((step: Step, stepIndex: number) => {
            const isOpenHeader = activeTabKey.includes((stepIndex + 1).toString())
            return (
              <Panel
                header={
                  <>
                    <CaretRightOutlined rotate={isOpenHeader ? 90 : 0} />
                    {step.id === 8 && 'References and Resources'}
                    {step.id === 7 && 'Treatment and Prognosis'}
                    {step.id !== 7 && step.id !== 8 && getActiveTabHeader(step, isOpenHeader)}
                  </>
                }
                key={step.id}
                extra={
                  step.id !== 7 && (
                    <ComparisonItemTabMenu
                      diseaseInfo={disease.disease_info}
                      step={step}
                      onReplaceTab={onReplaceTab(stepIndex + 1)}
                    />
                  )
                }
                showArrow={false}
                style={panelStyle}>
                <ComparisonItemTabContent
                  step={step}
                  disease={disease}
                  selectedDiseasesIds={selectedDiseasesIds}
                  onToggleDiffDisease={onToggleDiffDisease}
                />
              </Panel>
            )
          })}
        </Collapse>
      </Card>
    </div>
  )
}
