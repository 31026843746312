import { Keyword } from '../types/keyword'
import { Step } from '../types/step'
import { KeywordGroup } from '../types/keyword_group'

export const getUniqueKeywords = (data: Step[]) => {
  const uniqueKeywords = new Set<Keyword>()
  const uniqueKeywordIds = new Set<number>()

  data
    ?.filter((step: Step) => step.keyword_groups?.length > 0)
    ?.forEach((step: Step) => {
      step.keyword_groups
        ?.filter((group: KeywordGroup) => group.keywords?.length > 0)
        ?.forEach((keywordGroup: KeywordGroup) => {
          keywordGroup.keywords.forEach((keyword: Keyword) => {
            if (!uniqueKeywordIds.has(keyword.id)) {
              uniqueKeywordIds.add(keyword.id)
              uniqueKeywords.add(keyword)
            }
          })
        })
    })
  return Array.from(uniqueKeywords)
}
