import { message } from 'antd'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'

import { httpClient } from '../../service/http/HttpClient'
import { SignupRequest } from './request'
import { PathRiverRoutes } from '../../router/routes'

export const useMutationSignup = () => {
  const navigate = useNavigate()

  return useMutation(
    async (request: SignupRequest) => {
      return httpClient.session.signup(request)
    },
    {
      onSuccess() {
        navigate(PathRiverRoutes.LOGIN, { replace: true })
      },
      onError(error: AxiosError) {
        console.error(error)
        message.error({
          content: 'Sign up failed. Please try again.',
          duration: 3
        })
      }
    }
  )
}
