import React from 'react'

import { Mixpanel } from '../../util/tracking'
import { httpClient } from '../../service/http/HttpClient'

export const LogoutPage = () => {
  Mixpanel.trackEvent('Visit Logout page')

  httpClient.logout()

  return <div key="logout-key"></div>
}
