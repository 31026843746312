import { Tag } from 'antd'
import { ClockCircleFilled, CheckCircleFilled, ContainerOutlined } from '@ant-design/icons'

import { CaseStatus, CaseStatusLabel } from '../../types/case'

type Props = {
  status: CaseStatus
}

export const CaseStatusTag = ({ status }: Props) => {
  let Icon = ClockCircleFilled
  let color = 'processing'
  if (status === 20) {
    Icon = ContainerOutlined
    color = 'orange'
  } else if (status === 30) {
    Icon = CheckCircleFilled
    color = 'success'
  }

  return (
    <Tag icon={<Icon />} color={color}>
      {CaseStatusLabel[status]}
    </Tag>
  )
}
