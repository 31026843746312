import dayjs, { Dayjs } from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import duration from 'dayjs/plugin/duration'

dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
dayjs.extend(duration)

export enum DateTimeFormat {
  LT = 'LT', // h:mm A
  LTS = 'LTS', // h:mm:ss A
  L = 'L', // MM/DD/YYYY
  LL = 'LL', // MMMM D, YYYY
  LLL = 'LLL', // MMMM D, YYYY h:mm A
  LLLL = 'LLLL', // dddd, MMMM D, YYYY h:mm A
  l = 'l', // M/D/YYYY
  ll = 'll', // MMM D, YYYY
  lll = 'lll', // MMM D, YYYY h:mm A
  llll = 'llll' // ddd, MMM D, YYYY h:mm A
}

export class DateTime {
  _date: Dayjs
  constructor(date: string | number) {
    this._date = dayjs(date)
  }

  static fromNow(date: Date) {
    return dayjs(date).fromNow()
  }

  static durationInDaysFromNow(date: Date) {
    return dayjs.duration(dayjs(date).diff(dayjs())).asDays()
  }

  static format(date: Date, localizedFormat: DateTimeFormat) {
    return dayjs(date).format(localizedFormat)
  }

  static humanizedDuration(date: string | Date): string {
    return dayjs.duration(dayjs(date).diff(dayjs())).humanize(true)
  }

  isAfter(date: string | number | Date) {
    return this._date.isAfter(dayjs(date))
  }

  isBefore(date: string | number | Date) {
    return this._date.isBefore(dayjs(date))
  }
}
