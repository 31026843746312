import { AxiosInstance, AxiosResponse } from 'axios'

import { Step } from '../../types/step'

export class StepsClient {
  private BASE_URL = '/steps'
  private client: AxiosInstance

  static queryKeys = {
    steps: ['Steps'],
    getSteps: () => [...StepsClient.queryKeys.steps, 'List']
  }

  constructor(client: AxiosInstance) {
    this.client = client
  }

  getSteps(): Promise<AxiosResponse<Step[]>> {
    return this.client.get(`${this.BASE_URL}`)
  }
}
