import { AxiosInstance, AxiosResponse } from 'axios'

import { SubscriptionPlan } from '../../types/subscriptions'

export class SubscriptionPlansClient {
  private BASE_URL = '/subscription'
  private client: AxiosInstance

  static queryKeys = {
    steps: ['SubscriptionPlan'],
    getAll: () => [...SubscriptionPlansClient.queryKeys.steps, 'List']
  }

  constructor(client: AxiosInstance) {
    this.client = client
  }

  getAll(): Promise<AxiosResponse<SubscriptionPlan[]>> {
    return this.client.get(`${this.BASE_URL}/plans`)
  }
}
