import mixpanel from 'mixpanel-browser'
import * as SentryReact from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import { secrets } from '../environment'
import { isLocalhost } from '../util/url'

export class Sentry {
  static init = () => {
    if (secrets.sentryDsn && !isLocalhost) {
      SentryReact.init({
        dsn: secrets.sentryDsn,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0
      })
    }
  }
}

export class Mixpanel {
  static init = () => {
    if (secrets.mixpanelId && !isLocalhost) mixpanel.init(secrets.mixpanelId, { debug: false })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static trackEvent = (event: string, params?: any) => {
    if (!isLocalhost) {
      mixpanel.track(event, params)
    }
  }

  static trackLink = (selector: string, event: string) => {
    if (!isLocalhost) {
      mixpanel.track_links(selector, event)
    }
  }

  static identify = (
    userId: number,
    userData: { Email: string; email: string; first_name: string; last_name: string }
  ) => {
    if (!isLocalhost) {
      mixpanel.identify(userId)
      mixpanel.people.set(userData)
    }
  }
}
