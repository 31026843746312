export const LS_PR_AUTHENTICATED_USER = 'pr_authenticated_user'
export const LS_ACCESS_TOKEN = 'access_token'
export const LS_REFRESH_TOKEN = 'refresh_token'
export const LS_PR_SEARCH_INST_MODAL_SEEN = 'pr_search_inst_modal_seen'
export const LS_CASE_ID_SEARCH_TERM = 'case_id_search_term'
export const LS_SUBSCRIPTION_UPDATE_PENDING = 'subscription_update_pending'

export class LocalStorage {
  static getAccessToken = () => localStorage.getItem(LS_ACCESS_TOKEN)
  static setAccessToken = (token: string) => localStorage.setItem(LS_ACCESS_TOKEN, token)
  static removeAccessToken = () => localStorage.removeItem(LS_ACCESS_TOKEN)
  static getRefreshToken = () => localStorage.getItem(LS_REFRESH_TOKEN)
  static setRefreshToken = (token: string) => localStorage.setItem(LS_REFRESH_TOKEN, token)
  static removeRefreshToken = () => localStorage.removeItem(LS_REFRESH_TOKEN)

  static getInstructionModalStatus(): boolean | null {
    const hasSeen = JSON.parse(localStorage.getItem(LS_PR_SEARCH_INST_MODAL_SEEN)) as boolean
    return hasSeen
  }

  static getSearchTermForCase(caseId: string): string {
    const cases = (JSON.parse(localStorage.getItem(LS_CASE_ID_SEARCH_TERM)) || []) as [{ caseId: string; term: string }]
    return cases.find((c) => c.caseId === caseId)?.term || ''
  }

  static setInstructionModalStatus() {
    localStorage.setItem(LS_PR_SEARCH_INST_MODAL_SEEN, 'true')
  }

  static setSearchTermForCase(caseId: string, term: string) {
    const cases = (JSON.parse(localStorage.getItem(LS_CASE_ID_SEARCH_TERM)) || []) as [{ caseId: string; term: string }]
    const index = cases.findIndex((c) => c.caseId === caseId)
    if (index !== -1) {
      cases[index].term = term
    } else {
      cases.push({ caseId, term })
    }
    localStorage.setItem(LS_CASE_ID_SEARCH_TERM, JSON.stringify(cases))
  }

  static setSubscriptionPending() {
    localStorage.setItem(LS_SUBSCRIPTION_UPDATE_PENDING, '1')
  }

  static removeSubscriptionPendingState() {
    localStorage.removeItem(LS_SUBSCRIPTION_UPDATE_PENDING)
  }

  static isSubscriptionUpdatePending(): boolean {
    const isSubscriptionUpdatePending = localStorage.getItem(LS_SUBSCRIPTION_UPDATE_PENDING) === '1'
    if (!isSubscriptionUpdatePending) {
      this.removeSubscriptionPendingState()
    }

    return isSubscriptionUpdatePending
  }
}
